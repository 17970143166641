import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';

import { BasicLayout, CardWrapper } from '@monorepo/common';

import { useGetJobPackagesQuery } from '../../../../services/servicePrepaidPurchase';
import PanelTableHeader from '../../components/PanelTableHeader';
import { IStepsOneInterfaces } from '../../stepsOneInterfaces';
import { Scrollbar } from '../../../../containers/ScrollBar';
import Steps1TableBodyCells from './Steps1TableBodyCells';
import { useAppSelector } from '../../../../store/hooks';
import { IEventsStepProps } from '../../interfaceSteps';

interface IStep1Props extends IEventsStepProps{
  list: IStepsOneInterfaces[],
  Nav: React.ElementType,
  setPackages: any,
}
export const Step1 = memo(({ onNext, list, setPackages, Nav }:IStep1Props) => {
  const tableList: string[] = useMemo(() => ['Quantity', 'Description', 'Listings', 'Price'], []);

  const [sumListings, setSumListings] = useState<number>(0);
  const [sumPrice, setSumPrice] = useState(0);

  const { hostNumber, isHost } = useAppSelector(({ pathnameSettings }) => ({
    hostNumber: pathnameSettings.siteNumber.toString(),
    isHost: pathnameSettings.host
  }));
  const { isLoading } = useGetJobPackagesQuery(hostNumber, {
    skip: !isHost
  });

  useEffect(() => {
    setSumListings(list.reduce((a, b) => a + (b.number * b.listingsNumber), 0));
    setSumPrice(list.reduce((a, b) => a + (b.number * b.itemPrice), 0));
  }, [list]);

  return (
    <BasicLayout dataCy="step1">
      <CardWrapper
        isLoading={isLoading}
        isNoPadding
      >
        <Scrollbar>
          <Table sx={{ minWidth: 680 }}>
            <PanelTableHeader tableList={tableList} />
            <TableBody>
              <Steps1TableBodyCells
                list={list}
                setPackages={setPackages}
              />

              <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={1}>
                  <b style={{ marginLeft: '-3.1rem' }}>TOTAL</b>
                  {' '}
                  {sumListings}
                </TableCell>
                <TableCell
                  align="left"
                  data-cy="totalPrice"
                >
                  $
                  {sumPrice}
                </TableCell>
              </TableRow>

            </TableBody>

          </Table>
        </Scrollbar>
      </CardWrapper>

      <Nav
        onNext={onNext}
        nextDisabled={sumListings === 0}
      />
    </BasicLayout>
  );
});
