import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import { IUserInfoResponse } from './apiInterface';

export const currentUserInfoApi = createApi({
  reducerPath: 'CurrentUserInfo',
  tagTypes: ['CurrentUserInfo'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getUserInfo: build.query<IUserInfoResponse, void>({
      query: () => ({
        url: endpoints.jobs.getUserCurrentInfo,
      }),
    }),
  }),
});
export const {
  useGetUserInfoQuery
} = currentUserInfoApi;
