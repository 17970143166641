import React, { memo } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

interface IPanelTableHeaderProps {
  tableList: string[]
}
const PanelTableHeader = ({ tableList }:IPanelTableHeaderProps) => (
  <TableHead>
    <TableRow>
      {tableList.map((item) => (
        <TableCell key={item}>
          {item}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default memo(PanelTableHeader);
