import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import {
  FormField,
  CardWrapper,
  useHookForm,
  useIsProvince,
  GlobalInterfaces,
  StripeWrapperField,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete, scrollUp
} from '@monorepo/common';

import { useParams } from 'react-router-dom';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { useUpgradeMutation, usePaymentIntentForUpgradeMutation } from '../../../../services/serviceJobData';
import { stripeFieldOptions } from '../../../PrepaidPurchase/styles';
import { IEventsStepProps, Step2FormData } from '../../interfaces';
import { stripeRequestStep2 } from './stripeRequestStep2';
import { useStyles } from '../../styles';
import { schema } from './schema';
import { useAppSelector } from '../../../../store/hooks';
import { showNotification } from '../../../../features/sliceNotification';

interface IStep2Props extends IEventsStepProps {
}
export const Step2 = ({ Nav, onBack, onComplete }:IStep2Props) => {
  const stripe = useStripe();
  const { guid } = useParams<{ guid: string }>();
  const classes = useStyles();
  const elements = useElements();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [finalRequestSent, setFinalRequestSent] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string | null>('US');
  const [stripeError, setStripeError] = useState<GlobalInterfaces.IStripeErrorProps | null>(null);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [clientSecretData, setClientSecretData] = useState<{ data: { clientSecret: string; }; } | { error: any; } | null>(null);

  const [getPaymentIntent] = usePaymentIntentForUpgradeMutation();
  const [upgradeJob, { isLoading: isUpgrading, error: finalResponseError, isSuccess: finalResponseSuccess }] = useUpgradeMutation();

  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber.toString()
  }));

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors
  } = useHookForm<Step2FormData>({
    defaultValues: {
      billingCountry: 'US',
      billingAddress1: '',
      billingCity: '',
      billingCompany: '',
      billingEmail: '',
      billingFirstName: '',
      billingLastName: '',
      billingPhone: '',
      billingProvince: '',
      billingZip: ''
    },
    schema
  });
  const { getData, isProvince } = useIsProvince({
    extraOptions: {
      setValue,
      provinceFieldName: 'billingProvince',
      stateFieldName: 'billingState'
    }
  });

  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      state: 'billingState',
      province: 'billingProvince',
      address1: 'billingAddress1',
      city: 'billingCity',
      zip: 'billingZip'
    }
  });

  const checkOverallValidity = async (data: Step2FormData) : Promise<{ success: boolean, message: string }> => {
    const saveDataResponse = await upgradeJob({
      siteId,
      guid,
      validateOnly: true,
      body: {
        step2: {
          stripePaymentMethodId: '',
          ...data
        }
      }
    });

    if (saveDataResponse && 'error' in saveDataResponse) {
      const respErorr = saveDataResponse as {
        error: { data: { errors: {} } }
      };
      let errorsString = '';
      Object.values(respErorr.error.data.errors).forEach((value) => {
        const valueErray = value as [];

        valueErray.forEach((element) => {
          errorsString += `${element}\n`;
        });
      });

      return { success: false, message: errorsString };
    }

    return { success: true, message: '' };
  };

  const onSubmit = useCallback(async (data: Step2FormData) => {
    setIsLoading(true);

    const saveFormDataResponse = await checkOverallValidity(data);
    if (!saveFormDataResponse.success) {
      dispatch(showNotification({
        show: false,
        type: 'error',
        text: saveFormDataResponse.message
      }));
      setIsLoading(false);
      return;
    }

    const resultIntent = clientSecretData ?? await getPaymentIntent({
      siteId,
      eguid: guid
    });

    const { data: { clientSecret } } = resultIntent as { data:{ clientSecret: string } };
    setClientSecretData(resultIntent);
    const stripePaymentMethodId = paymentMethodId ?? await stripeRequestStep2({
      stripe,
      elements,
      dispatch,
      clientSecret,
      formData: data,
      setIsLoading,
      setStripeError
    });

    setPaymentMethodId(stripePaymentMethodId);

    if (stripePaymentMethodId && guid && siteId) {
      upgradeJob({
        siteId,
        guid,
        validateOnly: false,
        body: {
          step2: {
            stripePaymentMethodId,
            ...data
          }
        }
      });
      setFinalRequestSent(true);
    }
  }, [onComplete, paymentMethodId, clientSecretData]);

  // if choose country
  const changeCountries = (val:GlobalInterfaces.IAutocompleteOption | null) => {
    clearSuggestions();

    getData({
      selectOption: val
    });
    setCountryCode(val?.alpha2 || null);
  };

  useEffect(() => {
    setValue('isShowBillingState', !isProvince);
  }, [isProvince]);

  useEffect(() => {
    if (finalResponseSuccess && onComplete && finalRequestSent) {
      scrollUp();
      onComplete();
    }
  }, [finalResponseSuccess, onComplete, finalRequestSent]);

  useEffect(() => {
    if (finalResponseError && 'data' in finalResponseError) {
      const resp = finalResponseError as { data: { error: string } };
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: resp.data.error
      }));
      return;
    }
    if (finalResponseError) {
      setIsLoading(false);
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: 'Internal server error!'
      }));
    }
  }, [finalResponseError]);

  return (
    <CardWrapper
      isLoading={isLoading || isUpgrading}
      scrollOptions={{
        isErrorsAutoScrollPage: errors,
        isScrollToStart: !!stripeError
      }}
    >
      <form
        data-cy="step2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          style={{ display: 'none' }}
          type="checkbox"
          {...register('isShowBillingState')}
        />

        <StripeWrapperField
          error={stripeError}
          label="Card Number"
          name="numbers"
        >
          <CardNumberElement
            className={classes.card_99}
            options={stripeFieldOptions}
          />
        </StripeWrapperField>

        <div className={classes.step3_address_wrap}>
          <StripeWrapperField
            error={stripeError}
            label="Card Expiry"
            name="expiry"
          >
            <CardExpiryElement
              className={classes.card_99}
              options={stripeFieldOptions}
            />
          </StripeWrapperField>
          <StripeWrapperField
            error={stripeError}
            label="Card CVC"
            name="cvc"
          >
            <CardCvcElement
              className={classes.card_99}
              options={stripeFieldOptions}
            />
          </StripeWrapperField>
        </div>

        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            extraProps={{ ...register('billingFirstName') }}
            errors={errors}
            label="First Name"
            placeholder="First Name"
          />
          <FormField
            isRequired
            extraProps={{ ...register('billingLastName') }}
            errors={errors}
            label="Last Name"
            placeholder="Last Name"
          />
        </div>

        <FormField
          extraProps={{ ...register('billingCompany') }}
          errors={errors}
          label="Company"
          placeholder="Company"
        />
        <FormField
          isRequired
          extraProps={{ ...register('billingEmail') }}
          errors={errors}
          label="Contact Email"
          placeholder="Email"
        />

        <FormField
          isRequired
          label="Phone"
          errors={errors}
          placeholder="Phone"
          extraProps={{ ...register('billingPhone') }}
        />

        <CountryAutocomplete
          isRequired
          label="Country"
          errors={errors}
          disableClearable
          setValue={setValue}
          getValues={getValues}
          handleChange={changeCountries}
          extraProps={{ ...register('billingCountry') }}
        />

        <FormField
          isRequired
          errors={errors}
          label="Address"
          placeholder="Address"
          extraProps={{ ...register('billingAddress1') }}
          eventInput={(inputValue, event) => {
            changeInput(event as ChangeEvent<HTMLInputElement>);
          }}
        >
          {renderedList}
        </FormField>

        <FormField
          extraProps={{ ...register('billingAddress2') }}
          errors={errors}
          label="Address Line 2"
          placeholder="Apartment, suite, unit, building, floor, etc."
        />

        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            extraProps={{ ...register('billingCity') }}
            errors={errors}
            placeholder="City"
            label="City"
          />
          <FormField
            visible={isProvince}
            extraProps={{ ...register('billingProvince') }}
            errors={errors}
            placeholder="Province"
            label="Province"
          />
          <UsaStatesAutocomplete
            isRequired
            label="State"
            visible={!isProvince}
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            extraProps={{ ...register('billingState') }}
          />
        </div>

        <FormField
          isRequired
          extraProps={{ ...register('billingZip') }}
          errors={errors}
          label="Zip/Postal Code"
        />

        <Nav
          nextDisabled={false}
          onBack={onBack}
          textPreviousStep="Previous step"
          nextButton={(
            <Button
              endIcon={(<ArrowRightIcon fontSize="small" />)}
              data-cy="complete"
              variant="contained"
              disabled={!stripe}
              type="submit"
            >
              Continue To Payment
            </Button>
            )}
        />
      </form>
    </CardWrapper>
  );
};
