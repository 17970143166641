import React from 'react';
import { Typography } from '@mui/material';
import { useStyles } from '../styles';

interface IFinalBlockItemProps {
  title: string,
  link: string,
}
const FinalBlockItem = ({ title, link }:IFinalBlockItemProps) => {
  const classes = useStyles();
  return (
    <div className={classes.final_block_item}>
      <Typography
        variant="h6"
        color="#292929"
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
      >
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: 'red' }}
          href={link}
        >
          {link}
        </a>
      </Typography>
    </div>
  );
};

export default FinalBlockItem;
