import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';

import { BasicLayout, useStepper } from '@monorepo/common';

import { Step1 } from './steps/Step1';
import { Step2 } from './steps/Step2';
import { FinalStep } from './components/FinalStep';
import { useAppSelector } from '../../store/hooks';
import { setPageTitle } from '../../utils/setPageTitle';
import { Hosts } from '../../constants/hostName';
import { MetaDescription } from '../../components/MetaDescription';
import { book } from '../../routers/books';

// interface IUpgradeListingProps {}
export const UpgradeListing = () => {
  const [titleBrowserPage, setTitleBrowserPage] = useState<string>('Jobs');

  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber
  }));

  const siteRedirectEdit = useCallback(() => {
    if (Hosts.BREWBOUND === siteId) {
      return book.editJobBrewbound;
    }
    if (Hosts.NOSH === siteId) {
      return book.editJobNosh;
    }
    return book.editJob;
  }, [siteId]);

  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons } = useStepper({
    orientation: 'vertical',
    leftInfoPanel: (
      <Typography variant="h3">
        Upgrade Job Listing:
      </Typography>
    ),
    finalComponent: <FinalStep
      editJobLink={siteRedirectEdit()}
    />
  });

  const steps = useMemo(() => [
    {
      label: 'Step 1: Listing Type',
      content: (
        <Step1
          Nav={StepsButtons}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Step 2: Payment Information',
      content: (
        <Step2
          onBack={onBack}
          Nav={StepsButtons}
          onComplete={onComplete}
        />
      )
    },
  ], []);

  useEffect(() => {
    setPageTitle({
      firstTitle: 'Upgrade Job Listing',
      siteNumber: siteId || Hosts.BEVNET,
      setTitle: setTitleBrowserPage
    });
  }, [siteId]);

  useEffect(() => {
    putSteps(steps);
  }, []);

  return (
    <BasicLayout
      dataCy="UpgradeListing"
      helmet={MetaDescription({
        title: titleBrowserPage,
        siteNumber: siteId
      })}
    >
      {renderStepper}
    </BasicLayout>
  );
};
