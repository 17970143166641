import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import {
  ICreatedJobPackageResponse,
  IJobPackagesRequest,
  IJobPackagesResponse,
  IGetPaymentIntentPrepaidPurchaseRequest,
  IGetPaymentIntentPrepaidPurchaseResponse
} from './interfacePrepaidPurchase';

export const jobsApi = createApi({
  reducerPath: 'serviceJob',
  tagTypes: ['ServiceJobPackages'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    getJobPackages: build.query<IJobPackagesResponse, string>({
      query: (id:string) => ({
        url: endpoints.jobs.packages(id),
      }),
      providesTags: ['ServiceJobPackages']
    }),

    createJobPackage: build.mutation<ICreatedJobPackageResponse, { body: IJobPackagesRequest; id: string; validateOnly: boolean }>({
      query: ({ body, id, validateOnly }) => ({
        url: endpoints.jobs.postPrepaidPurchase(id, validateOnly),
        method: 'POST',
        body
      }),
    }),

    getPaymentIntentPrepaidPurchase: build.mutation<IGetPaymentIntentPrepaidPurchaseResponse, IGetPaymentIntentPrepaidPurchaseRequest>({
      query: (body) => ({
        url: endpoints.jobs.paymentIntentPrepaidPurchase,
        method: 'POST',
        body
      })
    })
  }),
});
export const {
  useGetJobPackagesQuery,
  useCreateJobPackageMutation,
  useGetPaymentIntentPrepaidPurchaseMutation
} = jobsApi;
