import React from 'react';
import { Typography } from '@mui/material';
import { CardWrapper } from '@monorepo/common';

// interface IFinalStepProps {}
export const FinalStep = () => (
  <CardWrapper dataCy="finalStep">
    <Typography
      variant="h4"
      marginBottom={3}
    >
      Editing Complete
    </Typography>
    <Typography variant="subtitle1">
      Your job listing was edited successfully.
    </Typography>
  </CardWrapper>
);
