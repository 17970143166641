import React, { memo } from 'react';
import { Typography } from '@mui/material';

// interface ISalaryTitleProps {}
const SalaryTitle = () => (
  <>
    <Typography
      sx={{ mb: 1 }}
      variant="h6"
    >
      Compensation/Salary
    </Typography>
    <Typography
      sx={{ mb: 1 }}
      variant="body2"
    >
      <b>Recommended</b>
      : Provide compensation/salary details that are estimated for this position.
      <b> Failure to provide compensation information WILL reduce the number of applicants you receive.</b>
    </Typography>
  </>
);

export default memo(SalaryTitle);
