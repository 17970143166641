import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Notification from './components/Notification';
import RootRouters from './routers/RootRouters';
import { useAppSelector } from './store/hooks';
import { PossibleHostType } from './interfaces';
import { setHost } from './features/sliceJob';
import Layout from './containers/Layout';
import { useGetUserInfoQuery } from './services/serviceUserInfo';

function App() {
  const { isLoading } = useGetUserInfoQuery();
  const { showNotification } = useAppSelector(({ notification }) => ({
    showNotification: notification.show
  }));
  const dispatch = useDispatch();
  const { hostname, port, host } = window.location;
  const { pathname: locationPathname } = useLocation();

  useEffect(() => {
    if (host) {
      dispatch(setHost(host as PossibleHostType));
    }
  }, [locationPathname, hostname, port]);

  return (
    <>
      {showNotification && <Notification />}
      <Layout
        hiddenContentWhenIsLoading
        isLoading={isLoading}
        noMenu
      >
        <RootRouters />
      </Layout>

    </>
  );
}

export default App;
