import React, { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
// import ReactHtmlParser from 'react-html-parser';
import { useStyles } from '../../../styles';

interface IStep1LabelForSimpleProps {
  title: string | ReactNode,
  description: string | ReactNode
}
export const Step1LabelForSimple = ({ title, description }:IStep1LabelForSimpleProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  return (
    <Box sx={{ ml: 2 }}>
      <Typography variant="subtitle2">
        {title}
      </Typography>
      <Typography
        color="textSecondary"
        variant="body2"
      >
        {description}
      </Typography>
    </Box>
  );
};
