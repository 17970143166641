import { StripeCardElement, StripeCardNumberElement, PaymentIntent, StripeError } from '@stripe/stripe-js';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { GlobalInterfaces } from '@monorepo/common';
import { showNotification } from '../../../../features/sliceNotification';
import { IStep2, IStep3FormDataProps } from '../../interfaceSteps';

interface IStripeRequestForStep3 {
  stripe?: any;
  elements: any;
  dispatch: any;
  clientSecret: string;
  formData: IStep3FormDataProps;
  dataFromStep2: IStep2;
  setIsLoading: (value: boolean) => void;
  setStripeError: (val:GlobalInterfaces.IStripeErrorProps | null) => void
}

export const stripeRequestForStep3 = async ({ stripe, dispatch, elements, setIsLoading, clientSecret, setStripeError, formData, dataFromStep2 }:IStripeRequestForStep3) => {
  if (stripe && elements) {
    const { paymentIntent, error }:{ paymentIntent:PaymentIntent, error:StripeError } = await stripe.confirmCardPayment(
      clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement) as StripeCardNumberElement | StripeCardElement | { token: string; },
          billing_details: {
            name: `${formData.firstName} ${formData.lastName}`,
            email: dataFromStep2.yourEmail,
            address: {
              country: formData.country,
              state: formData.state ?? formData.province,
              city: formData.city,
              line1: formData.address,
              postal_code: formData.zip
            }
          }
        }
      }
    );
    // ----------------------  if success
    if (paymentIntent && paymentIntent.id) {
      return paymentIntent.id;
    }
    // ------ if error
    if (error?.message) {
      console.error('~~~~', error);
      setStripeError({ message: error.message, code: error.code ?? '', type: error.type });
      setIsLoading(false);
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: error.message
      }));
      setIsLoading(false);
    }
    return null;
  }
  dispatch(showNotification({
    show: false,
    type: 'error',
    text: 'Something went wrong with stripe, country code or with contact data'
  }));
  return null;
};
