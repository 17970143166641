import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface IReceiptLinkProps {
  title: string;
  link: string;
  textLink: string;
  isLocal?: boolean;
}
export const ReceiptLink = ({ link, title, isLocal, textLink }:IReceiptLinkProps) => (
  <div style={{ marginBottom: 20 }}>
    <Typography
      variant="subtitle1"
      fontWeight="700"
      color="#666"
    >
      {title}
    </Typography>

    {!isLocal ? (
      <a
        href={link}
        target="_blank"
        style={{ textDecoration: 'none' }}
        rel="noreferrer"
      >
        <Typography
          color="#cd0a2a"
          variant="subtitle2"
        >
          {textLink}
        </Typography>
      </a>
    ) : (
      <Link
        to={link}
        style={{ textDecoration: 'none' }}
        rel="noreferrer"
      >
        <Typography
          color="#cd0a2a"
          variant="subtitle2"
        >
          {textLink}
        </Typography>
      </Link>
    )}

  </div>
);
