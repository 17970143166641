import React, { memo, useEffect, useState } from 'react';
import { Portal, Snackbar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { closeNotification } from '../../features/sliceNotification';

import { useStyles } from './styles';
import { useAppSelector } from '../../store/hooks';

const Alert = (props: AlertProps) => (
  <MuiAlert
    elevation={6}
    variant="filled"
    {...props}
  />
);

// interface NotificationProps {}
const Notification = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { text, type, show } = useAppSelector(({ notification }) => ({
    type: notification.type,
    text: notification.text,
    show: notification.show
  }));
  const handleClose = () => {
    setOpen(false);
    dispatch(closeNotification());
  };

  useEffect(() => {
    setTimeout(() => {
      if (show) {
        dispatch(closeNotification());
      }
    }, 9000);
  }, [show]);

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          className={classes.message}
        >
          {text}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default memo(Notification);
