import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  description_page: {
    '& h1': {
      textAlign: 'center'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
      marginTop: '2rem',
    },
    '& p, & b,& li': {
      fontSize: 16
    },
    '& ul': {
      paddingLeft: '1.3rem'
    },
    '& p, & ul': {
      marginBottom: '1rem'
    },
    '& li': {
      marginBottom: '.7rem'
    },
  }
}));
