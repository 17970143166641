import { IStep2Form } from './steps/Step2/formInterface';
import { IStep4Form } from './steps/Step4/formInterface';
import { IStep1Form } from './steps/Step1/formInterface';
import { IStep5Form } from './steps/Step5/formInterface';
import { IStep6Form } from './steps/Step6/formInterfaces';
import { IStep7Form } from './steps/Step7/formInterfaces';
import { ILocationItem, IStep3Form } from './steps/Step3/formInterfaces';

export const cloudfrontImagesBaseURL: string = 'https://dg6qn11ynnp6a.cloudfront.net/classifieds/images/';

export const step3ItemLocation: ILocationItem = {
  isShowState: true,
  country: 'US',
  state: null,
  province: null,
  address1: null,
  address2: null,
  city: null,
  zip: null
};

export const staticValuesForStep1:IStep1Form = {
  listingType: 1
};
export const staticValuesForStep2: IStep2Form = {
  jobTitle: null,
  companyName: null,
  employmentTypeId: 0,
  industrySectorId: 0,
};
export const staticValueForStep3:IStep3Form = {
  salaryMax: null,
  salaryMin: null,
  salaryUnitId: 5,
  salaryExact: null,
  locations: [{
    isShowState: true,
    country: 'US',
    state: null,
    province: null,
    address1: null,
    address2: null,
    city: null,
    zip: null
  }],
  noneLocation: false,
  salaryUnitRadio: 2,
};

export const staticValuesForStep4:IStep4Form = {
  shortDescription: null,
  jobDescription: ''
};
export const staticValuesForStep5:IStep5Form = {
  uploadedLogoPath: null,
};
export const staticValuesForStep6:IStep6Form = {
  email: null,
  applicationEmail: '',
  contactLastName: null,
  contactFirstName: null,
  applicationUrl: null,
  showEmails: true,
  applicationUrlEnabled: false,
  tempAdditionalEmails: [
    {
      email: null
    }
  ]
};
export const staticValuesForStep7:IStep7Form = {
  stripePaymentMethodId: '',
  billingCountry: 'US',
  billingEmail: '',
  billingAddress1: '',
  billingCity: '',
  billingProvince: '',
  billingZip: '',
  billingCompany: '',
  billingPhone: '',
  billingFirstName: '',
  billingLastName: ''
};
