import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { readConfig, theme } from '@monorepo/common';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import App from './App';
import { store } from './store/store';
import * as serviceWorker from './serviceWorker';
import GlobalStyles from './theme/GlobalStyles';
import { SiteAddress } from './containers/SiteAddress';

const stripePromise = loadStripe(readConfig().stripeKey);

ReactDOM.render(
  <HelmetProvider>
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Elements stripe={stripePromise}>
              <SiteAddress>
                <App />
              </SiteAddress>
            </Elements>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </SnackbarProvider>
  </HelmetProvider>,
  document.getElementById('root'),
);

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
