import React, { memo, useEffect } from 'react';
import { Card, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { ICreatedJobPackageResponse } from '../../../services/servicePrepaidPurchase/interfacePrepaidPurchase';
import { showNotification } from '../../../features/sliceNotification';

interface IFinalBlockProps {
  data?: ICreatedJobPackageResponse
}
const FinalBlock = ({ data }:IFinalBlockProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Job listing was created'
      }));
    }
  }, [data]);

  return (
    <Card
      data-cy="finalStep"
      sx={{
        mt: 2,
        px: 2,
        p: 2.5
      }}
      variant="outlined"
    >
      <Typography
        variant="h5"
        marginBottom={1}
      >
        Your Order Has Been Processed -- Order ID
        {' '}
        {data?.orderId}
      </Typography>

      <>
        <Typography variant="body1">
          <a
            style={{ color: 'red' }}
            href={`https://app.bevnet.com/orders/receipt/${data?.orderGuid}`}
            target="_blank"
            rel="noreferrer"
          >
            View your receipt
          </a>
        </Typography>

      </>
    </Card>
  );
};
export default memo(FinalBlock);
