interface IBook {
  jobs: string,
  jobsNosh: string,
  jobsBrewbound: string,

  postAJob: string,
  postAJobNosh: string,
  postAJobBrewbound: string,
  editJob: string;
  editJobNosh: string;
  editJobBrewbound: string;
  upgradeJob: (quid?: string) => string;
  upgradeJobNosh: (quid?: string) => string;
  upgradeJobBrewbound: (quid?: string) => string;
  copyListing: (quid?: string) => string;
  copyListingNosh: (quid?: string) => string;
  copyListingBrewbound: (quid?: string) => string;
  reactivateListing: string;
  reactivateListingNosh: string;
  reactivateListingBrewbound: string;
  postPrepaidListing: (quid?: string) => string;
  postPrepaidListingNosh: (quid?: string) => string;
  postPrepaidListingBrewbound: (quid?: string) => string;
  noremaining: string;
  receipt: string;
  receiptNosh: string;
  receiptBrewbound: string;
}

export const book: IBook = Object.freeze({
  jobs: '/PrepaidPurchase',
  jobsNosh: '/PrepaidPurchase/nosh',
  jobsBrewbound: '/PrepaidPurchase/brewbound',

  postAJob: '/PostJobListing',
  postAJobBrewbound: '/PostJobListing/brewbound',
  postAJobNosh: '/PostJobListing/nosh',
  editJob: '/jobs/EditJob',
  editJobBrewbound: '/jobs/EditJob/brewbound',
  editJobNosh: '/jobs/EditJob/nosh',
  reactivateListing: '/jobs/reactivatelisting',
  reactivateListingBrewbound: '/jobs/reactivatelisting/brewbound',
  reactivateListingNosh: '/jobs/reactivatelisting/nosh',
  upgradeJob: (guid : string) => {
    if (guid) {
      return `/jobs/upgradelisting/${guid}`;
    }
    return '/jobs/upgradelisting/:guid';
  },
  upgradeJobBrewbound: (guid : string) => {
    if (guid) {
      return `/jobs/upgradelisting/brewbound/${guid}`;
    }
    return '/jobs/upgradelisting/brewbound/:guid';
  },
  upgradeJobNosh: (guid : string) => {
    if (guid) {
      return `/jobs/upgradelisting/nosh/${guid}`;
    }
    return '/jobs/upgradelisting/nosh/:guid';
  },
  copyListing: (guid : string) => {
    if (guid) {
      return `/jobs/CopyListing/${guid}`;
    }
    return '/jobs/CopyListing/:guid';
  },
  copyListingBrewbound: (guid : string) => {
    if (guid) {
      return `/jobs/CopyListing/brewbound/${guid}`;
    }
    return '/jobs/CopyListing/brewbound/:guid';
  },
  copyListingNosh: (guid : string) => {
    if (guid) {
      return `/jobs/CopyListing/nosh/${guid}`;
    }
    return '/jobs/CopyListing/nosh/:guid';
  },
  postPrepaidListing: (guid : string) => {
    if (guid) {
      return `/jobs/postprepaidlisting/${guid}`;
    }
    return '/jobs/postprepaidlisting/:guid';
  },
  postPrepaidListingBrewbound: (guid : string) => {
    if (guid) {
      return `/jobs/postprepaidlisting/brewbound/${guid}`;
    }
    return '/jobs/postprepaidlisting/brewbound/:guid';
  },
  postPrepaidListingNosh: (guid : string) => {
    if (guid) {
      return `/jobs/postprepaidlisting/nosh/${guid}`;
    }
    return '/jobs/postprepaidlisting/nosh/:guid';
  },
  noremaining: '/jobs/noremaining',
  receipt: '/jobs/receipt',
  receiptBrewbound: '/jobs/receipt/brewbound',
  receiptNosh: '/jobs/receipt/nosh'
});
