import React, { ReactNode } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useStyles } from '../../../styles';

interface IStep1LabelPriceFormatProps {
  title: string | ReactNode;
  extraPrice: number;
  currentPrice: number;
  status: string | null;
  isCharterMember: boolean | null;
}
export const Step1LabelPriceFormat = ({ currentPrice, extraPrice, title, status, isCharterMember }:IStep1LabelPriceFormatProps) => {
  const classes = useStyles();
  const medium = useMediaQuery('(max-width:650px)');
  if (extraPrice > currentPrice) {
    return (
      <>
        {medium && (
        <Typography
          color="black"
          variant="body2"
          fontWeight={800}
          marginBottom={2}
          marginTop={1.3}
          textAlign="left"
        >
          {title}
          {' '}
          {extraPrice > currentPrice && status}
        </Typography>
        )}
        <div className={classes.right}>
          <Typography
            variant="body1"
            color="green"
            fontWeight={700}
            lineHeight={1.2}
          >
            {isCharterMember ? 'Special Charter Member Discount' : 'Special Insider Member Discount'}
          </Typography>

          <Typography
            variant={medium ? 'h2' : 'h4'}
            fontWeight="bold"
          >
            $
            {currentPrice}
          </Typography>

          <Typography
            variant="subtitle2"
            color="textSecondary"
            lineHeight={1.2}
          >
            Originally
            {' '}
            <del>
              $
              {extraPrice}
            </del>
          </Typography>
        </div>
      </>
    );
  }
  return (
    <>
      {medium && (
      <Typography
        color="black"
        variant="body2"
        fontWeight={800}
        marginBottom={2}
        textAlign="left"
      >
        {title}
      </Typography>
      )}
      <div className={classes.right}>
        <Typography
          variant={medium ? 'h2' : 'h4'}
          fontWeight="bold"
        >
          $
          {currentPrice}
        </Typography>
      </div>
    </>
  );
};
