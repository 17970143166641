import { Hosts } from '../constants/hostName';

interface IProps {
  firstTitle: string;
  siteNumber: number;
  setTitle: (val:string) => void;
}
export const setPageTitle = ({ siteNumber, setTitle, firstTitle }:IProps) => {
  if (Hosts.BEVNET === siteNumber) {
    setTitle(`${firstTitle} | BevNET.com`);
    return;
  }
  if (Hosts.NOSH === siteNumber) {
    setTitle(`${firstTitle} | NOSH.com`);
    return;
  }
  if (Hosts.BREWBOUND === siteNumber) {
    setTitle(`${firstTitle} | Brewbound.com`);
  }
};
