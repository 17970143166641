import React, { memo, useEffect, useState } from 'react';
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Typography } from '@mui/material';

import { useStyles } from '../../styles';

interface IApplePayProps {
  listingType: number
}
const nameAmount = (p: number) => {
  if (p === 2) {
    return {
      label: '$199 - 60 DAY STANDARD LISTING',
      amount: 19900,
    };
  }
  return {
    label: '$299 - 60 DAY FEATURED LISTING',
    amount: 29900,
  };
};

const ApplePay = ({ listingType }:IApplePayProps) => {
  const classes = useStyles();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any | null>(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: nameAmount(listingType),
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
          } else {
            console.error("The Google or Apple button doesn't work");
          }
        })
        .catch((e) => {
          console.error('Something went wrong: ', e);
        });
    }
  }, [stripe, listingType]);

  return (
    <>
      {paymentRequest ? (
        <>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
          <Typography
            className={classes.text_divider}
            variant="body2"
          >
            Or pay with card
          </Typography>
        </>
      ) : null}
    </>
  );
};

export default memo(ApplePay);
