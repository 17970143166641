import { Typography } from '@mui/material';
import { useStyles } from '../../styles';

export const PackDescription = () => {
  const classes = useStyles();

  return (
    <Typography
      sx={{
        fontSize: '0.8rem',
        fontWeight: 500,
        lineHeight: '1.15rem',
        textWrap: 'balance'
      }}
      className={classes.job_package_description}
    >
      One pack includes 10 listings. For every package purchased, you receive 1 free listing for a total of 11 listings. Credits are valid for 24 months from purchase.
    </Typography>
  );
};
