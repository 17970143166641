import React, { memo, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { useStyles } from '../styles';
import { useAppSelector } from '../../../store/hooks';
import { Hosts } from '../../../constants/hostName';

// interface IDescriptionJobsProps {}
const forBevnet: string = 'Job Listing Packages';
const forNosh: string = 'Job Listing Packages - NOSH Job Board';
const forBrewbound: string = 'Multiple Job Listing Packages - Brewbound Job Board';
const DescriptionJobs = () => {
  const classes = useStyles();
  const [title, setTitle] = useState<string>();
  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber
  }));

  useEffect(() => {
    if (siteNumber) {
      if (siteNumber === Hosts.NOSH) {
        setTitle(forNosh);
        return;
      }
      if (siteNumber === Hosts.BREWBOUND) {
        setTitle(forBrewbound);
        return;
      }
      setTitle(forBevnet);
    }
    setTitle(forBevnet);
  }, [siteNumber]);

  return (
    <div className={classes.description_page}>
      <Typography
        sx={{ mb: 3 }}
        variant="h1"
        fontWeight={800}
      >
        {title}
      </Typography>

      <p>
        {' Save when you buy a package! Buy a pack of 10 job listings and get 1 free '}
      </p>
      <ul>
        <li>
          {' '}
          <b>Savings</b>
          {' '}
          - Get 1 free job listing for every pack of 10 that you purchase. No limit!
        </li>
        <li>
          <b>Flexibility</b>
          {' '}
          - Job packages are valid for 24 months from purchase so you can use them anytime. Buy now and post later.
        </li>
        <li>
          <b>Convenience</b>
          {' '}
          - Share with others in your company/department.
        </li>
        <li>
          <b>Post Jobs Quickly</b>
          {' - You don\'t have to enter your payment info every time. '}
        </li>
      </ul>

      <p>
        <strong>
          Want to pay by ACH or do you require an invoice?
          {' '}
          <a
            target="_blank"
            href="mailto:jobboard@bevnet.com?subject=Job Board invoicing"
            className={classes.link}
            rel="noreferrer"
          >
            Contact us to set up an account.
          </a>
        </strong>
      </p>
    </div>
  );
};

export default memo(DescriptionJobs);
