import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { CardWrapper, Loader } from '@monorepo/common';

import { useAppSelector } from '../../../../store/hooks';
import { useLazyGetPostPrepaidListingQuery } from '../../../../services/servicePostPrepaidListing';
import { IEventsStepProps } from '../interfaceSteps';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { book } from '../../../../routers/books';

interface IStep0Props extends IEventsStepProps {
  setIsRequest: (val:boolean) => void;
}
export const Step0 = ({ onNext, Nav, setIsRequest }:IStep0Props) => {
  const [getPostPrepaidListing, { data, isLoading }] = useLazyGetPostPrepaidListingQuery();
  const { guid } = useParams<{ guid: string }>();
  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber.toString()
  }));
  const { push } = useHistory();

  const nextStep = () => {
    setIsRequest(true);
    onNext();
  };

  useEffect(() => {
    if (guid) {
      getPostPrepaidListing({
        siteId,
        guid
      });
    }
  }, [guid]);

  useEffect(() => {
    if (data !== undefined) {
      if (data.step1.remainingQuantity < 1) {
        push(`${book.noremaining}`);
      }
    }
  }, [data]);

  if (!data) {
    return (
      <Loader
        isShow
        position="relative"
      />
    );
  }

  return (
    <CardWrapper
      dataCy="step1"
      isLoading={isLoading}
      hiddenContentWhenIsLoading={isLoading}
    >
      <Typography
        variant="subtitle1"
        marginBottom={2}
      >
        You have entered a prepaid code for a
        {' '}
        <b>
          60 DAY
          {data.step1.isFeatured ? ' FEATURED' : ' STANDARD'}
          {' '}
          JOB LISTING
        </b>
        .
      </Typography>

      <Typography variant="subtitle1">
        Prepaid code status:
        {' '}
        {data.step1.remainingQuantity}
        {' '}
        of
        {' '}
        {data.step1.totalQuantity}
        {' '}
        posts remaining.
      </Typography>

      <Nav
        nextDisabled={false}
        nextButton={
                (
                  <Button
                    endIcon={(<ArrowRightIcon fontSize="small" />)}
                    variant="contained"
                    disabled={false}
                    onClick={nextStep}
                    data-cy="nextStep"
                  >
                    Next Step
                  </Button>
                )
            }
      />
    </CardWrapper>
  );
};
