import React from 'react';
import { Typography } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import { IStepsOneInterfaces } from '../../stepsOneInterfaces';
import { useStyles } from '../../styles';
import { CellForAuthFormatPrice } from './CellForAuthFormatPrice';
import { CellForSimpleUser } from './CellForSimpleUser';
import { PackDescription } from './PackDescription';

interface ICellForAuthProps {
  item: IStepsOneInterfaces,
}

export const CellForAuth = ({ item }:ICellForAuthProps) => {
  const classes = useStyles();

  const hasDiscount = item.itemPrice !== item.listItemPrice;

  if (hasDiscount) {
    return (
      <>
        <div className={classes.line}>
          <CellForAuthFormatPrice
            currentPrice={item.itemPrice}
            futurePrice={item.listItemPrice}
          />
          <div className={classes.left}>
            <Typography
              color="black"
              fontWeight={800}
              fontSize={20}
              lineHeight={1.2}
              marginBottom={1}
            >
              {item.description && ReactHtmlParser(item.description)}
              {' '}
            </Typography>
            <PackDescription />
          </div>
        </div>
      </>
    );
  }

  return <CellForSimpleUser item={item} />;
};
