import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { cookieNames, useStepper } from '@monorepo/common';

import cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { StepperInfoPanel } from './StepperInfoPanel';

import {
  staticValuesForStep1,
  staticValuesForStep2,
  staticValuesForStep3,
  staticValuesForStep4,
  staticValuesForStep5
} from './staticValues';

import { IStep1Form } from './Step1/formInterface';
import { IStep2Form } from './Step2/formInterfaces';
import { IStep3Form } from './Step3/formInterface';
import { IStep4Form } from './Step4/formInterface';
import { IStep5Form } from './Step5/formInterfaces';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step6 } from './Step6';
import { Step0 } from './Step0/Step0';
import { useGetTempDataQuery, useSaveTempDataMutation } from '../../../services/servicePostPrepaidListing';
import { showDropButton, showExtraDropButton } from '../../../features/sliceStepper';
import { useAppSelector } from '../../../store/hooks';
import { showNotification } from '../../../features/sliceNotification';
import { AllSteps } from './interfaceSteps';

interface IStepperPanelProps {
  isComplete?: boolean;
  finalRequest: (value: AllSteps) => void;
}

export const StepperPanel = ({ finalRequest, isComplete }:IStepperPanelProps) => {
  const dispatch = useDispatch();
  const [isGetTempData, setIsGetTempData] = useState(true);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [stepperIsDropped, setStepperIsDropped] = useState<boolean>(false);

  const { isStepperDrop } = useAppSelector(({ stepperOptions }) => ({
    isStepperDrop: stepperOptions.isDrop
  }));

  const [saveTempData, { data: quidFromTempData, error: errSavedData }] = useSaveTempDataMutation();
  const [isFirstLoadPage, setIsFirstLoadPage] = useState(true);

  const { data: getSavedTempData, isLoading: isLoadingDataFromServer } = useGetTempDataQuery({ guid: cookies.get(cookieNames.tempPrepaidData) || '' }, {
    skip: isGetTempData
  });

  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons, dropStepper } = useStepper({
    orientation: 'vertical',
    isHideLeftInfoPanelAfterComplete: true,
    leftInfoPanel: (
      <StepperInfoPanel />
    ),
    finalComponent: null,
    isLoading: isLoadingDataFromServer
  });

  const [step1, setStep1] = useState<IStep1Form>(staticValuesForStep1);
  const [step2, setStep2] = useState<IStep2Form>(staticValuesForStep2);
  const [step3, setStep3] = useState<IStep3Form>(staticValuesForStep3);
  const [step4, setStep4] = useState<IStep4Form>(staticValuesForStep4);
  const [step5, setStep5] = useState<IStep5Form>(staticValuesForStep5);

  const finalStepSubmit = useCallback(() => {
    finalRequest(
      {
        step1,
        step2,
        step3,
        step4,
        step5
      }
    );
  }, [step1, step2, step3, step4, step5]);

  const steps = useMemo(() => [
    {
      label: 'Basic Job Details',
      content: (
        <Step0
          setIsRequest={setIsRequest}
          Nav={StepsButtons}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Listing Type',
      content: (
        <Step1
          defaultData={step1}
          setStep1={setStep1}
          Nav={StepsButtons}
          onBack={onBack}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Compensation & Location',
      content: (
        <Step2
          defaultData={step2}
          setStep2={setStep2}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Job Description',
      content: (
        <Step3
          setStep3={setStep3}
          defaultData={step3}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Upload Logo',
      content: (
        <Step4
          setStep4={setStep4}
          defaultData={step4}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Contact and Application Details',
      content: (
        <Step5
          defaultValue={step5}
          setStep5={setStep5}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Terms and Conditions',
      content: (
        <Step6
          isEdited={false}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
          onComplete={onComplete}
          finalRequest={finalStepSubmit}
        />
      )
    },
  ], [step1, step2, step3, step4, step5]);

  useEffect(() => {
    dispatch(showExtraDropButton({ tempKay: cookieNames.tempPrepaidData }));
    putSteps(steps);
    if (!isFirstLoadPage && isRequest) {
      saveTempData({
        body: {
          step1,
          step2,
          step3,
          step4,
          step5,
        }
      });
    }
  }, [step1, step2, step3, step4, step5, isRequest]);

  useEffect(() => {
    if (isRequest) {
      setIsFirstLoadPage(false);
    }
  }, [isRequest]);

  useEffect(() => {
    if (isFirstLoadPage && !cookies.get(cookieNames.tempPrepaidData)) {
      setIsFirstLoadPage(false);
    }
    if (isFirstLoadPage && cookies.get(cookieNames.tempPrepaidData)) {
      setIsGetTempData(false);
    }
  }, []);

  useEffect(() => {
    if (quidFromTempData && quidFromTempData.guid && !cookies.get(cookieNames.tempPrepaidData)) {
      cookies.set(cookieNames.tempPrepaidData, quidFromTempData.guid);
      dispatch(showDropButton());
    }
  }, [quidFromTempData, errSavedData]);

  useEffect(() => {
    if (isStepperDrop === false && cookies.get(cookieNames.tempPrepaidData)) {
      if (isFirstLoadPage && getSavedTempData) {
        setStep1(getSavedTempData.step1);
        setStep2(getSavedTempData.step2);
        setStep3(getSavedTempData.step3);
        setStep4(getSavedTempData.step4);
        setStep5(getSavedTempData.step5);
      }
    }
  }, [getSavedTempData, isStepperDrop]);

  useEffect(() => {
    if (isComplete && !stepperIsDropped) {
      setStepperIsDropped(true);
      onComplete();
      const timeout = setTimeout(() => {
        setStepperIsDropped(false);
        clearTimeout(timeout);
      }, 3000);
    }
  }, [isComplete]);

  useEffect(() => {
    dispatch(showDropButton());
    if (isStepperDrop) {
      setIsRequest(false);
      setStep1(staticValuesForStep1);
      setStep2(staticValuesForStep2);
      setStep3(staticValuesForStep3);
      setStep4(staticValuesForStep4);
      setStep5(staticValuesForStep5);
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Form cleared'
      }));
      dropStepper();
    }
  }, [isStepperDrop]);

  return renderStepper;
};
