import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BasicLayout, scrollUp, useStepper } from '@monorepo/common';

import { Step1 } from './steps/Steps1';
import { Step2 } from './steps/Steps2';
import { Step3 } from './steps/Steps3';

import { MetaDescription } from '../../components/MetaDescription';
import DescriptionJobs from './components/DescriptionJobs';
import { IStepsOneInterfaces } from './stepsOneInterfaces';
import { useAppSelector } from '../../store/hooks';
import FinalBlock from './components/FinalBlock';
import { useCreateJobPackageMutation, useGetJobPackagesQuery } from '../../services/servicePrepaidPurchase';
import { IStep1, IStep2, IStep3AllDataProps } from './interfaceSteps';
import { showNotification } from '../../features/sliceNotification';
import { emptyObj, staticValuesForStep1, staticValuesForStep2, staticValuesForStep3 } from './staticValues';
import { setPageTitle } from '../../utils/setPageTitle';
import { Hosts } from '../../constants/hostName';

export const PrepaidPurchase = () => {
  const dispatch = useDispatch();
  const [titleBrowserPage, setTitleBrowserPage] = useState<string>('Jobs');

  const { hostNumber, isHost, siteParams } = useAppSelector(({ pathnameSettings }) => ({
    hostNumber: pathnameSettings.siteNumber.toString(),
    siteParams: pathnameSettings,
    isHost: pathnameSettings.host
  }));
  const { data: jobPackages } = useGetJobPackagesQuery(hostNumber, {
    skip: !isHost
  });

  // for steps
  const [dataForStep1, setDataForStep1] = useState<IStepsOneInterfaces[]>([]);
  const [step1, setStep1] = useState<IStep1>(staticValuesForStep1);
  const [step2, setStep2] = useState<IStep2>(staticValuesForStep2);
  const [step3, setStep3] = useState<IStep3AllDataProps>(staticValuesForStep3);

  const [createJobPackage, { data, isLoading: isPaymentLoading, error: finalResponseError, isSuccess: finalResponseSuccess }] = useCreateJobPackageMutation();
  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons, setIconColor } = useStepper({
    orientation: 'vertical',
    leftInfoPanel: <DescriptionJobs />,
    finalComponent: <FinalBlock data={data} />,
  });

  const finalPaymentRequest = (result: IStep3AllDataProps) => {
    createJobPackage({
      id: hostNumber,
      validateOnly: false,
      body: {
        step1,
        step2,
        step3: result
      }
    });
  };

  const steps = useMemo(() => [
    {
      label: 'Select Package',
      content: (
        <Step1
          setPackages={setDataForStep1}
          list={dataForStep1}
          onNext={onNext}
          Nav={StepsButtons}
        />
      )
    },
    {
      label: 'Contact Information',
      content: (
        <Step2
          step2={step2 || null}
          setStep2={setStep2}
          onBack={onBack}
          onNext={onNext}
          Nav={StepsButtons}
        />
      )
    },
    {
      label: 'Payment Information',
      content: (
        <Step3
          step1={step1}
          step2={step2}
          step3={step3}
          onBack={onBack}
          Nav={StepsButtons}
          setStep3={setStep3}
          onNext={onComplete}
          dataForStep1={dataForStep1}
          finalPaymentRequest={finalPaymentRequest}
        />
      )
    }
  ], [StepsButtons, step1, step2, step3, dataForStep1]);

  useEffect(() => {
    putSteps(steps);
  }, [step1, step2, step3, dataForStep1]);

  useEffect(() => {
    if (jobPackages && jobPackages.prepaidPackages) {
      const result = jobPackages.prepaidPackages.map((item) => ({
        ...emptyObj,
        ...item
      }));
      setDataForStep1(result);
    }
  }, [jobPackages]);

  useEffect(() => {
    setPageTitle({
      firstTitle: 'Job Listing Packages',
      siteNumber: siteParams.siteNumber || Hosts.BEVNET,
      setTitle: setTitleBrowserPage
    });
  }, [siteParams]);

  useEffect(() => {
    const localFormattedData: IStep1 = {
      ...staticValuesForStep1
    };
    if (dataForStep1.length) {
      dataForStep1.forEach((item:IStepsOneInterfaces) => {
        if (item.description.search('STANDARD') >= 0) {
          return localFormattedData.standardPackagesQuantity = item.number;
        }
        if (item.description.search('FEATURED') >= 0) {
          return localFormattedData.featuredPackagesQuantity = item.number;
        }
        return localFormattedData;
      });
      setStep1(localFormattedData);
    }
  }, [dataForStep1]);

  useEffect(() => {
    if (finalResponseError && 'data' in finalResponseError) {
      const resp = finalResponseError as { data: { error: string } };
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: resp.data.error
      }));
    }
    if (finalResponseSuccess) {
      scrollUp();
      onComplete();
    }
  }, [finalResponseError, finalResponseSuccess]);

  useEffect(() => {
    if (siteParams.color) {
      setIconColor(siteParams.color);
    }
  }, [siteParams]);

  return (
    <BasicLayout
      dataCy="PrepaidPurchase"
      isLoading={isPaymentLoading}
      helmet={MetaDescription({ title: titleBrowserPage, siteNumber: siteParams.siteNumber || Hosts.BEVNET })}
    >
      {renderStepper}
    </BasicLayout>
  );
};
