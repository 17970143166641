import React from 'react';
import { CardWrapper } from '@monorepo/common';
import { Typography } from '@mui/material';

export const NoRemaining = () => (
  <CardWrapper
    dataCy="final_step_for_page_postJobListing"
  >
    <Typography
      variant="h4"
      marginBottom={4}
    >
      No remaining prepaid job
    </Typography>
  </CardWrapper>
);
