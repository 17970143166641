import { Hosts } from '../../constants/hostName';

export interface IPathnameInitialStateProps {
  color: string;
  host: string;
  siteNumber: number;
  pathname: string | null
}

export const pathnameInitialState:IPathnameInitialStateProps = {
  color: '#ce0e2d',
  host: 'bevnet.com',
  siteNumber: Hosts.BEVNET,
  pathname: null
};
