import React, { memo, useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { CardWrapper, useHookForm } from '@monorepo/common';

import { useDispatch } from 'react-redux';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IEventsStepProps } from '../../interfaces';
import { itemLocation } from '../staticValues';
import LocationTitle from './components/LocationTitle';
import { IStep2Form } from './formInterfaces';
import Location from './components/Location';
import Salary from './components/Salary';
import { schema } from './schema';
import { showNotification } from '../../../../features/sliceNotification';

interface IStep3Props extends IEventsStepProps {
  defaultData: IStep2Form
  setStep2: (val: IStep2Form) => void
}

export const Step2 = memo(({ onNext, onBack, Nav, defaultData, setStep2 }:IStep3Props) => {
  const dispatch = useDispatch();
  const [isShowLocation, setIsShowLocation] = useState(false);
  const [changedFields, setChangedFields] = useState<IStep2Form | null>(null);

  const { register, errors, getValues, setValue, control, handleSubmit, useFieldArray, setFormData } = useHookForm<IStep2Form>({
    schema,
    showChangedFields: (a) => {
      setChangedFields(a);
    }
  });
  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(defaultData).toString() !== JSON.stringify(changedFields))) {
        setStep2(changedFields);
      }
      onBack();
    }
  };

  const { fields, prepend, remove } = useFieldArray({
    name: 'locations',
    control
  });

  const increase = () => {
    prepend(itemLocation);
  };

  const decrease = (id: number) => {
    if (fields.length === 1) {
      setValue('locations', [itemLocation]);
      return;
    }
    remove(id);
  };
  const onSubmit = (data: IStep2Form) => {
    if (data && onNext) {
      if (!data.locations.length && !data.noneLocation) {
        dispatch(showNotification({
          show: true,
          type: 'error',
          text: 'Please fill at least one location!'
        }));
        return;
      }
      setStep2(data);
      onNext();
    }
  };

  const toggleLocation = (status: boolean) => {
    setIsShowLocation(!status);
    if (!isShowLocation) {
      increase();
    }
  };

  useEffect(() => {
    setIsShowLocation(!defaultData.noneLocation || !defaultData.locations.length);
    const timeout = setTimeout(() => {
      defaultData.locations.forEach((item) => {
        prepend(item);
      });
      clearTimeout(timeout);
    }, 0);
    setFormData(defaultData);
  }, []);

  useEffect(() => {
    setValue('noneLocation', !isShowLocation);
    if (!isShowLocation) {
      setValue('locations', []);
    }
  }, [isShowLocation]);

  return (
    <CardWrapper
      scrollOptions={{
        isErrorsAutoScrollPage: errors
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-cy="step2"
      >
        <Salary
          defaultValueMax={defaultData.salaryMax}
          defaultValueMin={defaultData.salaryMin}
          defaultValueExact={defaultData.salaryExact}
          defaultValueSalaryUnitId={defaultData.salaryUnitId}
          defaultValueRadio={defaultData.salaryUnitRadio}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          register={register}
        />

        <LocationTitle
          value={!isShowLocation}
          toggleLocation={toggleLocation}
        />

        {isShowLocation && (
        <>
          {fields.map((item, index) => (
            <Location
              key={item.id}
              register={register}
              fieldLength={fields.length || 0}
              pIsShowState={`locations.${index}.isShowState` as const}
              pAddress1={`locations.${index}.address1` as const}
              pAddress2={`locations.${index}.address2` as const}
              pCity={`locations.${index}.city` as const}
              pState={`locations.${index}.state` as const}
              pZip={`locations.${index}.zip` as const}
              pProvince={`locations.${index}.province` as const}
              pCountry={`locations.${index}.country` as const}
              decrease={decrease}
              id={index}
              setValue={setValue}
              errors={{
                ...(errors?.locations) && ({ ...errors.locations[index] })
              }}
              getValues={getValues}
            />
          ))}
        </>
        )}

        {isShowLocation && (
        <Button
          type="button"
          color="error"
          onClick={() => {
            increase();
          }}
        >
          Add an additional location
        </Button>
        )}
        <Nav
          nextDisabled={false}
          onBack={goBack}
          nextButton={
              (
                <Button
                  endIcon={(<ArrowRightIcon fontSize="small" />)}
                  variant="contained"
                  data-cy="nextStep"
                  disabled={false}
                  type="submit"
                >
                  Next Step
                </Button>
              )
            }
        />
      </form>
    </CardWrapper>
  );
});
