import * as React from 'react';

function BrewboundLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 89"
      {...props}
    >
      <g id="BB_Blk_1_">
        <g>
          <g>
            <g>
              <path
                d="M28.48,0H8.65H1.78H0v1.78v85.44V89h1.78h6.87h19.83c10.46,0,16.13-5.56,16.13-16.02V52.51c0-4.12-3-7.23-7.01-7.9
          v-0.22c4.01-0.67,7.01-3.78,7.01-7.9V16.02C44.61,5.56,38.94,0,28.48,0z M29.82,69.87c0,3-1.56,4.45-4.45,4.45H15.24V51.84
          h10.12c3,0,4.45,1.56,4.45,4.45V69.87z M29.82,32.82c0,2.78-1.56,4.34-4.45,4.34H15.24V14.69h10.12c3,0,4.45,1.56,4.45,4.45
          V32.82z"
              />
              <path
                d="M82.37,0H61.31h-6.87h-1.78v1.78v85.44V89h1.78h6.87h4.7c1.11,0,1.78-0.67,1.78-1.78V59.19h7.68l7.45,28.26
          c0.22,1,0.78,1.56,1.89,1.56h11.57c0.67,0,2.12,0,2.12,0s-0.41-1.39-0.56-2l-7.68-29.15c5.34-2.34,8.12-7.23,8.12-14.69V16.02
          C98.39,5.56,92.83,0,82.37,0z M83.26,40.05c0,2.89-1.56,4.45-4.45,4.45H67.79V14.69h11.01c2.89,0,4.45,1.56,4.45,4.45V40.05z"
              />
              <path
                d="M148.28,0h-33.96h-6.98h-1.67v1.78v85.44V89h1.67h6.98h33.96c0.62,0,1.78,0,1.78,0s0-1.19,0-1.78V76.1
          c0-1.22-0.44-1.78-1.78-1.78h-27.81V51.84h21.25c1.11,0,1.78-0.67,1.78-1.89V38.94c0-1.22-0.67-1.89-1.78-1.89h-21.25V14.8
          h27.81c1.34,0,1.78-0.78,1.78-1.89V1.78c0-0.55,0-1.78,0-1.78S148.96,0,148.28,0z"
              />
              <path
                d="M226.2,0h-10.57c-1.33,0-2.22,0.78-2.34,2.11l-4.56,52.07h-0.56L197.28,2c-0.33-1.33-1.11-2-2.45-2h-6.67
          c-1.34,0-2.11,0.67-2.45,2l-11.01,52.18h-0.56L169.8,2.11c-0.11-1.34-1-2.11-2.34-2.11h-10.57c-0.78,0-2.35,0-2.35,0
          s0.18,1.67,0.23,2.34l9.12,84.55c0.06,0.72,0.17,2.11,0.17,2.11s1.44,0,2.16,0h11.13c1.34,0,2.11-0.67,2.45-1.89l11.68-50.4
          L203.06,87c0.33,1.33,1.11,2,2.45,2h10.9c0.68,0,2.11,0,2.11,0s0.17-1.35,0.23-2.11l9.57-84.55L228.57,0
          C228.57,0,226.99,0,226.2,0z"
              />
              <path
                d="M263.31,0h-19.83h-6.87h-1.78v1.78v85.44V89h1.78h6.87h19.83c10.46,0,16.13-5.56,16.13-16.02V52.51
          c0-4.12-3-7.23-7.01-7.9v-0.22c4-0.67,7.01-3.78,7.01-7.9V16.02C279.44,5.56,273.77,0,263.31,0z M264.65,69.87
          c0,3-1.56,4.45-4.45,4.45h-10.12V51.84h10.12c3,0,4.45,1.56,4.45,4.45V69.87z M264.65,32.82c0,2.78-1.56,4.34-4.45,4.34h-10.12
          V14.69h10.12c3,0,4.45,1.56,4.45,4.45V32.82z"
              />
              <path
                d="M315.21,0h-12.46c-10.46,0-16.02,5.56-16.02,16.02v56.96c0,10.46,5.56,16.02,16.02,16.02h12.46
          c10.46,0,16.02-5.56,16.02-16.02V16.02C331.23,5.56,325.67,0,315.21,0z M316.88,70.2c0,3-1.56,4.45-4.45,4.45h-6.45
          c-2.89,0-4.45-1.45-4.45-4.45V18.8c0-2.89,1.56-4.45,4.45-4.45h6.45c2.89,0,4.45,1.56,4.45,4.45V70.2z"
              />
              <path
                d="M429.16,0h-4.7c-1.22,0-1.78,0.67-1.78,1.78v46.95h-0.67L405.1,1.45c-0.33-1-1-1.45-2-1.45h-4.15h-6.87h-1.78v1.78
          v85.44V89h1.78h6.87h4.7c1.22,0,1.78-0.67,1.78-1.78V40.05h0.56l17.02,47.51c0.33,1,1,1.45,2,1.45h4.15h6.87h1.78v-1.78V1.78V0
          h-1.78H429.16z"
              />
              <path
                d="M473.57,0h-19.83h-6.87h-1.78v1.78v85.44V89h1.78h6.87h19.83c10.46,0,16.02-5.56,16.02-16.02V16.13
          C489.59,5.67,484.03,0,473.57,0z M474.9,69.87c0,3-1.56,4.45-4.45,4.45h-10.24V14.69h10.24c2.89,0,4.45,1.56,4.45,4.56V69.87z"
              />
              <path
                d="M380.02,0h-6.42h-3.81c-1.22,0-2.23,1-2.23,2.23v67.64c0,3-1.56,4.45-4.45,4.45h-5.78c-2.89,0-4.45-1.45-4.45-4.45V2.23
          c0-1.22-1-2.23-2.22-2.23h-4.26h-6.42h-2.22v2.23v47.06v23.69c0,10.46,5.56,16.02,16.02,16.02h12.46
          c10.46,0,16.02-5.56,16.02-16.02V59.51V2.23V0H380.02z"
              />
            </g>
          </g>
          <g>
            <path
              d="M494.75,78.68c3.22,0,5.25,2.25,5.25,5.13c0,2.8-2.03,5.13-5.2,5.13c-3.15,0-5.2-2.23-5.2-5.1S491.68,78.68,494.75,78.68
        z M494.8,87.68c2.4,0,3.81-1.63,3.81-3.86c0-2.28-1.44-3.84-3.86-3.84c-2.33,0-3.76,1.61-3.76,3.86
        C490.98,86.02,492.42,87.68,494.8,87.68z M494.28,84.6v1.86h-1.46v-5.35H495c1.21,0,1.93,0.82,1.93,1.73
        c0,0.74-0.42,1.26-0.84,1.46l0.99,2.15h-1.51l-0.77-1.86H494.28z M494.28,82.35v1.07h0.59c0.3,0,0.54-0.22,0.54-0.54
        c0-0.32-0.25-0.52-0.54-0.52H494.28z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BrewboundLogo;
