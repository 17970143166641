import * as yup from 'yup';

export const schema:any = yup.object().shape({
  isShowState: yup.boolean(),
  firstName: yup.string().required('Required').nullable(),
  lastName: yup.string().required('Required').nullable(),
  country: yup.string().required('Required').nullable(),
  address: yup.string().required('Required').nullable(),
  city: yup.string().required('Required').nullable(),
  state: yup.string().nullable()
    .when('isShowState', {
      is: true,
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable()
    }),
  province: yup.string().nullable(),
  zip: yup.string().required('Required').nullable(),

});
