import { createApi } from '@reduxjs/toolkit/dist/query/react';
import cookies from 'js-cookie';
import { cookieNames } from '@monorepo/common';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import {
  IPostJobPrepaidRequest,
  IPostPrepaidListingResponse,
  ISaveTempDataRequest,
  ITempDataResponse
} from './responseInterfaces';

export const postPrepaidListingApi = createApi({
  reducerPath: 'servicePostPrepaidListing',
  tagTypes: ['postprepaidlisting', 'tempData'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    // ------ post prepaid listing
    getPostPrepaidListing: build.query<IPostPrepaidListingResponse, { siteId: string; guid: string }>({
      query: ({ siteId, guid }) => ({
        url: endpoints.jobs.postprepaidlisting({ guid, siteId }),
      }),
      providesTags: () => ['postprepaidlisting']
    }),
    postPrepaidListing: build.mutation<{ guid: string, path: string }, IPostJobPrepaidRequest>({
      query: ({ body, siteId, guid }) => ({
        url: endpoints.jobs.postprepaidlisting({ guid, siteId }),
        method: 'POST',
        body
      }),
    }),

    // -------Temp data
    saveTempData: build.mutation<{ guid: string }, ISaveTempDataRequest>({
      query: ({ body }) => {
        if (cookies.get(cookieNames.tempPrepaidData)) {
          return {
            url: endpoints.jobs.saveTempDataJobPrepaid,
            method: 'POST',
            params: { guid: cookies.get(cookieNames.tempPrepaidData) },
            body
          };
        }
        return {
          url: endpoints.jobs.saveTempDataJobPrepaid,
          method: 'POST',
          body
        };
      },
      // invalidatesTags: () => ['tempData']
    }),
    getTempData: build.query<ITempDataResponse, { guid: string }>({
      query: ({ guid }) => ({
        url: endpoints.jobs.getJobPrepaidTempData(guid),
      }),
      providesTags: () => ['tempData']
    }),
    clearTempData: build.query<{ success: boolean }, { guid: string }>({
      query: ({ guid }) => ({
        url: endpoints.jobs.clearTempData(guid),
      }),
    }),

    // -------- /Jobs/Receipt
    getReceiptData: build.query<{ path: string }, { eguid: string }>({
      query: ({ eguid }) => ({
        url: endpoints.jobs.getReceiptData(eguid),
      }),
    }),
  }),

});
export const {
  useLazyGetPostPrepaidListingQuery,
  usePostPrepaidListingMutation,

  useGetTempDataQuery,
  useSaveTempDataMutation,
  useLazyClearTempDataQuery,

  useLazyGetReceiptDataQuery
} = postPrepaidListingApi;
