import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, Radio } from '@mui/material';
import { useParams } from 'react-router-dom';

import { CardWrapper } from '@monorepo/common';

import { IEventsStepProps } from '../../interfaces';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { useLazyGetJobDataQuery } from '../../../../services/serviceJobData';

interface IStep1Props extends IEventsStepProps {}
export const Step1 = ({ onNext, Nav }:IStep1Props) => {
  const [price, setPrice] = useState<number>(0);

  const [getJobData, { data, isLoading }] = useLazyGetJobDataQuery();

  const { guid } = useParams<{ guid: string }>();

  useEffect(() => {
    if (guid) {
      getJobData({
        guid,
        siteId: '1'
      });
    }
  }, [guid]);
  useEffect(() => {
    if (data) {
      setPrice(data.header.featurePrice);
    }
  }, [data]);

  return (
    <CardWrapper
      dataCy="step1"
      isLoading={isLoading}
    >
      <FormControlLabel
        checked
        value="price"
        control={<Radio />}
        label={` $${price.toFixed(2)} - Upgrade Price`}
      />
      <Nav
        nextDisabled={false}
        nextButton={(
          <Button
            endIcon={(<ArrowRightIcon fontSize="small" />)}
            variant="contained"
            data-cy="nextStep"
            type="submit"
            onClick={onNext}
          >
            Continue To Payment
          </Button>
            )}
      />
    </CardWrapper>
  );
};
