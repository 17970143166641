import React, { memo } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

interface ILocationTitleProps {
  toggleLocation:(status: boolean) => void,
  value: boolean
}
const LocationTitle = ({ toggleLocation, value }:ILocationTitleProps) => (
  <>
    <Typography
      variant="h6"
      sx={{
        mt: 1,
        mb: 0,
      }}
    >
      Job Location(s)
    </Typography>
    <FormControlLabel
      style={{ marginTop: 0 }}
      checked={value}
      onChange={(event, isCheck) => {
        toggleLocation(isCheck);
      }}
      control={(
        <Checkbox />
            )}
      label={(
        <Typography variant="body1">
          This job is not location specific. Applicants will work remotely / from home.
        </Typography>
            )}
      sx={{
        mb: 2,
        mt: 3
      }}
    />
  </>
);
export default memo(LocationTitle);
