import { jobsApi } from '../services/servicePrepaidPurchase';
import { postAJobApi } from '../services/servicePostAJob';
import { currentUserInfoApi } from '../services/serviceUserInfo';
import { jobDataApi } from '../services/serviceJobData';
import { postPrepaidListingApi } from '../services/servicePostPrepaidListing';

export const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [
    ...getDefaultMiddleware()
      .concat(jobsApi.middleware)
      .concat(jobDataApi.middleware)
      .concat(postAJobApi.middleware)
      .concat(currentUserInfoApi.middleware)
      .concat(postPrepaidListingApi.middleware)
  ];
  return middlewareList;
};
