import React, { useEffect, useState, memo } from 'react';
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Typography } from '@mui/material';
import { IStepsOneInterfaces } from '../../../stepsOneInterfaces';
import { useStyles } from '../../../styles';

interface IPaymentButtonProps {
  dataForStep1: IStepsOneInterfaces[]
}
export const Step3PaymentButton = memo(({ dataForStep1 }:IPaymentButtonProps) => {
  const classes = useStyles();
  const [totalSum, setTotalSum] = useState<number>(0);
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any | null>(null);
  const [finalTitle, setFinalTitle] = useState<string | null>(null);

  useEffect(() => {
    let text: string = '';
    let total: number = 0;
    dataForStep1.forEach((item) => {
      if (item.number > 0) {
        text += `${item.description.replaceAll('<strong>', '').replaceAll('</strong>', '')}: $${item.number * item.itemPrice} \n`;
        total = +total + (+item.number * +item.itemPrice);
      }
    });
    setFinalTitle(text);
    setTotalSum(total);
  }, [dataForStep1]);

  useEffect(() => {
    if (stripe && finalTitle && totalSum > 0) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: finalTitle,
          amount: totalSum * 100
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
          } else {
            console.error("The Google or Apple button doesn't work");
          }
        })
        .catch((e) => {
          console.error('Something went wrong: ', e);
        });
    }
  }, [stripe, totalSum, finalTitle]);

  if (paymentRequest) {
    return (
      <>
        <PaymentRequestButtonElement options={{ paymentRequest }} />
        <Typography
          className={classes.text_divider}
          variant="body2"
        >
          Or pay with card
        </Typography>
      </>
    );
  }
  return null;
});
