import React, { ReactNode } from 'react';
import { IStep1FieldProps } from '../formInterface';

const getStatus = ({ isSubscriber, isCharterMember }:{ isSubscriber?: boolean, isCharterMember?: boolean }) => {
  if (isCharterMember) return ' -- SPECIAL CHARTER MEMBER PRICE';
  if (isSubscriber) return ' -- SPECIAL SUBSCRIBER PRICE';
  if (isCharterMember && isSubscriber) return ' -- SPECIAL CHARTER MEMBER PRICE';
  return null;
};

export const titleFormatter = ({ value, extraPrice, currentPrice, isSubscriber = false, isCharterMember = false }:IStep1FieldProps): string | ReactNode => {
  if (value === 1) {
    if (extraPrice > currentPrice) {
      return (
        <>
          $
          {currentPrice}
          {'  '}
          <del>
            $
            {extraPrice}
          </del>
          {' '}
          - 60 DAY STANDARD LISTING
          {getStatus({ isCharterMember, isSubscriber })}
        </>
      );
    }
    return `$${currentPrice} - 60 DAY STANDARD LISTING`;
  }

  if (extraPrice > currentPrice) {
    return (
      <>
        $
        {currentPrice}
        {' '}
        <del>
          $
          {extraPrice}
        </del>
        {' '}
        - 60 DAY FEATURED LISTING
        {getStatus({ isCharterMember, isSubscriber })}
      </>
    );
  }
  return `$${currentPrice} - 60 DAY FEATURED LISTING`;
};
