import React from 'react';
import { Btn } from '@monorepo/common';
import { Typography } from '@mui/material';
import { useStyles } from '../styles';
import { IEditJobData } from '../interfaces';

interface IStatusButtonOptionsProps {
  guid?: string | null;
  data?: IEditJobData;
  upgradeListing: () => string;
  reactivateFreeJobRequest: () => void
}

export const StatusButtonOptions = ({ guid, data, upgradeListing, reactivateFreeJobRequest }:IStatusButtonOptionsProps) => {
  const classes = useStyles();

  if (!data || !guid) {
    return null;
  }

  if (data.header.isReactivateAllowed) {
    return (
      <div className={classes.head_item_wrapper}>
        <div className={classes.inner_wrapper_content}>
          <Typography
            variant="subtitle1"
              // marginBottom={1}
            textAlign="center"
            fontWeight={700}
          >
            Reactivate your job
          </Typography>
          <Typography
            className={classes.comment}
            textAlign="center"
          >
            Reactivate your job to make your job visible.
          </Typography>

          <div className={classes.btn_wrapper}>
            <Btn
              click={reactivateFreeJobRequest}
            >
              Reactivate
            </Btn>
          </div>
        </div>
      </div>
    );
  }

  if (data.header.featurePrice > 0 && !data?.header?.isFeatured) {
    return (
      <div className={classes.head_item_wrapper}>
        <div className={classes.inner_wrapper_content}>
          <Typography
            variant="subtitle1"
                // marginBottom={1}
            textAlign="center"
            fontWeight={700}
          >
            Upgrade to a
            {' '}
            {data?.header.isFeatured ? 'Standard' : 'Featured'}
            {' '}
            Listing
          </Typography>
          <Typography
            className={classes.comment}
            textAlign="center"
          >
            Get more visibility with more prominent placement of your job.
          </Typography>

          <div className={classes.btn_wrapper_comment}>
            <Btn
              type="button"
              link={
              upgradeListing()
  }
            >
              {data.header.isFeatured ? 'Standard' : 'Featured'}
              {' '}
              Upgrade
            </Btn>
          </div>
          <div className={classes.comment_wrapper}>
            <Typography className={classes.field_comment}>
              Prorated for $
              {data.header.featurePrice.toFixed(2)}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
