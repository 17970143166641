interface IStipeCardErrorsName {
  numbers: string[],
  expiry: string[],
  cvc: string[]
}
export const stipeCardErrorsName: IStipeCardErrorsName = {
  numbers: [
    'incomplete_number',
    'incorrect-number',
    'invalid_number'
  ],
  expiry: [
    'invalid_expiry_year',
    'invalid_expiry_year_past',
    'incomplete_expiry',
    'expired-card',
    'invalid-expiry-month',
    'invalid-expiry-year',
  ],
  cvc: [
    'incomplete_cvc',
    'incorrect-cvc',
    'invalid-cvc'
  ]
};
