import { IStepsOneInterfaces } from './stepsOneInterfaces';
import { IStep1, IStep2, IStep3AllDataProps } from './interfaceSteps';

export const emptyObj:IStepsOneInterfaces = {
  description: '',
  listingsNumber: 11,
  itemPrice: 1990,
  listItemPrice: 1990,
  number: 0,
  listings: 0,
  price: 0
};
export const staticValuesForStep1:IStep1 = {
  standardPackagesQuantity: 1,
  featuredPackagesQuantity: 0
};
export const staticValuesForStep2:IStep2 = {
  companyName: '',
  yourFirstName: '',
  yourLastName: '',
  yourEmail: ''
};
export const staticValuesForStep3:IStep3AllDataProps = {
  stripePaymentMethodId: null,
  country: 'US',
  city: '',
  address: '',
  zip: '',
  lastName: '',
  state: '',
  province: '',
  firstName: '',
  creditCardInfo: {
    ccNumber: '',
    expMonth: '',
    expYear: '',
    cvv: ''
  }
};
