import * as yup from 'yup';

export const schema:any = yup.object().shape({
  isShowBillingState: yup.boolean(),

  billingEmail: yup.string().email().required('Required').nullable(),
  billingCompany: yup.string().nullable(),
  billingFirstName: yup.string().required('Required').nullable(),
  billingLastName: yup.string().required('Required').nullable(),
  billingPhone: yup.string().required('Required.').nullable(),

  billingCountry: yup.string().required('Required').nullable(),
  billingAddress1: yup.string().required('Required').nullable(),
  billingAddress2: yup.string().nullable(),
  billingCity: yup.string().required('Required').nullable(),

  billingState: yup.string().nullable()
    .when('isShowBillingState', {
      is: true,
      then: yup.string().required('Required').nullable(),
      otherwise: yup.string().nullable()
    }),
  billingProvince: yup.string().nullable(),

  billingZip: yup.string().required('Required').nullable(),
});
