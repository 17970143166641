import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import { IHeaderData } from '../interfaces';
import { useStyles } from '../styles';

interface IFirstLineProps {
  data: IHeaderData;
}
export const FirstLine = ({ data: { isFeatured, expiryDate } }:IFirstLineProps) => {
  const classes = useStyles();
  if (moment(expiryDate).isAfter(moment())) {
    return (
      <Typography
        className={classes.comment}
        marginBottom={1}
      >
        This
        {' '}
        <b>
          {isFeatured ? 'Featured' : 'Standard'}
          {' '}
          Job Listing
          {' '}
        </b>
        is set to expire on
        {' '}
        {moment(expiryDate).format('MM/DD/YYYY')}
        .
      </Typography>
    );
  }
  return (
    <Typography
      className={classes.comment}
      marginBottom={1}
    >
      This
      {' '}
      <b>
        {isFeatured ? 'Featured' : 'Standard'}
        {' '}
        Job Listing
      </b>
      <b style={{ color: 'red' }}> Expired </b>
      on
      {' '}
      {moment(expiryDate).format('MM/DD/YYYY')}
      .
      It is no longer visible to our users.
    </Typography>
  );
};
