import { BasicLayout, scrollUp } from '@monorepo/common';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MetaDescription } from '../../components/MetaDescription';
import { Hosts } from '../../constants/hostName';
import { showNotification } from '../../features/sliceNotification';
import {
  useLazyGetJobDataQuery,
  useReactivateJobMutation,
} from '../../services/serviceJobData';
import { useAppSelector } from '../../store/hooks';
import { setPageTitle } from '../../utils/setPageTitle';
import DescriptionJobs from './components/DescriptionJobs';
import FinalComponent from './components/FinalComponent';
import { StepperPanel } from './stepper/StepperPanel';

export const ReactivateListing = () => {
  const dispatch = useDispatch();
  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber,
  }));
  const [titleBrowserPage, setTitleBrowserPage] = useState<string>('Jobs');
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [getJobData, { data, isLoading }] = useLazyGetJobDataQuery();
  const [reactivateRequest, { data: reactivateResponse, isLoading: isReactivateLoading, error, isSuccess }] = useReactivateJobMutation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const guid = query.get('path');

  useEffect(() => {
    if (guid) {
      getJobData({
        siteId: siteId.toString(),
        guid,
      });
    }
  }, [getJobData, guid, siteId]);

  const reactivateFormRequest = (formData: { body: any; id: string }) => {
    reactivateRequest({
      body: {
        guid,
        ...formData.body,
      },
      validateOnly: false,
      id: siteId.toString(),
    });
  };

  useEffect(() => {
    if (error && 'data' in error) {
      const resp = error as { data: { error: string } };
      scrollUp();
      dispatch(
        showNotification({
          show: true,
          type: 'error',
          text: resp.data.error,
        })
      );
    }
    if (isSuccess) {
      setIsComplete(true);
      dispatch(
        showNotification({
          show: true,
          type: 'success',
          text: 'Job was reactivated!',
        })
      );
    }
  }, [error, isSuccess]);

  useEffect(() => {
    if (isComplete) {
      const timeout = setTimeout(() => {
        setIsComplete(false);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [isComplete]);

  useEffect(() => {
    setPageTitle({
      firstTitle: 'Reactivate Job Listing',
      siteNumber: siteId || Hosts.BEVNET,
      setTitle: setTitleBrowserPage,
    });
  }, [siteId]);

  return (
    <BasicLayout
      dataCy="reactivateJobListing"
      isLoading={isLoading}
      hiddenContentWhenIsLoading={isLoading}
      helmet={MetaDescription({
        title: titleBrowserPage,
        siteNumber: siteId,
      })}
    >
      <div>
        {guid && data && (
          <StepperPanel
            guid={guid}
            isComplete={isComplete}
            isStepperLoading={isReactivateLoading}
            finalStepperRequest={reactivateFormRequest}
            leftInfoPanel={(
              <DescriptionJobs
                jobTitle={data.stepper.step1.jobTitle}
                companyName={data.stepper.step1.companyName}
              />
              )}
            finalComponent={<FinalComponent data={reactivateResponse} />}
          />
        )}
      </div>
    </BasicLayout>
  );
};
