import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader, useStepper } from '@monorepo/common';

import { FinalStep } from './FinalStep';
import { StepperInfoPanel } from './StepperInfoPanel';

import {
  staticValuesForStep2,
  staticValuesForStep3,
  staticValuesForStep1,
  staticValuesForStep4,
  staticValuesForStep5, staticValuesForStep6
} from './staticValues';

import { IStep1Form } from './Step1/formInterface';
import { IStep2Form } from './Step2/formInterfaces';
import { IStep3Form } from './Step3/formInterface';
import { IStep4Form } from './Step4/formInterface';
import { IStep5Form } from './Step5/formInterfaces';
import { IStep6Form } from './Step6/formInterfaces';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step6 } from './Step6';
import { IEditJobData } from '../interfaces';
import { formattedLocations } from '../utils/formattedlocation';
import { useEditJobMutation } from '../../../services/serviceJobData';
import { cloudfrontImagesBaseURL } from '../../../components/JobStepper/staticValues';

interface IStepperPanelProps {
  data: IEditJobData;
  guid: string;
}

export const StepperPanel = ({ data, guid }:IStepperPanelProps) => {
  const [editJob, { isLoading, isSuccess }] = useEditJobMutation();
  const [step1, setStep1] = useState<IStep1Form>(staticValuesForStep1);
  const [step2, setStep2] = useState<IStep2Form>(staticValuesForStep2);
  const [step3, setStep3] = useState<IStep3Form>(staticValuesForStep3);
  const [step4, setStep4] = useState<IStep4Form>(staticValuesForStep4);
  const [step5, setStep5] = useState<IStep5Form>(staticValuesForStep5);
  const [step6, setStep6] = useState<IStep6Form>(staticValuesForStep6);

  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons } = useStepper({
    orientation: 'vertical',
    isHideLeftInfoPanelAfterComplete: true,
    leftInfoPanel: (
      <StepperInfoPanel
        data={data}
        guid={guid}
      />
    ),
    finalComponent: <FinalStep />,
    isLoading
  });

  const finalRequest = useCallback(() => {
    editJob({
      id: guid,
      body: {
        step1,
        step2,
        step3,
        step4: {
          ...step4,
          uploadedLogoPath: step4.uploadedLogoPath?.replace(cloudfrontImagesBaseURL, '')
        },
        step5
      }
    });
  }, [step1, step2, step3, step4, step5, step6]);

  const steps = useMemo(() => [
    {
      label: 'Basic Job Details',
      content: (
        <Step1
          setStep1={setStep1}
          defaultData={step1}
          Nav={StepsButtons}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Compensation & Location',
      content: (
        <Step2
          setStep2={setStep2}
          defaultData={step2}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Job Description',
      content: (
        <Step3
          setStep3={setStep3}
          defaultData={step3}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Upload Logo',
      content: (
        <Step4
          setStep4={setStep4}
          defaultData={step4}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Contact and Application Details',
      content: (
        <Step5
          defaultValue={step5}
          setStep5={setStep5}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
        />
      )
    },
    {
      label: 'Terms and Conditions',
      content: (
        <Step6
          isEdited={isSuccess}
          Nav={StepsButtons}
          onNext={onNext}
          onBack={onBack}
          defaultData={step6}
          onComplete={onComplete}
          finalRequest={finalRequest}
        />
      )
    },
  ], [data, step1, step2, step3, step4, step5, step6, isSuccess]);

  useEffect(() => {
    if (data) {
      setStep1(data.stepper.step1);
      setStep2({
        ...data.stepper.step2,
        locations: formattedLocations(data.stepper.step2.locations)
      });
      setStep3(data.stepper.step3);
      setStep4(data.stepper.step4);
      setStep6(data.stepper.step6);

      if (data.stepper.step5.applicationEmail && !data.stepper.step5.tempAdditionalEmails) {
        const arr:{ email: string }[] = [];
        const tempArr: string[] = data.stepper.step5.applicationEmail.split(', ');
        tempArr.forEach((item) => {
          arr.push({
            email: item.replaceAll(';', '')
          });
        });

        setStep5({
          ...data.stepper.step5,
          showEmails: true,
          tempAdditionalEmails: arr
        });
        return;
      }

      setStep5(data.stepper.step5);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    putSteps(steps);
  }, [putSteps, step1, step2, step3, step4, step5, step6]);

  if (!data) {
    return <Loader isShow />;
  }

  return renderStepper;
};
