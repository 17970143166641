import React, { memo, useEffect } from 'react';
import { Button, RadioGroup, Typography } from '@mui/material';

import { BasicLayout, PlugNoData } from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../Icons/arrow-right';
import { useGetJobListingTypesQuery } from '../../../../services/servicePostAJob';
import { IEventsStepProps } from '../../interfaceSteps';
import { useAppSelector } from '../../../../store/hooks';
import MultipleJobListing from './MultipleJobListing';
import { Step1Field } from './components/Step1Field';
import { IStep1Form } from './formInterface';
import { JobListingTypesResponse } from '../../../../services/servicePostAJob/responseInterfaces';

interface IStep1Props extends IEventsStepProps {
  setJobListing: (a:IStep1Form) => void,
  setIsRequest: (a: boolean) => void,
  setIsInternalLoading: (a: boolean) => void,
  defaultData: IStep1Form,
  setJobListingTypes: (data:JobListingTypesResponse) => void
}

export const Step1 = memo(({ onNext, setJobListing, defaultData, Nav, setIsRequest, setIsInternalLoading, setJobListingTypes }:IStep1Props) => {
  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber
  }));
  const { data: dataList, isLoading } = useGetJobListingTypesQuery(siteNumber.toString());

  const send = () => {
    if (onNext) {
      setIsRequest(true);
      onNext();
    }
  };

  const changeRadio = (a: any, val: string) => {
    setJobListing({ listingType: Number(val) });
  };

  useEffect(() => {
    setIsInternalLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setJobListingTypes(dataList);
  }, [dataList]);

  return (
    <BasicLayout
      dataCy="step1"
      isHideChildren={isLoading}
      isLoading={isLoading}
    >
      <Typography
        sx={{ mb: 3 }}
        variant="h6"
        fontWeight={800}
      >
        SINGLE JOB LISTING
      </Typography>

      <RadioGroup
        sx={{
          '& > *:not(:last-of-type)': {
            mb: 2
          }
        }}
        value={defaultData.listingType}
        onChange={(a, value) => changeRadio(a, value)}
      >
        {dataList ? (
          <>
            <Step1Field
              value={1}
              dataCy="jobListingStandard"
              currentPrice={dataList.standardPrice}
              extraPrice={dataList.listStandardPrice}
            />
            <Step1Field
              value={2}
              dataCy="jobListingFeatured"
              currentPrice={dataList.featuredPrice}
              extraPrice={dataList.listFeaturedPrice}
            />
          </>
        ) : <PlugNoData />}
      </RadioGroup>

      <Nav
        nextDisabled={false}
        onNext={onNext}
        nextButton={(
          <Button
            endIcon={(<ArrowRightIcon fontSize="small" />)}
            variant="contained"
            disabled={!dataList || !Object.keys(dataList)}
            type="button"
            onClick={send}
            data-cy="nextStep"
          >
            Next step
          </Button>
              )}
      />

      <MultipleJobListing />
    </BasicLayout>
  );
});
