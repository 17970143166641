import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Btn, BasicLayout } from '@monorepo/common';
import { useStyles } from '../styles';
import { ModalBody } from './ModalBody';

interface ICopyOptionsProps {
  guid?: string | null;
  title?: string | null;
  copyJob: () => void;
  isLoading?: boolean;
}
export const CopyOptions = ({ guid, title, copyJob, isLoading }:ICopyOptionsProps) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const classes = useStyles();
  const showModal = () => {
    setIsModal(true);
  };
  const closeModal = () => {
    if (!isLoading) {
      setIsModal(false);
    }
  };

  if (!guid || !title) {
    return null;
  }

  return (
    <BasicLayout
      breadcrumbs={null}
      modalWindowOptions={{
        show: isModal,
        title: `Copy Job Listing: ${title}`,
        closeModal,
        modalBody: <ModalBody
          isLoading={isLoading}
          copyJob={copyJob}
        />
      }}
    >
      <div className={classes.head_item_wrapper}>
        <div className={classes.inner_wrapper_content}>
          <Typography
            variant="subtitle1"
            textAlign="center"
            fontWeight={700}
          >
            Copy this Listing
          </Typography>

          <Typography
            className={classes.comment}
            textAlign="center"
          >
            Use this job as a template for another post.
          </Typography>

          <div className={classes.btn_wrapper_copy}>
            <Btn click={showModal}>
              Copy This Job
            </Btn>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};
