import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ErrorBoundary, FormAutocomplete, FormField, GlobalInterfaces, CardWrapper, useHookForm } from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { convertingApplicationEmail } from './funcConvertingApplicationEmail';
import AdditionalEmail from './components/AdditionalEmail';
import { EOptions, step5Options } from './step5Options';
import { IEventsStepProps } from '../../interfaces';
import { IStep5Form } from './formInterfaces';
import { schema } from './schema';
import { useGetUserInfoQuery } from '../../../../services/serviceUserInfo';

interface IStep5Props extends IEventsStepProps {
  setStep5: (value: IStep5Form) => void;
  defaultValue: IStep5Form
}

export const Step5 = memo(({
  Nav,
  onNext,
  onBack,
  setStep5,
  defaultValue
}:IStep5Props) => {
  const [isShowUrl, setIsShowUrl] = useState(false);
  const [changedFields, setChangedFields] = useState<IStep5Form | null>(null);

  const { data: userData, isLoading } = useGetUserInfoQuery();

  const {
    useFieldArray,
    setValue,
    register,
    handleSubmit,
    getValues,
    control,
    errors
  } = useHookForm<IStep5Form>({
    schema,
    defaultValues: defaultValue,
    showChangedFields: (a) => {
      setChangedFields(a);
    }
  });

  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(defaultValue).toString() !== JSON.stringify(changedFields))) {
        setStep5({
          ...changedFields,
          applicationEmail: convertingApplicationEmail(changedFields.tempAdditionalEmails),
          applicationUrlEnabled: !!isShowUrl
        });
      }
      onBack();
    }
  };

  const { fields, append, remove } = useFieldArray({
    name: 'tempAdditionalEmails',
    control
  });

  const increase = useCallback(() => {
    append({
      email: null
    });
  }, [append]);

  const decrease = (id: number) => {
    if (fields.length !== 0) {
      remove(id);
    }
  };

  const onSubmit = (data: IStep5Form) => {
    if (data && onNext) {
      data.applicationEmail = convertingApplicationEmail(data.tempAdditionalEmails);
      data.applicationUrlEnabled = data.applicationUrlEnabled !== '0';
      setStep5(data);
      onNext();
    }
  };

  useEffect(() => {
    Object.keys(defaultValue).forEach((item) => {
      if (item !== 'tempAdditionalEmails' && item !== 'applicationURLEnabled') {
        // @ts-ignore
        setValue(item, defaultValue[item]);
      }
    });
    if (!defaultValue.applicationUrlEnabled) {
      setValue('applicationUrlEnabled', step5Options[0].value);
    }
    if (defaultValue.applicationUrlEnabled) {
      setValue('applicationUrlEnabled', step5Options[1].value);
    }

    setIsShowUrl(defaultValue.applicationUrlEnabled as boolean);

    setValue('tempAdditionalEmails', []);
    let emailStr = '';
    if (defaultValue?.tempAdditionalEmails?.length > 0) {
      defaultValue.tempAdditionalEmails.forEach((item) => {
        if (item.email) {
          append(item);
          emailStr += `${item.email}; `;
        }
      });
    }
    if (!defaultValue.applicationUrlEnabled && defaultValue.applicationEmail?.length === 0) {
      append({
        email: null
      });
    }
    if (defaultValue?.showEmails === null) {
      setValue('showEmails', false);
    }

    setValue('applicationEmail', emailStr);
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValue && userData) {
      if (!defaultValue.email) {
        setValue('email', userData.email);
      }
      if (!defaultValue.contactFirstName) {
        setValue('contactFirstName', userData.firstName);
      }
      if (!defaultValue.contactLastName) {
        setValue('contactLastName', userData.lastName);
      }
    }
  }, [userData, defaultValue]);

  return (
    <CardWrapper
      isLoading={isLoading}
      scrollOptions={{
        isErrorsAutoScrollPage: errors
      }}
    >
      <form
        data-cy="step6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          style={{ display: 'none' }}
          type="checkbox"
          {...register('showEmails')}
        />
        <input
          type="hidden"
          {...register('applicationEmail')}
        />
        <>
          <Typography
            variant="body2"
            sx={{
              mb: 4
            }}
          >
            Please enter a contact for this job listing. This information is only used for billing/editing and is NOT displayed publicly.
          </Typography>
          <FormField
            isRequired
            extraProps={{ ...register('email') }}
            errors={errors}
            label="Your Email"
          />
          <FormField
            isRequired
            extraProps={{ ...register('contactFirstName') }}
            errors={errors}
            label="Your First Name"
          />
          <FormField
            isRequired
            extraProps={{ ...register('contactLastName') }}
            errors={errors}
            label="Your Last Name"
          />

          <Typography
            variant="h6"
            sx={{
              mb: 2
            }}
          >
            Job Application Delivery Method
          </Typography>
        </>

        <FormAutocomplete<GlobalInterfaces.IOption>
          optionsSettings={{
            optionText: 'text',
            optionValue: 'value'
          }}
          fieldComment="Configure how you would like to receive applications for this job listing:"
          extraProps={{ ...register('applicationUrlEnabled') }}
          errors={errors}
          getValues={getValues}
          options={step5Options}
          disableClearable
          onChange={(option) => {
            if (option && !Array.isArray(option)) {
              setValue('applicationUrlEnabled', option.value);
              setValue('applicationUrl', null);
              if (option.value === EOptions.APPLICATIONS) {
                increase();
                setIsShowUrl(false);
                setValue('applicationUrl', null);
                setValue('showEmails', true);
                return;
              }
              setValue('tempAdditionalEmails', []);
              setValue('showEmails', false);
              setIsShowUrl(true);
            }
          }}
        />

        <Typography
          variant="h6"
          sx={{
            mb: 2
          }}
        >
          Applications for this job will be sent to the following email address(es):
        </Typography>

        <ErrorBoundary>
          {!isShowUrl && fields && fields.length ? fields.map((item, index) => (
            <AdditionalEmail
              generalFieldsLength={fields.length}
              id={index}
              register={register}
              errors={{
                ...(errors?.tempAdditionalEmails) && ({ ...errors.tempAdditionalEmails[index] })
              }}
              decrease={decrease}
              key={item.id}
              nameField={`tempAdditionalEmails.${index}.email` as const}
            />
          )) : null}
        </ErrorBoundary>

        <FormField
          isRequired
          extraProps={{ ...register('applicationUrl') }}
          placeholder="Url"
          visible={isShowUrl}
          errors={errors}
          label="URL"
        />

        {!isShowUrl && (
        <Button
          color="error"
          startIcon={<AddCircleOutlineIcon color="error" />}
          onClick={() => {
            increase();
          }}
          sx={{ mt: 3 }}
        >
          Add additional email
        </Button>
        )}

        <Nav
          nextDisabled={false}
          onBack={goBack}
          nextButton={(
            <Button
              endIcon={(<ArrowRightIcon fontSize="small" />)}
              variant="contained"
              data-cy="nextStep"
              type="submit"
            >
              Next Step
            </Button>
          )}
        />
      </form>
    </CardWrapper>
  );
});
