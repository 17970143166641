import React, { memo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { UserMenu } from '@monorepo/common';
import { useStyles } from './styles';
import { HeaderLeftSide } from './components/HeaderLeftSide';

// interface IHeaderProps {}
const Header = () => {
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      className={classes.header}
    >
      <Toolbar>
        <HeaderLeftSide />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default memo(Header);
