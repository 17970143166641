import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cookies from 'js-cookie';
import { cookieNames } from '@monorepo/common';
import { stepperInitialState, IStepperInitialState } from './stepperInitialState';

export const slice = createSlice({
  name: 'stepper',
  initialState: stepperInitialState,
  reducers: {
    dropStepper: (state:IStepperInitialState, action:PayloadAction<IStepperInitialState>): void => {
      state.isDrop = action.payload.isDrop;
    },
    showDropButton: (state:IStepperInitialState): void => {
      if (cookies.get(cookieNames.tempJob)) {
        state.isShowDropButton = true;
        return;
      }
      state.isShowDropButton = false;
    },
    showExtraDropButton: (state:IStepperInitialState, action:PayloadAction<{ tempKay: string }>): void => {
      if (cookies.get(action.payload.tempKay)) {
        state.isShowDropButton = true;
        return;
      }
      state.isShowDropButton = false;
    },
  },
});

export const { dropStepper, showDropButton, showExtraDropButton } = slice.actions;
export default slice.reducer;
