import React, { memo, useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { CardWrapper, FormAutocomplete, FormField, useHookForm } from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { useAppSelector } from '../../../../store/hooks';
import { IEventsStepProps } from '../../interfaces';
import { IStep1Form } from './formInterface';
import { schema } from './schema';
import { useGetEmploymentTypesQuery, useGetIndustryQuery } from '../../../../services/servicePostAJob';

interface IStep1Props extends IEventsStepProps {
  defaultData: IStep1Form,
  setStep1: (res: IStep1Form) => void
}
interface IOptions {
  selected?: boolean,
  value: string | number,
  text: string
}

export const Step1 = memo(({ onBack, onNext, defaultData, setStep1, Nav }:IStep1Props) => {
  const { hostNumber, isHost } = useAppSelector(({ pathnameSettings }) => ({
    hostNumber: pathnameSettings.siteNumber.toString(),
    isHost: pathnameSettings.host
  }));

  const { data: listIndustry, isLoading: isLoadingIndustry } = useGetIndustryQuery(hostNumber, {
    skip: !isHost
  });
  const { data: employmentTypeOptions, isLoading: isLoadingEmploymentType } = useGetEmploymentTypesQuery(null, {
    skip: !isHost
  });

  const [changedFields, setChangedFields] = useState<IStep1Form | null>(null);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors,
    setFormData
  } = useHookForm<IStep1Form>({
    schema,
    showChangedFields: (a) => {
      setChangedFields(a);
    }
  });

  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(defaultData).toString() !== JSON.stringify(changedFields))) {
        setStep1(changedFields);
      }
      onBack();
    }
  };

  const onSubmit = (data: IStep1Form) => {
    if (data && setStep1 && onNext) {
      setStep1(data);
      onNext();
    }
  };

  useEffect(() => {
    setFormData(defaultData);
  }, [defaultData]);

  return (
    <CardWrapper
      scrollOptions={{
        isErrorsAutoScrollPage: errors
      }}
    >
      <form
        data-cy="step2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          isRequired
          extraProps={{ ...register('jobTitle') }}
          errors={errors}
          label="Job Title"
          maxLength={120}
        />
        <FormField
          isRequired
          errors={errors}
          label="Company Name"
          maxLength={50}
          extraProps={{ ...register('companyName') }}
          fieldComment={'For a blind listing, please describe your company in general terms, i.e. "Energy Drink Manufacturer"'}
        />
        <FormAutocomplete<IOptions>
          optionsSettings={{
            optionText: 'text',
            optionValue: 'value'
          }}
          disableClearable
          isRequired
          isLoading={isLoadingIndustry}
          fieldComment="Please select the category that best matches your company and the type of job your are hiring for."
          extraProps={{ ...register('industrySectorId') }}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          options={listIndustry || []}
          label="Job Category"
        />
        <FormAutocomplete<IOptions>
          optionsSettings={{
            optionText: 'text',
            optionValue: 'value'
          }}
          isLoading={isLoadingEmploymentType}
          isRequired
          disableClearable
          extraProps={{ ...register('employmentTypeId') }}
          errors={errors}
          getValues={getValues}
          options={employmentTypeOptions || []}
          label="Employment Type"
          setValue={setValue}
        />

        <Nav
          nextDisabled={false}
          onBack={goBack}
          nextButton={
                (
                  <Button
                    endIcon={(<ArrowRightIcon fontSize="small" />)}
                    variant="contained"
                    disabled={false}
                    type="submit"
                    data-cy="nextStep"
                  >
                    Next Step
                  </Button>
                )
              }
        />
      </form>
    </CardWrapper>
  );
});
