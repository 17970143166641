import * as yup from 'yup';

export const schema:any = yup.object().shape({
  salaryUnitId: yup.number().nullable(),

  showSalaryExact: yup.boolean(),

  salaryMin: yup.number().nullable().when('salaryUnitRadio', {
    is: 2,
    then: yup.number().required('Required').nullable(),
    otherwise: yup.number().nullable()
  }),
  salaryMax: yup.number().nullable().when('salaryUnitRadio', {
    is: 2,
    then: yup.number().required('Required').nullable(),
    otherwise: yup.number().nullable()
  }),
  salaryExact: yup.number().nullable()
    .when('showSalaryExact', {
      is: true,
      then: yup.number().required('The Salary Exact field is required.').nullable(),
      otherwise: yup.number().nullable()
    }),
  noneLocation: yup.boolean(),
  salaryUnitRadio: yup.number(),

  locations: yup.array().of(
    yup.object().shape({
      isShowState: yup.boolean(),
      country: yup.string().nullable(),
      address1: yup.string().nullable(),
      address2: yup.string().nullable(),
      state: yup.string().nullable()
        .when('isShowState', {
          is: true,
          then: yup.string().required('Required').nullable(),
          otherwise: yup.string().nullable()
        }),
      province: yup.string().nullable(),
      zip: yup.string().nullable(),
      city: yup.string().nullable().required('Required'),
    })
  )
});
