import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardNumberElement, PaymentIntent, StripeError } from '@stripe/stripe-js';

import { GlobalInterfaces } from '@monorepo/common';

import { showNotification } from '../../../../features/sliceNotification';
import { Step2FormData } from '../../interfaces';

interface IStripeRequestStep2Props {
  stripe?: any;
  elements: any;
  dispatch: any;
  clientSecret: string;
  formData: Step2FormData;
  setIsLoading:(val:boolean) => void,
  setStripeError: (val:GlobalInterfaces.IStripeErrorProps | null) => void
}
export const stripeRequestStep2 = async ({
  stripe,
  elements,
  dispatch,
  clientSecret,
  formData,
  setIsLoading,
  setStripeError
}:IStripeRequestStep2Props) => {
  if (stripe && elements) {
    const { paymentIntent, error }:{ paymentIntent:PaymentIntent, error:StripeError } = await stripe.confirmCardPayment(
      clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement) as StripeCardNumberElement | StripeCardElement | { token: string; },
          billing_details: {
            name: `${formData.billingFirstName} ${formData.billingLastName}`,
            email: formData.billingEmail,
            phone: formData.billingPhone,
            address: {
              country: formData.billingCountry,
              state: formData.billingState ?? formData.billingProvince,
              city: formData.billingCity,
              line1: formData.billingAddress1,
              line2: formData.billingAddress2,
              postal_code: formData.billingZip
            }
          }
        }
      }
    );

    if (paymentIntent && paymentIntent.id) {
      setStripeError(null);
      return paymentIntent.id;
    }
    if (error?.message) {
      setStripeError({ message: error.message, code: error.code ?? '', type: error.type });
      dispatch(showNotification({
        show: false,
        type: 'error',
        text: error.message
      }));

      setIsLoading(false);
    }
    return null;
  }
  setIsLoading(false);
  dispatch(showNotification({
    show: false,
    type: 'error',
    text: 'Something went wrong with stripe, country code or with contact data'
  }));
  return null;
};
