import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';

import { CardWrapper } from '@monorepo/common';

import { IEventsStepProps } from '../../interfaces';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IStep6Form } from './formInterfaces';

interface IStep6Props extends IEventsStepProps {
  defaultData: IStep6Form;
  finalRequest: () => void;
  isEdited: boolean;
}

export const Step6 = ({ onBack, Nav, onComplete, defaultData, finalRequest, isEdited }:IStep6Props) => {
  const onSubmit = () => {
    if (defaultData?.terms) {
      finalRequest();
    }
  };

  useEffect(() => {
    if (isEdited && onComplete) {
      onComplete();
    }
  }, [isEdited]);

  return (
    <div data-cy="step6">
      <CardWrapper>
        <div style={{ maxHeight: 500, overflowY: 'auto' }}>
          <Typography
            whiteSpace="break-spaces"
            variant="subtitle1"
          >
            {defaultData.terms}
          </Typography>
        </div>
      </CardWrapper>

      <Nav
        nextDisabled={false}
        onBack={onBack}
        nextButton={(
          <Button
            endIcon={(<ArrowRightIcon fontSize="small" />)}
            variant="contained"
            onClick={onSubmit}
            data-cy="acceptTermsAndContinue"
          >
            Accept Terms and Continue
          </Button>
                )}
      />
    </div>
  );
};
