import React, { memo, useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { Editor, FormField, CardWrapper, useHookForm } from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IEventsStepProps } from '../../interfaces';
import { IStep3Form } from './formInterface';
import { schema } from './schema';

interface IStep4Props extends IEventsStepProps {
  defaultData: IStep3Form | null,
  setStep3: (value: IStep3Form) => void,
}

const empty:string = '<p><br></p>';
export const Step3 = memo(({ onBack, onNext, setStep3, defaultData, Nav }:IStep4Props) => {
  const [editorValue, setEditorValue] = useState<string>(defaultData?.jobDescription || empty);
  const [changedFields, setChangedFields] = useState< IStep3Form | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    errors
  } = useHookForm<IStep3Form>({
    schema,
    showChangedFields: (a) => {
      setChangedFields(a);
    }
  });
  const editorChange = (res: string) => {
    setEditorValue(res);
  };

  const submit = (data:IStep3Form) => {
    if (setStep3 && onNext) {
      setStep3(data);
      onNext();
    }
  };

  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(defaultData).toString() !== JSON.stringify(changedFields))) {
        setStep3(changedFields);
      }
      onBack();
    }
  };

  useEffect(() => {
    if (editorValue === '<p><br></p>') {
      setValue('jobDescription', null);
      return;
    }
    setValue('jobDescription', editorValue.trim());
  }, [editorValue]);

  useEffect(() => {
    if (defaultData) {
      setValue('jobDescription', defaultData.jobDescription === '<p><br></p>' ? null : defaultData.jobDescription);
      setValue('shortDescription', defaultData.shortDescription);
    }
  }, []);

  return (
    <CardWrapper>
      <form
        onSubmit={handleSubmit(submit)}
        data-cy="step3"
      >
        <Editor
          isRequired
          value={editorValue}
          onChange={editorChange}
          fieldCommentTop="Full Job Description"
          error={errors.jobDescription ? (errors.jobDescription.message as string | null) : null}
          fieldCommentBottom="We recommend including the job description, job requirements, and candidate qualifications as well as some information about the company."
        />

        <FormField
          isRequired
          type="textarea"
          textAreaRow={9}
          maxLength={100}
          extraProps={{ ...register('shortDescription') }}
          errors={errors}
          label="Short Description"
        />

        <Nav
          nextDisabled={false}
          onBack={goBack}
          nextButton={(
            <Button
              endIcon={(<ArrowRightIcon fontSize="small" />)}
              variant="contained"
              disabled={false}
              type="submit"
              data-cy="nextStep"
            >
              Next Step
            </Button>
                )}
        />
      </form>
    </CardWrapper>
  );
});
