import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

export const useStyles = makeStyles((theme) => ({
  location_wrapper: {
    '& ~ $location_wrapper': {
      marginTop: 45
    }
  },
  final_block_item: {
    '&$final_block_item': {
      marginTop: 20,
    }
  },
  last_field: {
    marginBottom: 10
  },
  root: {
    paddingTop: '2rem',
    ...Mixin.mixin.flex('flex-start', 'center'),
    '@media(max-width: 1200px)': {
      display: 'block'
    }
  },
  wrapper_card: {
    padding: '40px 20px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius
  },
  description_page: {
    '& h1': {
      textAlign: 'center'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
      marginTop: '2rem',
    },
    '& p, & b,& li': {
      fontSize: 16
    },
    '& ul': {
      paddingLeft: '1.3rem'
    },
    '& p, & ul': {
      marginBottom: '1rem'
    },
    '& li': {
      marginBottom: '.7rem'
    },
  },
  table_wrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
    ...Mixin.mixin.br()
  },
  link: {
    color: theme.palette.error.main
  },
  wrapper_for_steps: {
    width: '100%',
    maxWidth: '50rem',
    '& .MuiAvatar-root': {
      backgroundColor: '#e21836'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
    }
  },
  jobs_line_buttons: {
    marginTop: '2rem',
    ...Mixin.mixin.flex('center', 'flex-start'),
    minHeight: 30,
    '@media(max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  previous_btn: {
    '@media(max-width: 600px)': {
      marginLeft: '0 !important',
      marginTop: ' 15px !important'
    }
  },
  active_step: {
    padding: '32px 8px 32px 20px',
    borderLeft: '2px solid rgb(230, 232, 240) !important',
    marginLeft: '20px !important'
  },
  stepper: {
    '& .MuiStepConnector-line': {
      ml: 1,
      borderLeftColor: 'divider',
      borderLeftWidth: 2
    }

  },
  step3_address_wrap: {
    ...Mixin.mixin.flex('flex-start', 'flex-start'),
    gridGap: '2rem',
    marginBottom: '2rem',
    '& > div': {
      flex: 1,
      marginBottom: 0,
      '@media(max-width: 768px)': {
        marginBottom: '2rem'
      }
    },
    '@media(max-width: 768px)': {
      display: 'block'
    }
  },
  error: {},
  error_text: {
    position: 'absolute',
    left: 0,
    bottom: '-23px',
    color: 'red'
  },
  stripe_field: {
    marginBottom: '2rem',
    position: 'relative',
    borderRadius: (theme.shape.borderRadius as number) / 2,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&$error': {
      border: '1px solid red',
      marginBottom: '2.5rem'
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '& input:-webkit-autofill': {
      ' -webkit-text-fill-color': 'inherit !important',
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important'
    }
  },
  stripe_field_label: {
    position: 'absolute',
    background: '#fff',
    padding: '0 6px',
    top: '-12px',
    left: 8,
    fontWeight: 400,
    fontSize: '0.8rem',
    zIndex: 1,
    color: 'rgba(0, 0, 0, 0.6)',
    pointerEvents: 'none'
  },
  card_99: {
    padding: '0 14px',
    '& input:-webkit-autofill': {
      ' -webkit-text-fill-color': 'inherit !important',
      '-webkit-box-shadow': '0 0 0 100px #fff inset !important'
    }
  },
  multiple_job_listing: {
    marginTop: 40
  },
  text_error: {
    fontSize: 'inherit',
    textDecoration: 'none',
    color: theme.palette.error.main
  },
  couple_line: {
    ...Mixin.mixin.flex('flex-start', 'center'),
    gridGap: '1rem',
    '@media(max-width: 768px)': {
      display: 'block'
    },
    '& > div': {
      flex: 1
    }
  },
  card: {
    fill: 'red'
  },
  wrapper_for_additional_email: {
    ...Mixin.mixin.flex('center', 'space-between'),
    '& + $wrapper_for_additional_email': {
      marginTop: '1rem'
    }
  },
  additional_email: {
    marginBottom: 0,
    flex: 1
  },
  additional_email_remove: {
    cursor: 'pointer',
    marginLeft: '1rem',
    '&:hover': {
      fill: 'red'
    }
  },
  apple_pay_button: {
    color: '#fff',
    background: '#000',
    '& > span:first-child': {
      ...Mixin.mixin.flex('center', 'center'),
    },
    '& svg': {
      margin: '0 10px'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0, .7)',
    }
  },
  text_divider: {
    textAlign: 'center',
    ...Mixin.mixin.flex('center', 'center'),
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '2rem 0 2.2rem !important',
    gridGap: '2rem',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      height: 1,
      flex: 1,
      background: 'rgba(0, 0, 0, 0.2)',
    }
  },
  logoFieldError: {
    background: 'red'
  },
  wrapper_for_radio_group: {
    '@media(max-width: 650px)': {
      '& label': {
        margin: 0,
        alignItems: 'flex-start'
      }
    }
  },
  left: {
    order: 0
  },
  right: {
    order: 1,
    textAlign: 'center'
  },
  line: {
    marginLeft: 15,
    ...Mixin.mixin.flex(),
    '@media(max-width: 650px)': {
      marginLeft: 0,
      display: 'block',
      textAlign: 'center'
    },
    '& $left': {
      paddingRight: 10,
      width: 'calc(100% - 10rem)',
      '@media(max-width: 650px)': {
        width: '100%',
        paddingRight: 0,
      }
    },
    '& $right': {
      width: '100%',
      maxWidth: '10rem',
      '@media(max-width: 650px)': {
        margin: '0 auto 20px'
      }
    },
  }
}));

export const stripeFieldOptions = {
  showIcon: true,
  style: {
    base: {
      padding: '15px',
      lineHeight: '56px',
    },
  }
};
