import React, { memo } from 'react';
import { Typography, Container, useMediaQuery, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BasicLayout from '../../containers/BasicLayout';

// interface IPage404Props {}
export const ForPage404 = memo(() => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  return (
     <BasicLayout>
        <Box
           sx={{
             alignItems: 'center',
             backgroundColor: 'background.paper',
             display: 'flex',
             flexGrow: 1,
             py: '30px'
           }}
        >
           <Container maxWidth="lg">
              <Typography
                 align="center"
                 variant={mobileDevice ? 'h4' : 'h1'}
              >
                 404: The page you are looking for isn’t here
              </Typography>
              <Typography
                 align="center"
                 color="textSecondary"
                 sx={{ mt: 0.5 }}
                 variant="subtitle2"
              >
                 You either tried some shady route or you
                 came here by mistake. Whichever it is, try using the
                 navigation.
              </Typography>
              <Box
                 sx={{
                   display: 'flex',
                   justifyContent: 'center',
                   mt: 6
                 }}
              >
                 <Box
                    alt="Under development"
                    component="img"
                    src={`/static/error404_${theme.palette.mode}.svg`}
                    sx={{
                      height: 'auto',
                      maxWidth: '100%',
                      width: 400
                    }}
                 />
              </Box>
              <Box
                 sx={{
                   display: 'flex',
                   justifyContent: 'center',
                   mt: 6
                 }}
              >
                 <Button
                    variant="outlined"
                    onClick={() => window.history.go(-1)}
                 >
                    Go Back
                 </Button>
              </Box>
           </Container>
        </Box>
     </BasicLayout>
  );
});
