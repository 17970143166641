import React, { memo } from 'react';
import { FormControlLabel, Paper, Radio, useMediaQuery } from '@mui/material';

import { Loader } from '@monorepo/common';
import { Step1LabelForAuth } from './Step1LabelForAuth';
import { IStep1FieldProps } from '../formInterface';
import { useGetUserInfoQuery } from '../../../../../services/serviceUserInfo';
import { getStatus } from './getStatus';
import { info } from './info';

import { useStyles } from '../../../styles';
import { Step1LabelForSimple } from './Step1LabelForSimple';
import { titleFormatter } from './titleFormatter';
import { useAppSelector } from '../../../../../store/hooks';

export const Step1Field = memo(({ value, extraPrice, currentPrice, dataCy }:IStep1FieldProps) => {
  const classes = useStyles();
  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber
  }));
  const { data, isSuccess } = useGetUserInfoQuery();
  const medium = useMediaQuery('(max-width:650px)');

  if (isSuccess && data) {
    if (!data.isLoggedIn) {
      return (
        <Paper
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            p: 2
          }}
          variant="outlined"
          className={classes.wrapper_for_radio_group}
        >
          <FormControlLabel
            {...(dataCy) && { 'data-cy': dataCy }}
            control={<Radio />}
            labelPlacement={medium ? 'start' : 'end'}
            label={(
              <Step1LabelForSimple
                title={titleFormatter({
                  value,
                  extraPrice,
                  currentPrice,
                  isSubscriber: data?.isSubscriber,
                  isCharterMember: data?.isCharterMember
                })}
                description={info[value - 1].description(siteNumber)}
              />
                    )}
            value={value}
          />
        </Paper>
      );
    }
    return (
      <Paper
        sx={{
          alignItems: 'flex-start',
          display: 'flex',
          p: 2
        }}
        variant="outlined"
        className={classes.wrapper_for_radio_group}
      >
        <FormControlLabel
          control={<Radio />}
          labelPlacement={medium ? 'start' : 'end'}
          label={(
            <Step1LabelForAuth
              title={info[value - 1].title}
              description={info[value - 1].description(siteNumber)}
              currentPrice={currentPrice}
              extraPrice={extraPrice}
              status={getStatus({
                isCharterMember: data?.isCharterMember,
                isSubscriber: data?.isSubscriber
              })}
              isCharterMember={data?.isCharterMember}
            />
                  )}
          value={value}
        />
      </Paper>
    );
  }
  return <Loader isShow />;
});
