import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import {
  FormField,
  CardWrapper,
  useIsProvince,
  useHookForm,
  GlobalInterfaces,
  StripeWrapperField,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete
} from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../Icons/arrow-right';
import { IEventsStepProps } from '../../interfaceSteps';
import { IStep7Form } from './formInterfaces';
import { schema } from './schema';
import ApplePay from './ApplePay';

import { stripeFieldOptions, useStyles } from '../../styles';
import { stripeRequestStep6 } from './stripeRequestStep6';
import { useGetPaymentIntentMutation } from '../../../../services/servicePostAJob';
import { useAppSelector } from '../../../../store/hooks';
import { showNotification } from '../../../../features/sliceNotification';

interface IStep7Props extends IEventsStepProps{
  finalPaymentRequest: (dataForm: IStep7Form, validateOnly: boolean) => void,
  validateFormData: (val: IStep7Form, validateOnly: boolean) => Promise<{ success: boolean, message: string }>,
  setStep7: (dataForm: IStep7Form) => void,
  setIsInternalLoading: (value:boolean) => void,
  defaultData: IStep7Form,
  listingType: number,
  standardPrice: number | null,
  featuredPrice: number | null
}

export const Step7 = memo(({
  Nav,
  onBack,
  setStep7,
  defaultData,
  listingType,
  finalPaymentRequest,
  validateFormData,
  setIsInternalLoading,
  standardPrice,
  featuredPrice
}:IStep7Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [changedFields, setChangedFields] = useState<IStep7Form | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [stripeError, setStripeError] = useState<GlobalInterfaces.IStripeErrorProps | null>(null);
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);
  const [clientSecretData, setClientSecretData] = useState<{ data: { clientSecret: string, error: string }; } | { error: any; } | null>(null);

  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber
  }));
  const { hostNumber } = useAppSelector(({ jobsContent }) => ({
    hostNumber: jobsContent.hostId
  }));

  const history = useHistory();
  const isUrlApproach = history.location.pathname.includes('/brewbound')
    || history.location.pathname.includes('/nosh');
  const siteId = isUrlApproach ? siteNumber.toString() : hostNumber.toString();

  const elements = useElements();
  const stripe = useStripe();
  const [getPaymentIntent] = useGetPaymentIntentMutation();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors
  } = useHookForm<IStep7Form>({
    schema,
    defaultValues: defaultData,
    showChangedFields: (a) => {
      setChangedFields(a);
    }
  });

  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(defaultData).toString() !== JSON.stringify(changedFields))) {
        setStep7(changedFields);
      }
      onBack();
    }
  };

  const { getData, isProvince } = useIsProvince({
    extraOptions: {
      setValue,
      provinceFieldName: 'billingProvince',
      stateFieldName: 'billingState'
    }
  });

  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      state: 'billingState',
      province: 'billingProvince',
      address1: 'billingAddress1',
      city: 'billingCity',
      zip: 'billingZip'
    }
  });

  const onSubmit = useCallback(async (data: IStep7Form) => {
    setIsLoading(true);

    const saveFormDataResponse = await validateFormData(data, true);
    if (!saveFormDataResponse.success) {
      dispatch(showNotification({
        show: false,
        type: 'error',
        text: saveFormDataResponse.message
      }));
      setIsLoading(false);
      return;
    }

    const resultIntent = clientSecretData ?? await getPaymentIntent({
      listingType,
      siteId,
      standardPrice,
      featuredPrice
    });

    const { data: { clientSecret, error } } = resultIntent as { data:{ clientSecret: string, error: string } };
    if (error) {
      dispatch(showNotification({
        show: false,
        type: 'error',
        text: error
      }));
      setIsLoading(false);
      return;
    }

    setClientSecretData(resultIntent);

    const paymentIntentIdResponse = paymentIntentId ?? await stripeRequestStep6({
      stripe,
      elements,
      dispatch,
      formData: data,
      clientSecret,
      setIsLoading,
      setStripeError
    });

    setPaymentIntentId(paymentIntentIdResponse);

    if (paymentIntentIdResponse !== null) {
      finalPaymentRequest({
        ...data,
        stripePaymentMethodId: paymentIntentIdResponse
      }, false);
    }

    setIsLoading(false);
  }, [countryCode, stripe, elements, finalPaymentRequest, paymentIntentId, clientSecretData]);

  // if choose country
  const changeCountries = useCallback((val:GlobalInterfaces.IAutocompleteOption | null) => {
    clearSuggestions();

    getData({
      selectOption: val
    });
    if (val && val.alpha2) {
      setValue('billingCountry', val.alpha2);
      setCountryCode(val.alpha2);
    }
  }, [getData]);

  useEffect(() => {
    if (defaultData?.billingCountry) {
      setCountryCode(defaultData.billingCountry);
    }
  }, [defaultData]);

  useEffect(() => {
    setValue('isShowBillingState', !isProvince);
  }, [isProvince]);

  useEffect(() => {
    setIsInternalLoading(isLoading);
  }, [setIsInternalLoading, isLoading]);

  return (
    <CardWrapper
      scrollOptions={{
        isErrorsAutoScrollPage: errors,
        isScrollToStart: !!stripeError
      }}
    >
      <form
        data-cy="step7"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ApplePay listingType={listingType} />

        <input
          style={{ display: 'none' }}
          type="checkbox"
          {...register('isShowBillingState')}
        />

        <StripeWrapperField
          error={stripeError}
          label="Card Number"
          name="numbers"
        >
          <CardNumberElement
            className={classes.card_99}
            options={stripeFieldOptions}
          />
        </StripeWrapperField>

        <div className={classes.step3_address_wrap}>
          <StripeWrapperField
            error={stripeError}
            label="Card Expiry"
            name="expiry"
          >
            <CardExpiryElement
              className={classes.card_99}
              options={stripeFieldOptions}
            />
          </StripeWrapperField>
          <StripeWrapperField
            error={stripeError}
            label="Card CVC"
            name="cvc"
          >
            <CardCvcElement
              className={classes.card_99}
              options={stripeFieldOptions}
            />
          </StripeWrapperField>
        </div>

        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            extraProps={{ ...register('billingFirstName') }}
            errors={errors}
            label="First Name"
            placeholder="First Name"
          />
          <FormField
            isRequired
            extraProps={{ ...register('billingLastName') }}
            errors={errors}
            label="Last Name"
            placeholder="Last Name"
          />
        </div>

        <FormField
          isRequired
          extraProps={{ ...register('billingEmail') }}
          errors={errors}
          label="Contact Email"
          placeholder="Email"
        />
        <FormField
          extraProps={{ ...register('billingCompany') }}
          errors={errors}
          label="Company"
          placeholder="Company"
        />

        <FormField
          isRequired
          label="Phone"
          errors={errors}
          placeholder="Phone"
          extraProps={{ ...register('billingPhone') }}
        />

        <CountryAutocomplete
          isRequired
          label="Country"
          errors={errors}
          disableClearable
          setValue={setValue}
          getValues={getValues}
          handleChange={changeCountries}
          extraProps={{ ...register('billingCountry') }}
        />

        <FormField
          isRequired
          errors={errors}
          label="Address"
          placeholder="Address"
          extraProps={{ ...register('billingAddress1') }}
          eventInput={(inputValue, event) => {
            changeInput(event as ChangeEvent<HTMLInputElement>);
          }}
        >
          {renderedList}
        </FormField>

        <FormField
          extraProps={{ ...register('billingAddress2') }}
          errors={errors}
          label="Address Line 2"
          placeholder="Apartment, suite, unit, building, floor, etc."

        />
        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            extraProps={{ ...register('billingCity') }}
            errors={errors}
            placeholder="City"
            label="City"
          />
          <FormField
            visible={isProvince}
            extraProps={{ ...register('billingProvince') }}
            errors={errors}
            placeholder="Province"
            label="Province"
          />
          <UsaStatesAutocomplete
            isRequired
            label="State"
            visible={!isProvince}
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            extraProps={{ ...register('billingState') }}
          />
        </div>

        <FormField
          isRequired
          extraProps={{ ...register('billingZip') }}
          errors={errors}
          label="Zip/Postal Code"
        />

        <Nav
          nextDisabled
          onBack={goBack}
          textPreviousStep="Previous step"
          nextButton={(
            <Button
              endIcon={(<ArrowRightIcon fontSize="small" />)}
              variant="contained"
              disabled={!stripe}
              type="submit"
              data-cy="complete"
            >
              Process Purchase
            </Button>
            )}
        />
      </form>
    </CardWrapper>
  );
});
