import { IPaymentStep } from './interfaces';

export const staticValuesForPaymentStep:IPaymentStep = {
  stripePaymentMethodId: '',
  billingCountry: 'US',
  billingEmail: '',
  billingAddress1: '',
  billingCity: '',
  billingProvince: '',
  billingZip: '',
  billingCompany: '',
  billingPhone: '',
  billingFirstName: '',
  billingLastName: ''
};
