import { useEffect } from 'react';
import { Hosts } from '../../constants/hostName';

interface IMetaDescription {
  title: string,
  siteNumber: number
}

interface GtagIds {
  [key: string]: string;
}

export const MetaDescription = ({ title, siteNumber }:IMetaDescription) => {
  useEffect(() => {
    // Create the script element for gtag.js
    const gtagIds: GtagIds = {
      [Hosts.BEVNET]: 'G-L0JJ20QS9D',
      [Hosts.BREWBOUND]: 'G-LV4GXHHTGK',
      [Hosts.NOSH]: 'G-98C547X4YE'
    };
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagIds[siteNumber]}&l=dataLayer${siteNumber}`;
    script.async = true;

    script.onload = () => {
      // @ts-ignore
      window[`dataLayer${siteNumber}`] = window[`dataLayer${siteNumber}`] || [];
      // @ts-ignore
      window.gtag = function gtag() {
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        window[`dataLayer${siteNumber}`].push(arguments);
      };
      // @ts-ignore
      gtag('js', new Date());
      // @ts-ignore
      gtag('config', gtagIds[siteNumber]);
    };

    // Append the script element to the document's head
    document.head.appendChild(script);

    // Cleanup the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, [siteNumber]);

  useEffect(() => {
    // Create the script element for gtag.js
    const gtmIds: GtagIds = {
      [Hosts.BEVNET]: 'GTM-PQ9VGWC7',
      [Hosts.BREWBOUND]: 'GTM-M4MRHMPX',
      [Hosts.NOSH]: 'GTM-WB8DHGMN',
    };

    const scriptToHeader = `
      <!-- Google Tag Manager -->
      <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayerGtm${siteNumber}'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayerGtm${siteNumber}','${gtmIds[siteNumber]}');</script>
      <!-- End Google Tag Manager -->`;
    const docHeader = document
      .createRange()
      .createContextualFragment(scriptToHeader);
    document.head.appendChild(docHeader);

    const scriptToBody = `
      <!-- Google Tag Manager (noscript) -->
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmIds[siteNumber]}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->`;
    const docBody = document
      .createRange()
      .createContextualFragment(scriptToBody);
    document.body.insertBefore(docBody, document.body.firstChild);

    // Cleanup the script element when the component unmounts
    return () => {
      document.head.removeChild(docHeader);
      document.body.removeChild(docBody);
    };
  }, [siteNumber]);

  if (Hosts.BREWBOUND === siteNumber) {
    return (
      <>
        <title>{title}</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="//d5skk4sh8vefq.cloudfront.net/Assets/img/favicon-16x16.png"
        />
        <meta
          name="msapplication-TileColor"
          content="#ffffff"
        />
        <meta
          name="msapplication-TileImage"
          content="//d5skk4sh8vefq.cloudfront.net/Assets/img/ms-icon-144x144.png"
        />
        <meta
          name="theme-color"
          content="#ffffff"
        />
        <meta
          name="msapplication-TileColor"
          content="#f01d4f"
        />
      </>
    );
  }
  if (Hosts.NOSH === siteNumber) {
    return (
      <>
        <title>{title}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="//d2azl42aua8mom.cloudfront.net/assets/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="//d2azl42aua8mom.cloudfront.net/assets/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="//d2azl42aua8mom.cloudfront.net/assets/favicon//favicon-16x16.png"
        />
        <meta
          name="msapplication-TileColor"
          content="#da532c"
        />
        <meta
          name="theme-color"
          content="#ffffff"
        />
        <meta
          name="msapplication-TileColor"
          content="#121212"
        />
        <meta
          name="msapplication-TileImage"
          content="//d2azl42aua8mom.cloudfront.net/assets/images/ms-icon-144x144.png"
        />
      </>
    );
  }

  return (
    <>
      <title>{title}</title>
      <meta
        property="og:title"
        content="Post a Job Listing"
      />
      <meta
        property="og:url"
        content="https://www.bevnet.com:443/jobs/postjoblisting"
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="fb:app_id"
        content="125098464183569"
      />
      <meta
        property="og:image"
        content="//d2btcxja5g5zgs.cloudfront.net/BevNET_Logo_Square.jpeg"
      />
      <link
        rel="image_src"
        href="//d2btcxja5g5zgs.cloudfront.net/BevNET_Logo_Square.jpeg"
      />

      <meta
        name="twitter:card"
        content="summary"
      />
      <meta
        name="twitter:url"
        content="https://www.bevnet.com:443/jobs/postjoblisting"
      />
      <meta
        name="twitter:site"
        content="@bevnet"
      />
      <meta
        name="twitter:title"
        content="Post a Job Listing"
      />
      <meta
        name="twitter:description"
        content=""
      />
      <meta
        name="twitter:image"
        content="//d2btcxja5g5zgs.cloudfront.net/BevNET_Logo_Square.jpeg"
      />
    </>
  );
};
