export default {
  // user
  userProfile: '/user/ContactInfo',
  userPublicProfile: '/user/PublicProfile',
  userHeadShot: '/user/PublicProfileHeadshot',
  userOrders: '/user/orders',
  userChangePassword: '/user/ChangePassword',
  // user email
  checkEmail: '/user/IsEmailChanged',
  confirmEmail: '/user/IsCodeValid',
  // countries
  countries: '/misc/GetCountries',
  usaStates: '/misc/GetStates',
  // Jobs
  jobs: {
    jobLogo: '/Jobs/JobLogo',
    packages: (id: string): string => `/Jobs/PrepaidPackages/${id}`,
    postPrepaidPurchase: (id: string, validateOnly: boolean): string => `/Jobs/PrepaidPurchase/${id}?validateOnly=${validateOnly}`,
    paymentIntentPrepaidPurchase: '/jobs/paymentintentprepaidpurchase',
    industrySectors: (id: string): string => `/Jobs/IndustrySectors/${id}`,
    employmentTypes: '/Jobs/EmploymentTypes',
    jobListingTypes: (id: string): string => `/Jobs/JobListingTypes/${id}`,
    getUserCurrentInfo: '/Jobs/GetCurrentUserInfo',
    salaryUnits: '/Jobs/SalaryUnits',
    salaryTypes: '/Jobs/SalaryTypes',
    saveJobData: '/Jobs/SaveJobData',
    postJobListing: (id: string, validateOnly: boolean): string => `/Jobs/PostJobListing/${id}?validateOnly=${validateOnly}`,
    getJobTempData: (guid: string): string => `/Jobs/PostJobTempData/${guid}`,
    сlearTempData: (guid: string): string => `/Jobs/ClearTempData/${guid}`,
    brewboundCompanyList: (text: string): string => `/Jobs/CompanyList/${text}`,
    jobData: ({ siteId, guid }: { siteId: string; guid: string }): string => `/Jobs/JobInfo/${siteId}/${guid}`,
    postprepaidlisting: ({ siteId, guid }: { siteId: string; guid: string }): string => `/Jobs/PostPrepaidListing/${siteId}/${guid}`,
    upgradeJob: (siteId: string, guid: string, validateOnly: boolean): string => `/Jobs/UpgradeJobListing/${siteId}/${guid}?validateOnly=${validateOnly}`,
    paymentIntentForUpgrade: '/jobs/paymentintentforupgrade',
    editJobListing: (id: string): string => `/Jobs/EditJobListing/${id}`,
    stopJobListing: (guid: string): string => `/Jobs/StopListing/${guid}`,
    reactivateFreeJobListing: (guid: string): string => `/Jobs/ReactivateFree/${guid}`,

    getReactivateJobTempData: (guid: string): string => `/Jobs/ReactivateJobTempData/${guid}`,
    reactivateJobListing: (id: string, validateOnly: boolean): string => `/Jobs/Reactivate/${id}?validateOnly=${validateOnly}`,
    saveTempDataReactivate: '/Jobs/SaveReactivateJobData',
    сlearTempReactivateData: (guid: string): string => `/Jobs/ClearTempReactivateData/${guid}`,

    saveTempDataJobPrepaid: '/Jobs/PostPrepaidTempData',
    getJobPrepaidTempData: (guid: string) => `/Jobs/PostPrepaidTempData/${guid}`,
    postJobPrepaid: (siteId: string) => `/Jobs/PostPrepaidListing/${siteId}`,
    clearTempData: (siteId: string) => `/Jobs/ClearPostPrepaidTempData/${siteId}`,
    getReceiptData: (siteId: string) => `/Jobs/Receipt/${siteId}`,
    getPaymentIntent: '/Jobs/PaymentIntent'
  }
};
