import React, { ReactNode } from 'react';
import { Hosts } from '../../../../../constants/hostName';

const brand = (siteNumber: Hosts) => {
  switch (siteNumber) {
    case Hosts.BREWBOUND:
      return 'Brewbound';
    case Hosts.NOSH:
      return 'NOSH';
    default:
      return 'BevNET';
  }
};

export const info: { title: string, description: ((siteNumber: Hosts) => string) | ((siteNumber: Hosts) => ReactNode) }[] = [
  {
    title: '60 DAY STANDARD LISTING',
    description: (siteNumber) => `Includes placement on the ${brand(siteNumber)} Jobs pages and in the weekly ${brand(siteNumber)} jobs email newsletter.`
  },
  {
    title: '60 DAY FEATURED LISTING',
    description: (siteNumber) => (
      <>
        Includes standard listing placement
        {' '}
        <b>PLUS</b>
        {' '}
        placement at the top of the Jobs page, in the featured jobs box (sidebar of every news article), and premium placement in the weekly
        {` ${brand(siteNumber)} `}
        jobs email newsletter.
      </>
    )
  }
];
