import React, { ChangeEvent, memo } from 'react';
import clsx from 'clsx';
// @ts-ignore
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { FieldComment } from '../FormFieldUtils';

import { useStyles } from './styles';
import { FormFieldProps } from './formFieldInterface';

const FormFieldCurrency = ({
  isRequired,
  extraProps = {},
  refProps = {},
  type = 'text',
  label,
  visible = true,
  focus,
  errors = false,
  fieldComment,
  placeholder,
  minimumValue = '0',
  classNameForField,
  classNameForWrapper,
  change,
  position = 'relative',
  currencySymbol = '$',
  customInputProps,
  children,
  valueField,
  eventInput,
  name,
  dataCy
}:FormFieldProps) => {
  const classes = useStyles();
  const changeField = (el:ChangeEvent<HTMLInputElement>) => {
    if (change) {
      change(el.target.value, el);
    }
  };
  const eventInputField = (el:ChangeEvent<HTMLInputElement>) => {
    if (eventInput) {
      eventInput(el.target.value, el);
    }
  };

  const focusField = (el:React.FormEvent<HTMLInputElement>) => {
    if (focus) {
      focus({ value: el.currentTarget.value });
    }
  };

  if (visible) {
    return (
       <div
          {...refProps}
          className={clsx(classes.field_wrapper, classNameForWrapper, {
            [classes.hidden]: type === 'hidden',
            [classes.relative]: position && position === 'relative',
            [classes.absolute]: position && position === 'absolute',
          })}
       >
          <CurrencyTextField
             minimumValue={minimumValue}
             currencySymbol={currencySymbol}
             autoComplete="no"
             outputFormat="number"
             onFocus={focusField}
             className={clsx(classes.field, classNameForField)}
             onChange={changeField}
             onInput={eventInputField}
             placeholder={placeholder}
             type={type}
             error={!!errors[extraProps.name] || (name && !!errors[name])}
             fullWidth
             label={label && (isRequired ? (`${label} *`) : label)}
             variant="outlined"
          // {...extraProps}
             helperText={(errors[extraProps.name] && errors[extraProps.name].message) || (errors[extraProps.name] && name && errors[name].message) || (name && !!errors[name] && errors[name].message)}
              // TODO remove this props  {shrink: true}
             InputLabelProps={{
               shrink: true,
             }}
             InputProps={{
               inputProps: {
                 ...(customInputProps) && customInputProps,
                 ...(dataCy) && { 'data-cy': dataCy }
               }
             }}
             value={valueField}
          />
          {children}

          <FieldComment fieldComment={fieldComment} />
       </div>
    );
  }
  return null;
};

export default memo(FormFieldCurrency);
