import React, { memo, useEffect, useState } from 'react';
import { Card, Typography } from '@mui/material';
import { cookieNames, Loader } from '@monorepo/common';
import cookies from 'js-cookie';

import { useDispatch } from 'react-redux';
import FinalBlockItem from './FinalBlockItem';
import { useClearDataQuery } from '../../../services/servicePostAJob';
import { IPaymentResponse } from '../../../services/servicePostAJob/responseInterfaces';
import { showDropButton } from '../../../features/sliceStepper';

interface IFinalBlockProps {
  data?: IPaymentResponse
}
const FinalBlock = ({ data }:IFinalBlockProps) => {
  const dispatch = useDispatch();
  const [isSkipRequest, setIsSkipRequest] = useState<boolean>(true);
  const { isLoading } = useClearDataQuery(null, {
    skip: isSkipRequest
  });

  useEffect(() => {
    if (cookies.get(cookieNames.tempJob)) {
      setIsSkipRequest(false);
    }
  }, []);

  useEffect(() => {
    dispatch(showDropButton());
    if (isLoading) setIsSkipRequest(true);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      // @ts-ignore
      window.gtag('event', 'conversion', {
        send_to: 'AW-1019972986/c3rlCITEj2wQ-pqu5gM',
        value: data.orderAmount,
        currency: 'USD',
        transaction_id: data.orderId,
      });

      cookies.remove(cookieNames.tempJob);
    }
  }, [data]);

  return (
    <>
      <Loader isShow={isLoading} />

      <Card
        sx={{
          mt: 2,
          px: 2,
          p: 2.5
        }}
        variant="outlined"
        data-cy="finalStep"
      >

        <Typography
          variant="h4"
          fontWeight="bold"
        >
          Order Complete - Thank You
        </Typography>
        <Typography
          variant="h5"
          marginTop={4}
          marginBottom={4}
        >
          Your job lising
        </Typography>

        <Typography
          variant="body1"
          marginTop={1}
          marginBottom={2}
        >
          Your job listing is now active.
        </Typography>

        <FinalBlockItem
          title="VIEW YOUR JOB LISTING"
          link={`https://www.bevnet.com/jobs/${data?.path}`}
        />

        <FinalBlockItem
          title="EDIT YOUR JOB LISTING"
          link={`https://www.bevnet.com/jobs/${data?.eguid}`}
        />

        <FinalBlockItem
          title="VIEW ORDER"
          link={`https://www.bevnet.com/orders/receipt/${data?.orderGuid}`}
        />

        <Typography
          variant="body1"
          marginTop={4}
          color="#292929"
        >
          <strong>IMPORTANT: </strong>
          {' '}
          These links -- and a receipt for your purchase -- have also been sent to you via email.
        </Typography>

      </Card>
    </>
  );
};
export default memo(FinalBlock);
