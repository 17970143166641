import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { FormAutocomplete, FormFieldCurrency, Loader, GlobalInterfaces } from '@monorepo/common';

import SalaryTitle from './SalaryTitle';
import { ISalaryProps } from './salaryInterface';
import { useAppSelector } from '../../../../../store/hooks';
import {
  useGetSalaryTypesQuery,
  useGetSalaryUnitsQuery
} from '../../../../../services/servicePostAJob';

import { useStyles } from '../../../styles';
import { convertNumber } from './convertNumber';

interface IOption extends GlobalInterfaces.IOption{
  selected?: boolean,
}

const Salary = ({ getValues, setValue, errors, register, defaultValueRadio, defaultValueExact, defaultValueMin, defaultValueMax, defaultValueSalaryUnitId }:ISalaryProps) => {
  const classes = useStyles();
  const [activeItem, setActiveItem] = useState(Number(defaultValueRadio));
  const { isHost } = useAppSelector(({ pathnameSettings }) => ({
    isHost: pathnameSettings.host
  }));
  const { data: salaryTypes, isLoading } = useGetSalaryTypesQuery(null);

  const { data: salaryUnits, isLoading: isLoadingSalary } = useGetSalaryUnitsQuery(null, {
    skip: !isHost
  });

  const handleChange = useCallback((num: number) => {
    if (num === 3) {
      setValue('salaryUnitId', null);
    }
    setValue('salaryUnitId', defaultValueSalaryUnitId);
    setActiveItem(num);
    setValue('salaryUnitRadio', num);
    setValue('salaryExact', null);
    setValue('salaryMin', null);
    setValue('salaryMax', null);
  }, [setValue]);

  useEffect(() => {
    if (salaryUnits) {
      const filtered = salaryUnits.filter((item) => !!item.selected);
      if (filtered.length === 1) {
        setValue('salaryUnitId', filtered[0].value);
      }
    }
  }, [salaryUnits, setValue]);

  useEffect(() => {
    if (activeItem === 1) {
      setValue('showSalaryExact', true);
      return;
    }
    setValue('showSalaryExact', false);
  }, [activeItem]);

  if (!isLoadingSalary) {
    return (
      <>
        <input
          style={{ display: 'none' }}
          type="checkbox"
          {...register('showSalaryExact')}
        />
        <SalaryTitle />
        <RadioGroup
          value={activeItem}
          onChange={(a, activeRadio) => {
            handleChange(Number(activeRadio));
          }}
          sx={{
            mb: 3
          }}
        >
          <Loader isShow={isLoading} />
          {salaryTypes && salaryTypes && salaryTypes.map((item:{ label: string, value: number }) => (
            <FormControlLabel
              key={item.value}
              control={<Radio />}
              label={item.label}
              value={item.value}
            />
          ))}

        </RadioGroup>

        {activeItem !== 3 && (
        <FormAutocomplete<IOption>
          optionsSettings={{
            optionValue: 'value',
            optionText: 'text'
          }}
          isRequired
          disableClearable
          extraProps={{ ...register('salaryUnitId') }}
          errors={errors}
          getValues={getValues}
          options={salaryUnits}
          label="Specify Compensation Per"
          setValue={setValue}
        />
        )}

        {activeItem === 1 && (
        <FormFieldCurrency
          isRequired
          name="salaryExact"
          change={(a) => {
            setValue('salaryExact', convertNumber(a));
          }}
          dataCy="salaryExact"
          errors={errors}
          valueField={defaultValueExact}
          label="Exact Compensation (in dollars)"
        />
        )}
        {activeItem === 2 && (
        <div className={classes.couple_line}>
          <FormFieldCurrency
            isRequired
            name="salaryMin"
            change={(a) => {
              setValue('salaryMin', convertNumber(a));
            }}
            errors={errors}
            valueField={defaultValueMin}
            fieldComment="Range (in dollars)"
            dataCy="salaryMin"
          />
          <FormFieldCurrency
            isRequired
            name="salaryMax"
            change={(a) => {
              setValue('salaryMax', convertNumber(a));
            }}
            dataCy="salaryMax"
            errors={errors}
            valueField={defaultValueMax}
          />
        </div>
        )}
      </>
    );
  }
  return (
    <Loader isShow />
  );
};

export default memo(Salary);
