import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jobsInitialState } from './jobsInitialState';
import { IJobReducer, PossibleHostType } from '../../interfaces';
import { HostNames } from '../../constants/hostName';

export const slice = createSlice({
  name: 'jobs',
  initialState: jobsInitialState,
  reducers: {
    setHost: (state:IJobReducer, action:PayloadAction<PossibleHostType>): void => {
      if (HostNames[action.payload] > 0) {
        state.host = action.payload;
        state.hostId = HostNames[action.payload].toString();
      } else {
        state.host = action.payload;
        state.hostId = '1';
      }
    },
    setTemporaryData: (state:IJobReducer, action:PayloadAction<any>): void => {
      state.temporaryData = action.payload;
    },
    clearJobsContent: (state:IJobReducer) => {
      state.host = null;
      state.hostId = '1';
      state.temporaryData = null;
    }
  },
});

export const { setHost, clearJobsContent, setTemporaryData } = slice.actions;
export default slice.reducer;
