import ReactHtmlParser from 'react-html-parser';
import { Box, Typography } from '@mui/material';
import { IStepsOneInterfaces } from '../../stepsOneInterfaces';

import { useStyles } from '../../styles';

import { PackDescription } from './PackDescription';

interface ICellForSimpleUserProps {
  item: IStepsOneInterfaces,
}

const getItemPriceText = (item: IStepsOneInterfaces) => {
  if (item.itemPrice !== item.listItemPrice) {
    return `<s>$${item.listItemPrice.toLocaleString('en-US')}</s>&nbsp;$${item.itemPrice.toLocaleString('en-US')}`;
  }

  return `$${item.itemPrice.toLocaleString('en-US')}`;
};
export const CellForSimpleUser = ({ item }:ICellForSimpleUserProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography
        paragraph
        sx={{
          fontSize: '1.25rem',
          fontWeight: 700,
          marginBottom: 1,
        }}
        className={classes.job_package_title}
      >
        {ReactHtmlParser(`${item.description}`)}
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 700,
          }}
          className={classes.job_package_price}
        >
          {ReactHtmlParser(`${getItemPriceText(item)} `)}
          <span>per pack</span>
        </Typography>
      </Typography>
      <PackDescription />
    </Box>
  );
};
