import React from 'react';
import { Typography } from '@mui/material';

import { useStyles } from '../../styles';

interface ICellForAuthFormatPriceProps {
  currentPrice: number;
  futurePrice: number;
}
export const CellForAuthFormatPrice = ({ futurePrice, currentPrice }:ICellForAuthFormatPriceProps) => {
  const classes = useStyles();
  return (
    <div className={classes.right}>
      <Typography
        variant="body2"
        color="green"
        fontWeight={700}
        lineHeight={1.2}
      >
        Special Charter Member Discount
      </Typography>

      <Typography
        variant="h5"
        fontWeight="bold"
      >
        $
        {currentPrice.toLocaleString('en-US')}
      </Typography>

      <Typography
        variant="subtitle2"
        color="textSecondary"
        lineHeight={1.2}
      >
        Originally
        {' '}
        <del>
          $
          {futurePrice.toLocaleString('en-US')}
        </del>
      </Typography>
    </div>
  );
};
