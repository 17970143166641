import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';
import { cookieNames, BasicLayout } from '@monorepo/common';

import { useParams } from 'react-router-dom';
import { useLazyClearTempDataQuery } from '../../../services/servicePostPrepaidListing';
import { dropStepper } from '../../../features/sliceStepper';
import { useAppSelector } from '../../../store/hooks';

// interface IStepperInfoPanelProps {}
export const StepperInfoPanel = () => {
  const { guid } = useParams<{ guid: string }>();
  const dispatch = useDispatch();
  const [isStartClear, setIsStartClear] = useState<boolean>(false);
  const [clearData, { isLoading, isSuccess }] = useLazyClearTempDataQuery();

  const { isShowDopButton } = useAppSelector(({ stepperOptions }) => ({
    isShowDopButton: stepperOptions.isShowDropButton
  }));

  const drop = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure that you want to start over? You will not longer be able to edit the current listing.')) {
      if (cookies.get(cookieNames.tempPrepaidData) && guid) {
        setIsStartClear(true);
        clearData({
          guid
        });
      }
    }
  }, [guid]);

  useEffect(() => {
    if (isSuccess && cookies.get(cookieNames.tempPrepaidData) && isStartClear) {
      setIsStartClear(false);
      cookies.remove(cookieNames.tempPrepaidData);
      dispatch(dropStepper({
        isDrop: true
      }));

      const timeout = setTimeout(() => {
        dispatch(dropStepper({
          isDrop: false
        }));
        clearTimeout(timeout);
      }, 3000);
    }
  }, [isSuccess, isStartClear]);

  return (
    <BasicLayout isLoading={isLoading}>
      <Typography
        variant="h3"
        marginBottom={3}
      >
        Post a Job Listing -- Redeem Prepaid Listing
      </Typography>

      {isShowDopButton && (
        <Typography
          color="red"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          sx={{ mt: 2 }}
          variant="body1"
          onClick={drop}
        >
          Clear this job listing and start over
        </Typography>
      )}
    </BasicLayout>
  );
};
