import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { scrollUp, BasicLayout } from '@monorepo/common';

import FinalBlock from './components/FinalBlock';
import DescriptionJobs from './components/DescriptionJobs';
import { MetaDescription } from '../../components/MetaDescription';

import { showNotification } from '../../features/sliceNotification';
import { usePaymentRequestMutation } from '../../services/servicePostAJob';

import { JobStepper } from '../../components/JobStepper';
import { useAppSelector } from '../../store/hooks';
import { setPageTitle } from '../../utils/setPageTitle';
import { cloudfrontImagesBaseURL } from '../../components/JobStepper/staticValues';
import { Hosts } from '../../constants/hostName';

// interface IJobsProps {}
export const PostJobListing = () => {
  const dispatch = useDispatch();
  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber
  }));

  const [titleBrowserPage, setTitleBrowserPage] = useState<string>('Jobs');
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [paymentRequest, { data, isLoading: isPaymentLoading, error: finalResponseError, isSuccess: finalResponseSuccess }] = usePaymentRequestMutation();

  const finalPaymentRequest = (formData: { body: any; id: string; validateOnly: boolean }) => {
    paymentRequest({
      body: {
        ...formData.body,
        step5: {
          ...formData.body.step5,
          uploadedLogoPath: formData.body.step5.uploadedLogoPath?.replace(cloudfrontImagesBaseURL, '')
        }
      },
      id: siteNumber.toString(),
      validateOnly: formData.validateOnly
    });
  };

  useEffect(() => {
    if (finalResponseError && 'data' in finalResponseError) {
      const resp = finalResponseError as { data: { error: string } };
      scrollUp();
      dispatch(showNotification({
        show: true,
        type: 'error',
        text: resp.data.error
      }));
    }
    if (finalResponseSuccess) {
      setIsComplete(true);
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Job was created!'
      }));
    }
  }, [finalResponseError, finalResponseSuccess]);

  useEffect(() => {
    if (isComplete) {
      const timeout = setTimeout(() => {
        setIsComplete(false);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [isComplete]);

  useEffect(() => {
    setPageTitle({
      firstTitle: 'Post a Job Listing',
      siteNumber: siteNumber || Hosts.BEVNET,
      setTitle: setTitleBrowserPage
    });
  }, [siteNumber]);

  return (
    <BasicLayout
      dataCy="postJobListing"
      helmet={MetaDescription({
        title: titleBrowserPage,
        siteNumber
      })}
    >
      <JobStepper
        isComplete={isComplete}
        leftInfoPanel={<DescriptionJobs />}
        isStepperLoading={isPaymentLoading}
        finalStepperRequest={finalPaymentRequest}
        finalComponent={<FinalBlock data={data} />}
      />
    </BasicLayout>
  );
};
