import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    ...Mixin.mixin.flex('flex-start', 'center'),
    '@media(max-width: 1200px)': {
      display: 'block'
    }
  },
  wrapper_card: {
    padding: '40px 20px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius
  },
  description_page: {
    '& h1': {
      textAlign: 'center'
    },
    '& p, & b,& li': {
      fontSize: 16
    },
    '& ul': {
      paddingLeft: '1.3rem'
    },
    '& p, & ul': {
      marginBottom: '1rem'
    },
    '& li': {
      marginBottom: '.7rem'
    },
  },
  table_wrapper: {
    maxWidth: '100%',
    overflowX: 'auto',
    ...Mixin.mixin.br()
  },
  link: {
    color: theme.palette.error.main
  },
  wrapper_for_steps: {
    width: '100%',
    maxWidth: '50rem',
    '& .MuiAvatar-root': {
      backgroundColor: '#e21836'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
    }
  },
  jobs_line_buttons: {
    marginTop: '2rem',
    ...Mixin.mixin.flex('center', 'flex-start'),
    minHeight: 30,
    '@media(max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  previous_btn: {
    '@media(max-width: 600px)': {
      marginLeft: '0 !important',
      marginTop: ' 15px !important'
    }
  },
  active_step: {
    padding: '32px 8px 32px 20px',
    borderLeft: '2px solid rgb(230, 232, 240) !important',
    marginLeft: '20px !important'
  },
  stepper: {
    '& .MuiStepConnector-line': {
      ml: 1,
      borderLeftColor: 'divider',
      borderLeftWidth: 2
    }

  },
  step3_address_wrap: {
    ...Mixin.mixin.flex('flex-start', 'flex-start'),
    gridGap: '2rem',
    marginBottom: '2rem',
    '& > div': {
      flex: 1,
      marginBottom: 0,
      '@media(max-width: 768px)': {
        marginBottom: '2rem'
      }
    },
    '@media(max-width: 768px)': {
      display: 'block'
    }
  },
  stripe_field: {
    marginBottom: '2rem',
    position: 'relative',
    borderRadius: (theme.shape.borderRadius as number) / 2,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    }
  },
  stripe_field_label: {
    position: 'absolute',
    background: '#fff',
    padding: '0 6px',
    top: '-12px',
    left: 8,
    fontWeight: 400,
    fontSize: '0.8rem',
    zIndex: 1,
    color: 'rgba(0, 0, 0, 0.6)',
    pointerEvents: 'none'
  },
  card_99: {
    padding: '0 14px',
  },
  card_with_icon: {
    '& svg': {
      opacity: '1 !important',
      transform: 'scale(1) !important'
    }
  },
  text_divider: {
    textAlign: 'center',
    ...Mixin.mixin.flex('center', 'center'),
    color: 'rgba(0, 0, 0, 0.6)',
    margin: '2rem 0 2.2rem !important',
    gridGap: '2rem',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      height: 1,
      flex: 1,
      background: 'rgba(0, 0, 0, 0.2)',
    }
  },

  left: {
    order: 0
  },
  right: {
    order: 1,
    textAlign: 'center'
  },
  line: {
    ...Mixin.mixin.flex(),
    marginBottom: '0.5rem',
    '& $left': {
      paddingRight: 10,
      width: 'calc(100% - 8rem)'
    },
    '& $right': {
      width: '100%',
      maxWidth: '8rem'
    },
  },
  job_package_title: {
    ...Mixin.mixin.flex('center', 'flex-start'),
    gap: 8,
    '@media(max-width: 992px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  job_package_price: {
    ...Mixin.mixin.flex('center', 'center'),
    gap: 6,
    padding: '0.25rem 0.5rem',
    borderRadius: (theme.shape.borderRadius as number),
    color: '#184069',
    backgroundColor: '#DBEEFF',
    '& span': {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
    }
  },
  job_package_description: {
    color: theme.palette.text.secondary,
  }
}));

export const stripeFieldOptions = {
  showIcon: true,
  style: {
    iconStyle: 'solid',
    base: {
      padding: '15px',
      lineHeight: '56px',
    },
  }
};
