import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import cookies from 'js-cookie';
import { baseUrls, GlobalInterfaces, cookieNames } from '@monorepo/common';
import { showNotification } from '../features/sliceNotification';

const token: string | null = cookies.get(cookieNames.bevnet_token) || null;

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrls.jobApi,
  ...(!!token) && {
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    }
  }
});

interface IOldErrorModel {
  data?: {
    error?: string
  },
  status?: number
}
const errorStatus: number[] = [400, 401, 403];

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error && (!errorStatus.includes(result.error.status as number))) {
    if ('data' in result.error) {
      const err = result.error as IOldErrorModel;
      if (err.data?.error) {
        api.dispatch(showNotification({
          type: 'error',
          show: true,
          text: err.data?.error
        }));
      }
    }
  }

  if (result.error && result.error.status === 400) {
    const err = result.error as GlobalInterfaces.IErrorProps;
    let errText: string = '';
    // @ts-ignore
    Object.keys(err.data.errors).forEach((item) => {
      // @ts-ignore
      errText += `${err.data.errors[item]} \n`;
    });

    api.dispatch(showNotification({
      type: 'error',
      show: true,
      text: errText
    }));
  }

  if (result?.error && ('originalStatus' in result.error)) {
    const err = result.error as GlobalInterfaces.IErrorQueryProps;
    if (err?.originalStatus === 500) {
      api.dispatch(showNotification({
        show: true,
        type: 'error',
        text: 'Internal server Error!'
      }));
    }
    if (err?.originalStatus === 400) {
      api.dispatch(showNotification({
        type: 'error',
        show: true,
        text: err.data
      }));
    }
  }

  return result;
};
