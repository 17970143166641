import React, { memo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import { FormField } from '@monorepo/common';

import { useStyles } from '../../../styles';

interface IAdditionalEmailProps {
  register: any,
  errors: any,
  generalFieldsLength: number,
  nameField: string,
  id: number,
  decrease: (id: number) => void
}
const AdditionalEmail = ({ nameField, register, errors, id, decrease, generalFieldsLength }:IAdditionalEmailProps) => {
  const classes = useStyles();

  return (
    <div
      key={id}
      className={classes.wrapper_for_additional_email}
    >
      <FormField
        isRequired
        placeholder="Email"
        originalName="email"
        classNameForWrapper={classes.additional_email}
        extraProps={{ ...register(nameField) }}
        errors={errors}
      />
      {generalFieldsLength > 1 && (
      <ClearIcon
        className={classes.additional_email_remove}
        onClick={() => {
          decrease(id);
        }}
      />
      )}
    </div>
  );
};

export default memo(AdditionalEmail);
