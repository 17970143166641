import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';
import { IChildrenProps, IStripeErrorProps } from '../../interfaces';
import Loader from '../Loader';
import { useStyles } from './styles';
import { stipeCardErrorsName } from '../../constants/stipeCardErrorsName';

interface IStripeWrapperFieldProps extends IChildrenProps {
  label?: string;
  name: 'numbers' | 'expiry' | 'cvc';
  error?: IStripeErrorProps | null;
  dataCy?: string;
}
export const StripeWrapperField = memo(({ error, name, label, children, dataCy }:IStripeWrapperFieldProps) => {
  const classes = useStyles();
  const [errText, setErrText] = useState<string | null | undefined>(null);
  useEffect(() => {
    if (error?.code && stipeCardErrorsName[name].includes(error.code)) {
      setErrText(error.message);
      return;
    }
    setErrText(null);
  }, [error, name]);

  if (children) {
    return (
       <div
          className={clsx(classes.stripe_field, {
            [classes.error]: error?.code && stipeCardErrorsName[name].includes(error.code)
          })}
          {...(dataCy) && { 'data-cy': dataCy }}
       >
          {label && (
          <span className={classes.stripe_field_label}>
             {label}
             <span style={{ color: 'red' }}> *</span>
          </span>
          )}
          {children}
          {errText && <span className={classes.error_text}>{errText}</span>}
       </div>
    );
  }
  return <Loader isShow />;
});
