import React, { memo, ReactNode } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useStyles } from '../../../styles';
import { Step1LabelPriceFormat } from './Step1LabelPriceFormat';

interface IStep1LabelProps {
  extraPrice: number;
  currentPrice: number;
  title: string | ReactNode;
  description: string | ReactNode;
  status: string | null;
  isCharterMember: boolean | null;
}
export const Step1LabelForAuth = memo(({ title, description, extraPrice, currentPrice, status, isCharterMember }:IStep1LabelProps) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const medium = useMediaQuery('(max-width:650px)');
  return (
    <div className={classes.line}>
      <Step1LabelPriceFormat
        title={title}
        status={status}
        extraPrice={extraPrice}
        currentPrice={currentPrice}
        isCharterMember={isCharterMember}
      />
      <div className={classes.left}>
        <Typography
          color="black"
          variant="body2"
          fontWeight={800}
          marginBottom={1}
          hidden={medium}
        >
          {title}
          {' '}
          {extraPrice > currentPrice && status}
        </Typography>

        <Typography
          color="textSecondary"
          variant="subtitle2"
        >
          {description}
        </Typography>
      </div>

    </div>
  );
});
