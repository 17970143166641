import { cookieNames, useStepper } from '@monorepo/common';
import cookies from 'js-cookie';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  staticValuesForStep1,
  staticValuesForStep7
} from '../../../components/JobStepper/staticValues';
import { Step1 } from '../../../components/JobStepper/steps/Step1';
import { IStep1Form } from '../../../components/JobStepper/steps/Step1/formInterface';
import { Step7 } from '../../../components/JobStepper/steps/Step7';
import { IStep7Form } from '../../../components/JobStepper/steps/Step7/formInterfaces';
import { showNotification } from '../../../features/sliceNotification';
import { showDropButton } from '../../../features/sliceStepper';
import { useAppSelector } from '../../../store/hooks';
import {
  useGetReactivateJobTempDataQuery,
  useSaveReactivateStepsDataMutation,
  useReactivateJobMutation,
} from '../../../services/serviceJobData';
import { JobListingTypesResponse } from '../../../services/servicePostAJob/responseInterfaces';

interface IJobStepperProps {
  guid: string;
  isComplete?: boolean;
  leftInfoPanel?: ReactNode;
  finalComponent?: ReactNode;
  isStepperLoading?: boolean;
  finalStepperRequest: (val: { body: any; id: string }) => void
}

export const StepperPanel = ({ guid, isComplete, isStepperLoading, finalStepperRequest, leftInfoPanel, finalComponent }:IJobStepperProps) => {
  const dispatch = useDispatch();
  const [isGetTempData, setIsGetTempData] = useState(true);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [stepperIsDropped, setStepperIsDropped] = useState<boolean>(false);

  const { hostNumber, isStepperDrop } = useAppSelector(({ jobsContent, stepperOptions }) => ({
    hostNumber: jobsContent.hostId,
    isStepperDrop: stepperOptions.isDrop
  }));

  const [saveStepsData, { data: savedData, error: errSavedData }] = useSaveReactivateStepsDataMutation();
  const [reactivateRequest] = useReactivateJobMutation();
  const [isFirstLoadPage, setIsFirstLoadPage] = useState(true);

  const [isInternalLoading, setIsInternalLoading] = useState<boolean>(false);

  const { data: getSavedTempData, isLoading: getTempDataLoading, } = useGetReactivateJobTempDataQuery(cookies.get(cookieNames.tempReactivateJob) || '', {
    skip: isGetTempData
  });

  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons, dropStepper } = useStepper({
    orientation: 'vertical',
    leftInfoPanel: leftInfoPanel || (<></>),
    finalComponent: finalComponent || (<></>),
    isLoading: getTempDataLoading || isStepperDrop || isInternalLoading || isStepperLoading
  });

  //  steps data
  const [step1, setStep1] = useState<IStep1Form>(staticValuesForStep1);
  const [step2, setStep2] = useState<IStep7Form>(staticValuesForStep7);

  const [jobListingTypes, setJobListingTypes] = useState<JobListingTypesResponse>(null);

  const finalPaymentRequest = (val: IStep7Form) => {
    finalStepperRequest({
      id: hostNumber,
      body: {
        guid,
        step1,
        step2: val
      }
    });
  };

  const validateFormData = async (val: IStep7Form, validateOnly: boolean): Promise<{ success: boolean, message: string }> => {
    await reactivateRequest({
      id: hostNumber,
      validateOnly,
      body: {
        guid,
        step1,
        step2: val,
      }
    });
    return { success: true, message: '' };
  };

  const steps = useMemo(() => [
    {
      label: 'Listing Type',
      content: (
        <Step1
          setIsInternalLoading={setIsInternalLoading}
          setIsRequest={setIsRequest}
          setJobListing={setStep1}
          Nav={StepsButtons}
          defaultData={step1}
          onNext={onNext}
          setJobListingTypes={setJobListingTypes}
        />
      )
    },
    {
      label: 'Payment Information',
      content: (
        <Step7
          onBack={onBack}
          Nav={StepsButtons}
          defaultData={step2}
          onNext={onComplete}
          setStep7={setStep2}
          listingType={step1.listingType}
          finalPaymentRequest={finalPaymentRequest}
          setIsInternalLoading={setIsInternalLoading}
          validateFormData={validateFormData}
          standardPrice={jobListingTypes?.standardPrice}
          featuredPrice={jobListingTypes?.featuredPrice}
        />
      )
    }
  ], [StepsButtons, step1, step2]);

  useEffect(() => {
    dispatch(showDropButton());
    putSteps(steps);
    if (!isFirstLoadPage && isRequest) {
      saveStepsData({
        guid,
        step1,
        step2,
      });
    }
  }, [step1, step2, isRequest]);

  useEffect(() => {
    if (isFirstLoadPage && !cookies.get(cookieNames.tempReactivateJob)) {
      setIsFirstLoadPage(false);
    }
    if (isFirstLoadPage && cookies.get(cookieNames.tempReactivateJob)) {
      setIsGetTempData(false);
    }
  }, []);

  useEffect(() => {
    if (savedData && savedData.guid && !cookies.get(cookieNames.tempReactivateJob)) {
      cookies.set(cookieNames.tempReactivateJob, savedData.guid);
      dispatch(showDropButton());
    }
  }, [savedData, errSavedData]);

  useEffect(() => {
    if (isStepperDrop === false && cookies.get(cookieNames.tempReactivateJob)) {
      if (isFirstLoadPage && getSavedTempData) {
        setStep1(getSavedTempData.step1);
        setIsFirstLoadPage(false);
      }
    }
  }, [getSavedTempData, isStepperDrop]);

  useEffect(() => {
    if (isComplete && !stepperIsDropped) {
      setStepperIsDropped(true);
      onComplete();
      const timeout = setTimeout(() => {
        setStepperIsDropped(false);
        clearTimeout(timeout);
      }, 3000);
    }
  }, [isComplete]);

  useEffect(() => {
    dispatch(showDropButton());
    if (isStepperDrop) {
      setIsRequest(false);
      setStep1(staticValuesForStep1);
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Form cleared'
      }));
      dropStepper();
    }
  }, [isStepperDrop]);

  return renderStepper;
};
