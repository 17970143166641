import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  FormField,
  useIsProvince,
  GlobalInterfaces,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete
} from '@monorepo/common';

import { Button } from '@mui/material';
import { useStyles } from '../../../styles';

interface ILocationProps {
  id: number;
  errors: any;
  pZip: string;
  setValue: any;
  pCity: string;
  getValues: any;
  pState: string;
  pCountry: string;
  pProvince: string;
  pAddress1: string;
  pAddress2: string;
  fieldLength: number
  pIsShowState: string;
  decrease: (id: number) => void;
  register: any;
}
const Location = ({
  id,
  pZip,
  pCity,
  pState,
  errors,
  register,
  decrease,
  pCountry,
  setValue,
  pProvince,
  getValues,
  pAddress1,
  pAddress2,
  fieldLength,
  pIsShowState
}:ILocationProps) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const { getData, isProvince } = useIsProvince({
    extraOptions: {
      setValue,
      provinceFieldName: pProvince,
      stateFieldName: pState
    }
  });
  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      country: pCountry && pCountry,
      state: pState && pState,
      province: pProvince && pProvince,
      address1: pAddress1 && pAddress1,
      city: pCity && pCity,
      zip: pZip && pZip
    }
  });

  // if choose country
  const changeCountries = useCallback((val:GlobalInterfaces.IAutocompleteOption | null) => {
    clearSuggestions();

    getData({
      selectOption: val
    });
    setCountryCode(val?.alpha2 || null);
  }, [getData]);

  // toggle isProvince
  useEffect(() => {
    setValue(pIsShowState, !isProvince);
  }, [isProvince]);

  useEffect(() => {
    getData({
      countryName: getValues({ ...register(pCountry) }.name)
    });
  }, [pCountry, getData]);

  return (
    <div className={classes.location_wrapper}>
      <input
        style={{ display: 'none' }}
        type="checkbox"
        {...register(pIsShowState)}
      />
      <CountryAutocomplete
        getValues={getValues}
        label="Country"
        isRequired
        disableClearable
        extraProps={{ ...register(pCountry) }}
        errors={errors}
        handleChange={changeCountries}
        setValue={setValue}
      />

      <FormField
        extraProps={{ ...register(pAddress1) }}
        errors={errors}
        label="Street Address (Optional)"
        eventInput={(inputValue, event) => {
          changeInput(event as ChangeEvent<HTMLInputElement>);
        }}
      >
        {renderedList}
      </FormField>

      <FormField
        extraProps={{ ...register(pAddress2) }}
        errors={errors}
        label="Street Address Line 2 (Optional)"
      />

      <div className={classes.step3_address_wrap}>
        <FormField
          isRequired
          originalName="city"
          errors={errors}
          extraProps={register(pCity)}
          label="City"
          placeholder="City"
        />
        <FormField
          visible={isProvince}
          extraProps={register(pProvince)}
          errors={({ ...errors })}
          originalName="province"
          placeholder="Province"
          label="Province"
        />
        <UsaStatesAutocomplete
          isRequired
          setValue={setValue}
          label="State"
          originalName="state"
          visible={!isProvince}
          getValues={getValues}
          errors={errors}
          extraProps={{ ...register(pState) }}
        />
      </div>

      <FormField
        extraProps={register(pZip)}
        errors={errors}
        label="Zip/Postal Code"
        classNameForWrapper={classes.last_field}
      />
      {fieldLength > 1 && (
        <Button
          color="error"
          onClick={() => {
            decrease(id);
          }}
        >
          Delete address
        </Button>
      )}
    </div>
  );
};

export default Location;
