import * as yup from 'yup';

export const schema:any = yup.object().shape({
  jobTitle: yup.string().max(120, 'You have 120 characters remaining').nullable().required('The Job Title field is required.'),
  companyName: yup.string().max(50, 'You have 120 characters remaining').nullable().required('The Company Name field is required.'),

  industrySectorId: yup.number().moreThan(0, 'The Job Category field is required.'),
  employmentTypeId: yup.number().moreThan(0, 'The Employment Type field is required.')

});
