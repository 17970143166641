import React, { memo, useEffect } from 'react';
import { Button } from '@mui/material';

import { FormField, CardWrapper, useHookForm } from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IEventsStepProps, IStep2 } from '../../interfaceSteps';
import { schema } from './schema';

interface IStep2Props extends IEventsStepProps{
  setStep2: any,
  Nav: React.ElementType,
  step2: null | IStep2
}

export const Step2 = memo(({ onNext, onBack, setStep2, step2, Nav }:IStep2Props) => {
  const {
    watch,
    register,
    handleSubmit,
    errors,
  } = useHookForm<IStep2>({
    schema,
    ...(step2) && {
      data: step2,
      defaultValues: step2
    },

  });

  const onSubmit = (data: IStep2) => {
    if (data && onNext) {
      setStep2(data);
      onNext();
    }
  };

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        setStep2({
          ...value
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setStep2, step2]);

  return (
    <CardWrapper
      scrollOptions={{
        isErrorsAutoScrollPage: errors,
      }}
    >
      <form
        data-cy="step2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          isRequired
          errors={errors}
          label="Company Name"
          extraProps={{ ...register('companyName') }}
        />
        <FormField
          isRequired
          errors={errors}
          label="Your First Name"
          extraProps={{ ...register('yourFirstName') }}
        />
        <FormField
          isRequired
          errors={errors}
          label="Your Last Name"
          extraProps={{ ...register('yourLastName') }}
        />
        <FormField
          isRequired
          errors={errors}
          label="Your Email"
          extraProps={{ ...register('yourEmail') }}
        />

        <Nav
          nextDisabled={false}
          onBack={onBack}
          nextButton={(
            <Button
              endIcon={(<ArrowRightIcon fontSize="small" />)}
              variant="contained"
              data-cy="nextStep"
              type="submit"
            >
              Continue To Payment
            </Button>
          )}
        />
      </form>

    </CardWrapper>
  );
});
