import React, { useEffect } from 'react';
import { CardWrapper } from '@monorepo/common';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ReceiptLink } from './components/ReceiptLink';
import { useLazyGetReceiptDataQuery } from '../../services/servicePostPrepaidListing';
import { book } from '../../routers/books';

// interface IReceiptProps {}
export const Receipt = () => {
  const [getReceiptData, { data, isLoading }] = useLazyGetReceiptDataQuery();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const eguid = query.get('eguid');

  useEffect(() => {
    if (eguid) {
      getReceiptData({
        eguid
      });
    }
  }, [eguid]);

  if (!eguid || !data) {
    return null;
  }

  return (
    <CardWrapper
      dataCy="final_step_for_page_postJobListing"
      isLoading={isLoading}
    >
      <Typography
        variant="h4"
        marginBottom={4}
      >
        Order Complete - Thank You
      </Typography>
      <Typography
        variant="h5"
        marginBottom={3}
      >
        Your Job Listing
      </Typography>

      <Typography
        variant="body1"
        marginBottom={3}
      >
        Your job listing is now active.
      </Typography>

      <ReceiptLink
        textLink={`https://www.bevnet.com/jobs/${data.path}`}
        link={`https://www.bevnet.com/jobs/${data.path}`}
        title="VIEW YOUR JOB LISTING"
      />

      <ReceiptLink
        isLocal
        textLink={`https://www.bevnet.com${book.editJob}?path=${eguid}`}
        link={`${book.editJob}?path=${eguid}`}
        title="EDIT YOUR JOB LISTING"
      />

      <Typography
        variant="subtitle1"
        color="#666"
        marginTop={5}
      >
        <b>
          IMPORTANT:
        </b>
        {' '}
        These links -- and a receipt for your purchase -- have also been sent to you via email.
      </Typography>
    </CardWrapper>
  );
};
