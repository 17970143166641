import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';

import { cookieNames, useStepper } from '@monorepo/common';

import { Step1 } from './steps/Step1';
import { Step2 } from './steps/Step2';
import { Step3 } from './steps/Step3';
import { Step4 } from './steps/Step4';
import { Step5 } from './steps/Step5';
import { Step6 } from './steps/Step6';
import { Step7 } from './steps/Step7';

import { IStep1Form } from './steps/Step1/formInterface';
import { IStep2Form } from './steps/Step2/formInterface';
import { IStep3Form } from './steps/Step3/formInterfaces';
import { IStep4Form } from './steps/Step4/formInterface';
import { IStep5Form } from './steps/Step5/formInterface';
import { IStep6Form } from './steps/Step6/formInterfaces';
import { IStep7Form } from './steps/Step7/formInterfaces';

import { showNotification } from '../../features/sliceNotification';
import { useAppSelector } from '../../store/hooks';
import {
  useGetJobTempDataQuery,
  useSaveStepsDataMutation,
  usePaymentRequestMutation
} from '../../services/servicePostAJob';
import {
  staticValuesForStep1,
  staticValuesForStep2,
  staticValueForStep3,
  staticValuesForStep4,
  staticValuesForStep5,
  staticValuesForStep6,
  staticValuesForStep7
} from './staticValues';

import { showDropButton } from '../../features/sliceStepper';
import { JobListingTypesResponse } from '../../services/servicePostAJob/responseInterfaces';

interface IJobStepperProps {
  isComplete?: boolean;
  leftInfoPanel?: ReactNode;
  finalComponent?: ReactNode;
  isStepperLoading?: boolean;
  finalStepperRequest: (val: { body: any; id: string; validateOnly: boolean }) => void
}

export const JobStepper = ({ isComplete, isStepperLoading, finalStepperRequest, leftInfoPanel, finalComponent }:IJobStepperProps) => {
  const dispatch = useDispatch();
  const [isGetTempData, setIsGetTempData] = useState(true);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [stepperIsDropped, setStepperIsDropped] = useState<boolean>(false);

  const { hostNumber, isStepperDrop } = useAppSelector(({ jobsContent, stepperOptions }) => ({
    hostNumber: jobsContent.hostId,
    isStepperDrop: stepperOptions.isDrop
  }));

  const [saveStepsData, { data: savedData, error: errSavedData }] = useSaveStepsDataMutation();
  const [paymentRequest] = usePaymentRequestMutation();
  const [isFirstLoadPage, setIsFirstLoadPage] = useState(true);

  const [isInternalLoading, setIsInternalLoading] = useState<boolean>(false);

  const { data: getSavedTempData, isLoading: getTempDataLoading, } = useGetJobTempDataQuery(cookies.get(cookieNames.tempJob) || '', {
    skip: isGetTempData
  });

  const { putSteps, onNext, onBack, onComplete, renderStepper, StepsButtons, dropStepper } = useStepper({
    orientation: 'vertical',
    leftInfoPanel: leftInfoPanel || (<></>),
    finalComponent: finalComponent || (<></>),
    isLoading: getTempDataLoading || isStepperDrop || isInternalLoading || isStepperLoading
  });

  //  steps data
  const [step1, setStep1] = useState<IStep1Form>(staticValuesForStep1);
  const [step2, setStep2] = useState<IStep2Form>(staticValuesForStep2);
  const [step3, setStep3] = useState<IStep3Form>(staticValueForStep3);
  const [step4, setStep4] = useState<IStep4Form>(staticValuesForStep4);
  const [step5, setStep5] = useState<IStep5Form>(staticValuesForStep5);
  const [step6, setStep6] = useState<IStep6Form>(staticValuesForStep6);
  const [step7, setStep7] = useState<IStep7Form>(staticValuesForStep7);

  const [jobListingTypes, setJobListingTypes] = useState<JobListingTypesResponse>(null);

  const finalPaymentRequest = (val: IStep7Form, validateOnly: boolean) => {
    finalStepperRequest({
      id: hostNumber,
      validateOnly,
      body: {
        step1,
        step2,
        step3,
        step4,
        step5,
        step6,
        step7: val,
      }
    });
  };

  const validateFormData = async (val: IStep7Form, validateOnly: boolean): Promise<{ success: boolean, message: string }> => {
    const saveDataResponse = await paymentRequest({
      id: hostNumber,
      validateOnly,
      body: {
        step1,
        step2,
        step3,
        step4,
        step5,
        step6,
        step7: val
      }
    });

    if (saveDataResponse && 'error' in saveDataResponse) {
      const respErorr = saveDataResponse as {
        error: { data: { errors: {} } }
      };
      let errorsString = '';
      Object.values(respErorr.error.data.errors).forEach((value) => {
        const valueErray = value as [];

        valueErray.forEach((element) => {
          errorsString += `${element}\n`;
        });
      });

      return { success: false, message: errorsString };
    }

    return { success: true, message: '' };
  };

  const steps = useMemo(() => [
    {
      label: 'Listing Type',
      content: (
        <Step1
          setIsInternalLoading={setIsInternalLoading}
          setIsRequest={setIsRequest}
          setJobListing={setStep1}
          Nav={StepsButtons}
          defaultData={step1}
          onNext={onNext}
          setJobListingTypes={setJobListingTypes}
        />
      )
    },
    {
      label: 'Basic Job Details',
      content: (
        <Step2
          Nav={StepsButtons}
          defaultData={step2}
          setStep2={setStep2}
          onBack={onBack}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Compensation & Location',
      content: (
        <Step3
          Nav={StepsButtons}
          defaultData={step3}
          setStep3={setStep3}
          onBack={onBack}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Job Description',
      content: (
        <Step4
          Nav={StepsButtons}
          defaultData={step4}
          setStep4={setStep4}
          onBack={onBack}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Upload Logo',
      content: (
        <Step5
          Nav={StepsButtons}
          defaultData={step5}
          setStep5={setStep5}
          onBack={onBack}
          onNext={onNext}
        />
      )
    },
    {
      label: 'Contact and Application Details',
      content: (
        <Step6
          onNext={onNext}
          onBack={onBack}
          Nav={StepsButtons}
          defaultValue={step6}
          setStep6={setStep6}
        />
      )
    },
    {
      label: 'Payment Information',
      content: (
        <Step7
          onBack={onBack}
          Nav={StepsButtons}
          defaultData={step7}
          onNext={onComplete}
          setStep7={setStep7}
          listingType={step1.listingType}
          validateFormData={validateFormData}
          finalPaymentRequest={finalPaymentRequest}
          setIsInternalLoading={setIsInternalLoading}
          standardPrice={jobListingTypes?.standardPrice}
          featuredPrice={jobListingTypes?.featuredPrice}
        />
      )
    }
  ], [StepsButtons, step1, step2, step3, step4, step5, step6, step7]);

  useEffect(() => {
    dispatch(showDropButton());
    putSteps(steps);
    if (!isFirstLoadPage && isRequest) {
      saveStepsData({
        step1,
        step2,
        step3,
        step4,
        step5,
        step6,
        step7,
      });
    }
  }, [step1, step2, step3, step4, step5, step6, step7, isRequest]);

  useEffect(() => {
    if (isFirstLoadPage && !cookies.get(cookieNames.tempJob)) {
      setIsFirstLoadPage(false);
    }
    if (isFirstLoadPage && cookies.get(cookieNames.tempJob)) {
      setIsGetTempData(false);
    }
  }, []);
  //
  useEffect(() => {
    if (savedData && savedData.guid && !cookies.get(cookieNames.tempJob)) {
      cookies.set(cookieNames.tempJob, savedData.guid);
      dispatch(showDropButton());
    }
  }, [savedData, errSavedData]);

  useEffect(() => {
    if (isStepperDrop === false && cookies.get(cookieNames.tempJob)) {
      if (isFirstLoadPage && getSavedTempData) {
        setStep1(getSavedTempData.step1);
        setStep2(getSavedTempData.step2);
        setStep3(getSavedTempData.step3);
        setStep4(getSavedTempData.step4);
        setStep5(getSavedTempData.step5);
        setStep6(getSavedTempData.step6);
        setIsFirstLoadPage(false);
      }
    }
  }, [getSavedTempData, isStepperDrop]);

  useEffect(() => {
    if (isComplete && !stepperIsDropped) {
      setStepperIsDropped(true);
      onComplete();
      const timeout = setTimeout(() => {
        setStepperIsDropped(false);
        clearTimeout(timeout);
      }, 3000);
    }
  }, [isComplete]);

  useEffect(() => {
    dispatch(showDropButton());
    if (isStepperDrop) {
      setIsRequest(false);
      setStep1(staticValuesForStep1);
      setStep2(staticValuesForStep2);
      setStep3(staticValueForStep3);
      setStep4(staticValuesForStep4);
      setStep6(staticValuesForStep6);
      setStep5(staticValuesForStep5);
      dispatch(showNotification({
        show: true,
        type: 'info',
        text: 'Form cleared'
      }));
      dropStepper();
    }
  }, [isStepperDrop]);

  return renderStepper;
};
