import { useEffect } from 'react';
import cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom';

import { BasicLayout, cookieNames } from '@monorepo/common';

import {
  useLazyClearTempDataQuery,
  usePostPrepaidListingMutation,
  useSaveTempDataMutation
} from '../../services/servicePostPrepaidListing';

import { StepperPanel } from './stepper/StepperPanel';
import {
  staticValuesForStep1,
  staticValuesForStep2,
  staticValuesForStep3,
  staticValuesForStep4,
  staticValuesForStep5
} from './stepper/staticValues';
import { useAppSelector } from '../../store/hooks';
import { AllSteps } from './stepper/interfaceSteps';
import { book } from '../../routers/books';
import { cloudfrontImagesBaseURL } from '../../components/JobStepper/staticValues';

// interface IEditJobProps {}

export const PostPrepaidListing = () => {
  const { push } = useHistory();
  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber.toString()
  }));
  const { guid } = useParams<{ guid: string }>();

  const [saveTempData, { data: tempData, isLoading: isSavingTempData }] = useSaveTempDataMutation();
  const [postPrepaidListing, { isLoading: isSendingData, isSuccess, data }] = usePostPrepaidListingMutation();
  const [clearData, { isLoading: isClearing, isSuccess: isClearedData }] = useLazyClearTempDataQuery();

  const finalRequest = (formData: AllSteps) => {
    postPrepaidListing({
      siteId,
      body: {
        ...formData,
        step4: {
          ...formData.step4,
          uploadedLogoPath: formData.step4.uploadedLogoPath?.replace(cloudfrontImagesBaseURL, '')
        }
      },
      guid
    });
  };

  useEffect(() => {
    if (!cookies.get(cookieNames.tempPrepaidData)) {
      saveTempData({
        body: {
          step1: staticValuesForStep1,
          step2: staticValuesForStep2,
          step3: staticValuesForStep3,
          step4: staticValuesForStep4,
          step5: staticValuesForStep5,
        }
      });
    }
  }, []);

  useEffect(() => {
    if (tempData) {
      cookies.set(cookieNames.tempPrepaidData, tempData.guid);
    }
  }, [tempData]);

  useEffect(() => {
    if (isSuccess && cookies.get(cookieNames.tempPrepaidData)) {
      clearData({
        guid: cookies.get(cookieNames.tempPrepaidData)
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isClearedData) {
      cookies.remove(cookieNames.tempPrepaidData);
      if (data) {
        push(`${book.receipt}?eguid=${data.guid}`);
      }
    }
  }, [isClearedData, data]);

  return (
    <BasicLayout
      dataCy="postPrepaidListing"
      isLoading={isSavingTempData || isSendingData || isClearing}
      hiddenContentWhenIsLoading={isSavingTempData}
      helmet="Post a Job Listing | BevNET.com"
    >
      <StepperPanel
        finalRequest={finalRequest}
        isComplete={isClearedData}
      />
    </BasicLayout>
  );
};
