import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import cookies from 'js-cookie';

import { Btn, CardWrapper, cookieNames } from '@monorepo/common';

import moment from 'moment';
import { useSaveStepsDataMutation } from '../../../services/servicePostAJob';
import { StatusButtonOptions } from '../components/StatusButtonOptions';
import { formattedLocations } from '../utils/formattedlocation';
import { showDropButton } from '../../../features/sliceStepper';
import { staticValuesForPaymentStep } from '../staticValues';
import { CopyOptions } from '../components/CopyOptions';
import { FirstLine } from '../components/FirstLine';
import { book } from '../../../routers/books';
import { IEditJobData } from '../interfaces';
import { useAppSelector } from '../../../store/hooks';
import { Hosts } from '../../../constants/hostName';
import { useStyles } from '../styles';
import { useReactivateFreeJobMutation, useStopJobMutation } from '../../../services/serviceJobData';
import { showNotification } from '../../../features/sliceNotification';

interface IStepperInfoPanelProps {
  guid: string;
  data: IEditJobData;
}
export const StepperInfoPanel = ({ guid, data }: IStepperInfoPanelProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { push } = useHistory();
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);
  const [saveStepsData, { data: savedData, isSuccess, isError }] = useSaveStepsDataMutation();
  const [reactivateFreeJob, { isSuccess: reactivateSuccess }] = useReactivateFreeJobMutation();
  const [stopJob, { isSuccess: stopSuccess }] = useStopJobMutation();

  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber
  }));

  const siteRedirectUpgrade = useCallback(() => {
    if (Hosts.BREWBOUND === siteId) {
      return book.upgradeJobBrewbound(guid);
    }
    if (Hosts.NOSH === siteId) {
      return book.upgradeJobNosh(guid);
    }
    return book.upgradeJob(guid);
  }, [siteId]);

  const siteCopyRedirect = useCallback(() => {
    if (Hosts.BREWBOUND === siteId) {
      return book.postAJobBrewbound;
    }
    if (Hosts.NOSH === siteId) {
      return book.postAJobNosh;
    }
    return book.postAJob;
  }, [siteId]);

  const copyJob = useCallback(() => {
    if (data) {
      const applicationEmailsData:{ email: string }[] = [];

      if (data.stepper.step5.applicationEmail.length > 0) {
        data.stepper.step5.applicationEmail.split(' ').forEach((item) => {
          applicationEmailsData.push({
            email: item.replaceAll(';', '')
          });
        });
      }
      setIsLocalLoading(true);
      cookies.remove(cookieNames.tempJob);
      saveStepsData({
        step1: {
          listingType: 1,
        },
        step2: data.stepper.step1,
        step3: {
          ...data.stepper.step2,
          locations: formattedLocations(data.stepper.step2.locations)
        },
        step4: data.stepper.step3,
        step5: data.stepper.step4,
        step6: {
          ...data.stepper.step5,
          ...(!data.stepper.step5.tempAdditionalEmails) && {
            tempAdditionalEmails: [{ email: null }]
          },
          ...(data.stepper.step5.applicationEmail.length) && {
            tempAdditionalEmails: applicationEmailsData,
            showEmails: true,
            applicationUrlEnabled: false
          }
        },
        step7: staticValuesForPaymentStep
      });
    }
  }, [data]);

  const reactivateFreeJobRequest = useCallback(() => {
    if (data) {
      reactivateFreeJob(guid);
    }
  }, [data]);

  const stopJobRequest = useCallback(() => {
    if (data) {
      stopJob(guid);
    }
  }, [data]);

  useEffect(() => {
    if (reactivateSuccess) {
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Job was successfully reactivated!'
      }));
    }
  }, [reactivateSuccess]);

  useEffect(() => {
    if (stopSuccess) {
      dispatch(showNotification({
        show: true,
        type: 'success',
        text: 'Job was successfully deactivated!'
      }));
    }
  }, [stopSuccess]);

  useEffect(() => {
    if (savedData && savedData.guid && isSuccess) {
      cookies.set(cookieNames.tempJob, savedData.guid);
      dispatch(showDropButton());

      const timeout = setTimeout(() => {
        const url = siteCopyRedirect();
        push(url);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [savedData, isSuccess]);

  useEffect(() => {
    if (isError) {
      setIsLocalLoading(false);
    }
  }, [isError]);

  return (
    <CardWrapper>
      <Typography
        fontWeight={700}
        variant="subtitle1"
      >
        Edit Listing:
      </Typography>
      <Typography
        variant="h4"
        marginBottom={0.5}
      >
        {data.stepper.step1.jobTitle}
      </Typography>

      <FirstLine data={data.header} />

      <div className={classes.row}>

        <StatusButtonOptions
          guid={guid}
          data={data}
          upgradeListing={siteRedirectUpgrade}
          reactivateFreeJobRequest={reactivateFreeJobRequest}
        />
        <CopyOptions
          guid={guid}
          copyJob={copyJob}
          isLoading={isLocalLoading}
          title={data?.stepper.step1.jobTitle}
        />
      </div>

      {!data.header.isReactivateAllowed && moment(data.header.expiryDate).isAfter(moment()) && (
        <>
          <Typography
            className={classes.comment_bottom}
          >
            To make changes to the contents of the listings, please    go through the steps below or select to deactivate this listing.
          </Typography>
          <Btn
            className={classes.deactivate_btn}
            click={stopJobRequest}
          >
            Deactivate Listing
          </Btn>
        </>
      )}
    </CardWrapper>
  );
};
