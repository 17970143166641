import { ILocationItem } from '../stepper/Step2/formInterfaces';

export const formattedLocations = (data: ILocationItem[]) => {
  const result:ILocationItem[] = data.map((item) => {
    if (item.country === 'USA') {
      return {
        ...item,
        country: 'US'
      };
    }
    return item;
  });
  return result;
};
