import { createApi } from '@reduxjs/toolkit/dist/query/react';
import cookies from 'js-cookie';

import { cookieNames } from '@monorepo/common';

import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import endpoints from '../../api/endpoints';
import {
  JobListingTypesResponse,
  RespSalaryTypes,
  IRequestStepsData,
  ResponseItem,
  IRespAllSteps,
  IPaymentResponse,
  IResponseBrewboundList,
  IPaymentIntentRequest,
  IPaymentIntentResponse
} from './responseInterfaces';

export const postAJobApi = createApi({
  reducerPath: 'servicePostAJob',
  tagTypes: ['IndustrySectors'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    // step 1
    getJobListingTypes: build.query<JobListingTypesResponse, string>({
      query: (id: string) => ({
        url: endpoints.jobs.jobListingTypes(id),
      }),
    }),
    // step 2

    getIndustry: build.query<ResponseItem[], string>({
      query: (id: string) => ({
        url: endpoints.jobs.industrySectors(id),
      }),
      providesTags: ['IndustrySectors']
    }),

    getBrewboundCompanyList: build.query<IResponseBrewboundList[], string>({
      query: (text: string) => ({
        url: endpoints.jobs.brewboundCompanyList(text)
      }),
    }),

    getEmploymentTypes: build.query<ResponseItem[], null>({
      query: () => ({
        url: endpoints.jobs.employmentTypes,
      }),
    }),

    getSalaryUnits: build.query<ResponseItem[], null>({
      query: () => ({
        url: endpoints.jobs.salaryUnits,
      }),
    }),

    getSalaryTypes: build.query<RespSalaryTypes[], null>({
      query: () => ({
        url: endpoints.jobs.salaryTypes,
      }),
    }),

    postLogo: build.mutation<{ url: string }, any>({
      query: (body:any) => ({
        url: endpoints.jobs.jobLogo,
        method: 'POST',
        body
      }),
    }),

    // get temp data
    getJobTempData: build.query<IRespAllSteps, string>({
      query: (guid: string) => ({
        url: endpoints.jobs.getJobTempData(guid)
      }),
    }),

    // save all data from steps
    saveStepsData: build.mutation<{ guid: string }, IRequestStepsData>({
      query: (body:IRequestStepsData) => {
        if (cookies.get(cookieNames.tempJob)) {
          return {
            url: endpoints.jobs.saveJobData,
            method: 'POST',
            params: { guid: cookies.get(cookieNames.tempJob) },
            body
          };
        }
        return {
          url: endpoints.jobs.saveJobData,
          method: 'POST',
          body
        };
      }
    }),

    // clear temp data
    clearData: build.query<any, null>({
      query: () => ({
        url: endpoints.jobs.сlearTempData(cookies.get(cookieNames.tempJob) as string),
      }),
    }),
    getPaymentIntent: build.mutation<IPaymentIntentResponse, IPaymentIntentRequest>({
      query: (body) => ({
        url: endpoints.jobs.getPaymentIntent,
        method: 'post',
        body
      })
    }),
    //  FINAL REQUEST
    paymentRequest: build.mutation<IPaymentResponse, { id: string, validateOnly: boolean, body: IRespAllSteps }>({
      query: ({ id, validateOnly, body }) => ({
        url: endpoints.jobs.postJobListing(id, validateOnly),
        method: 'post',
        body
      }),
    }),
  }),
});
export const {
  useGetIndustryQuery,
  useGetEmploymentTypesQuery,
  useGetSalaryUnitsQuery,
  useGetJobListingTypesQuery,
  useGetSalaryTypesQuery,
  useGetJobTempDataQuery,

  useLazyGetBrewboundCompanyListQuery,

  usePostLogoMutation,
  useSaveStepsDataMutation,

  useClearDataQuery,

  usePaymentRequestMutation,
  useGetPaymentIntentMutation
} = postAJobApi;
