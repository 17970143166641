import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { BasicLayout } from '@monorepo/common';

import { useAppSelector } from '../../store/hooks';
import { StepperPanel } from './stepper/StepperPanel';
import { useLazyGetJobDataQuery } from '../../services/serviceJobData';
import { MetaDescription } from '../../components/MetaDescription';
import { Hosts } from '../../constants/hostName';
import { setPageTitle } from '../../utils/setPageTitle';

// interface IEditJobProps {}
export const EditJob = () => {
  const [getJobData, { data, isLoading }] = useLazyGetJobDataQuery();
  const [titleBrowserPage, setTitleBrowserPage] = useState<string>('Jobs');

  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber
  }));

  useEffect(() => {
    setPageTitle({
      firstTitle: 'Edit Job Listing',
      siteNumber: siteId || Hosts.BEVNET,
      setTitle: setTitleBrowserPage
    });
  }, [siteId]);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const guid = query.get('path');

  useEffect(() => {
    if (guid) {
      getJobData({
        siteId: siteId.toString(),
        guid
      });
    }
  }, [search, guid]);

  return (
    <BasicLayout
      isLoading={isLoading}
      hiddenContentWhenIsLoading={isLoading}
      helmet={MetaDescription({
        title: titleBrowserPage,
        siteNumber: siteId
      })}
    >
      <div data-cy="editJob">
        {guid && data && (
        <StepperPanel
          data={data}
          guid={guid}
        />
        )}
      </div>
    </BasicLayout>
  );
};
