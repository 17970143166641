import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { book } from '../../../../routers/books';
import { useStyles } from '../../styles';

// interface IMultipleJobListingProps {}
const MultipleJobListing = () => {
  const classes = useStyles();
  return (
    <div className={classes.multiple_job_listing}>
      <Typography
        sx={{ mb: 2 }}
        variant="h6"
        fontWeight={800}
      >
        SAVE WITH BULK JOB LISTING PACKAGES
      </Typography>
      <Typography
        variant="body2"
      >
        Purchase multiple job listings and SAVE. All job listing packages are good for 24 months and can be used immediately.
        {' '}
        <Link
          to={book.jobs}
          className={classes.text_error}
        >
          View bulk listing packages/savings
        </Link>
      </Typography>
      <Typography
        sx={{ mb: 2, mt: 4 }}
        variant="h6"
        fontWeight={800}
      >
        NEED TO PAY BY ACH OR INVOICE?
      </Typography>
      <Typography
        variant="body2"
      >
        ACH and invoices are available for certain customers.
        {' '}
        <a
          href="mailto:jobboard@bevnet.com?subject=Job Board invoicing"
          className={classes.text_error}
        >
          Contact us to find out more.
        </a>
      </Typography>
    </div>
  );
};

export default memo(MultipleJobListing);
