import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ErrorBoundary, ForPage404 } from '@monorepo/common';

import { book } from './books';
import { PostJobListing } from '../pages/PostJobListing';
import { PrepaidPurchase } from '../pages/PrepaidPurchase';
import { EditJob } from '../pages/EditJob';
import { UpgradeListing } from '../pages/UpgradeListing';
import { ReactivateListing } from '../pages/ReactivateListing';
import { PostPrepaidListing } from '../pages/PostPrepaidListing';
import { Receipt } from '../pages/Receipt';
import { NoRemaining } from '../pages/NoRemaining';

const RootRouters = () => (
  <>
    <Switch>
      <Route
        exact
        path="/"
      >
        <Redirect to={book.postAJob} />
      </Route>

      <Route
        exact
        path={[book.jobs, book.jobsNosh, book.jobsBrewbound]}
      >
        <ErrorBoundary>
          <PrepaidPurchase />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={[book.postAJob, book.postAJobBrewbound, book.postAJobNosh]}
      >
        <ErrorBoundary>
          <PostJobListing />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={[book.editJob, book.editJobBrewbound, book.editJobNosh]}
      >
        <ErrorBoundary>
          <EditJob />
        </ErrorBoundary>
      </Route>
      <Route
        exact
        path={[book.upgradeJob(), book.upgradeJobBrewbound(), book.upgradeJobNosh()]}
      >
        <ErrorBoundary>
          <UpgradeListing />
        </ErrorBoundary>
      </Route>

      <Route
        exact
        path={[book.reactivateListing, book.reactivateListingBrewbound, book.reactivateListingNosh]}
      >
        <ErrorBoundary>
          <ReactivateListing />
        </ErrorBoundary>
      </Route>

      <Route
        path={[book.postPrepaidListing(), book.postPrepaidListingBrewbound(), book.postPrepaidListingNosh()]}
        exact
      >
        <ErrorBoundary>
          <PostPrepaidListing />
        </ErrorBoundary>
      </Route>

      <Route
        path={[book.receipt, book.receiptBrewbound, book.receiptNosh]}
        exact
      >
        <ErrorBoundary>
          <Receipt />
        </ErrorBoundary>
      </Route>

      <Route
        path={[book.noremaining]}
        exact
      >
        <ErrorBoundary>
          <NoRemaining />
        </ErrorBoundary>
      </Route>

      <Route>
        <ForPage404 />
      </Route>

    </Switch>
  </>
);

export default memo(RootRouters);
