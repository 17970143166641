export const step5Options = [
  {
    text: 'Applications Sent Via Email',
    value: '0',
  },
  {
    text: 'Candidates Apply on Your Web Site',
    value: '1',
  },
];

export enum EOptions {
  APPLICATIONS = '0',
  CANDIDATES = '1'
}
