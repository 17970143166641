import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

// '@media(max-width: 768px)': {},
export const useStyles = makeStyles(() => ({
  btn_wrapper: {
    padding: '5px 0 25px',
    ...Mixin.mixin.flex('center', 'center')
  },
  copy_wrapper: {
    padding: '25px 0 20',
  },
  btn: {
    textDecoration: 'none'
  },
  top_panel: {
    marginBottom: '1rem',
    '& a': {
      textDecoration: 'none'
    }
  },
  step3_address_wrap: {
    ...Mixin.mixin.flex('flex-start', 'flex-start'),
    gridGap: '2rem',
    marginBottom: '2rem',
    '& > div': {
      flex: 1,
      marginBottom: 0,
      '@media(max-width: 768px)': {
        marginBottom: '2rem'
      }
    },
    '@media(max-width: 768px)': {
      display: 'block'
    }
  },
  location_wrapper: {
    '& ~ $location_wrapper': {
      marginTop: 45
    }
  },
  last_field: {
    marginBottom: 10
  },
  couple_line: {
    ...Mixin.mixin.flex('flex-start', 'center'),
    gridGap: '1rem',
    '@media(max-width: 768px)': {
      display: 'block'
    },
    '& > div': {
      flex: 1
    }
  },
  wrapper_for_additional_email: {
    ...Mixin.mixin.flex('center', 'space-between'),
    '& + $wrapper_for_additional_email': {
      marginTop: '1rem'
    }
  },
  additional_email: {
    marginBottom: 0,
    flex: 1
  },
  additional_email_remove: {
    cursor: 'pointer',
    marginLeft: '1rem',
    '&:hover': {
      fill: 'red'
    }
  },
}));
