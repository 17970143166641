import React, { memo, useCallback } from 'react';
import { TableCell, TableRow, TextField } from '@mui/material';
import { Loader } from '@monorepo/common';
import { IStepsOneInterfaces } from '../../stepsOneInterfaces';
import { CellForSimpleUser } from './CellForSimpleUser';
import { useGetUserInfoQuery } from '../../../../services/serviceUserInfo';
import { CellForAuth } from './CellForAuth';

interface ISteps1TableCellProps {
  list: IStepsOneInterfaces[],
  setPackages: any
}

const Steps1TableBodyCells = ({ list = [], setPackages }:ISteps1TableCellProps) => {
  const { data, isSuccess } = useGetUserInfoQuery();
  const change = useCallback((val:any, el:IStepsOneInterfaces) => {
    const newResult = list.filter((item) => {
      if (item.description === el.description) {
        el.number = Number(val);
        return item;
      }
      return item;
    });
    setPackages(newResult);
  }, [list, setPackages]);
  if (data && isSuccess) {
    return (
      <>
        {list.map((item, i) => (
          <TableRow
            key={item.description}
            data-cy="prepaidPackage"
          >
            <TableCell>
              <TextField
                onChange={(e) => {
                  if (Number(e.currentTarget.value) >= 0) {
                    change(e.currentTarget.value, item);
                  }
                }}
                onBlur={(e) => {
                  if (Number(e.currentTarget.value) < 0) {
                    item.number = 0;
                    change(0, item);
                  }
                }}
                defaultValue={item.number}
                size="small"
                type="number"
                InputProps={{
                  inputProps:
                      {
                        min: 0,
                        max: 100,
                        'data-cy': `fieldQuantity_${i}`
                      }
                }}
              />
            </TableCell>

            <TableCell>
              {!data.isLoggedIn ? (
                <CellForSimpleUser item={item} />
              ) : (
                <CellForAuth item={item} />
              )}
            </TableCell>

            <TableCell>
              {item.number * item.listingsNumber}
            </TableCell>
            <TableCell>
              $
              {item.itemPrice * item.number}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }
  return (
    <Loader
      isShow
      size={20}
      position="relative"
    />
  );
};

export default memo(Steps1TableBodyCells);
