import React, { memo, useState } from 'react';
import { Button } from '@mui/material';

import { FormField, FormAutocomplete, CardWrapper, useHookForm } from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../Icons/arrow-right';
import { useAppSelector } from '../../../../store/hooks';
import { IEventsStepProps } from '../../interfaceSteps';
import { IStep2Form } from './formInterface';
import { schema } from './schema';
import {
  useGetIndustryQuery,
  useGetEmploymentTypesQuery
} from '../../../../services/servicePostAJob';

interface IStep2Props extends IEventsStepProps {
  defaultData: IStep2Form,
  setStep2: (res: IStep2Form) => void
}
interface IOptions {
  selected?: boolean,
  value: string | number,
  text: string
}

export const Step2 = memo(({ onBack, onNext, defaultData, setStep2, Nav }:IStep2Props) => {
  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber
  }));

  const { data: listIndustry, isLoading: isLoadingIndustry } = useGetIndustryQuery(siteNumber.toString());
  const { data: employmentTypeOptions, isLoading: isLoadingEmploymentType } = useGetEmploymentTypesQuery(null);

  const [changedFields, setChangedFields] = useState<IStep2Form | null>(null);
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    errors
  } = useHookForm<IStep2Form>({
    schema,
    defaultValues: defaultData,
    showChangedFields: (a) => {
      setChangedFields(a);
    }
  });

  const goBack = () => {
    if (onBack) {
      if (changedFields && (JSON.stringify(defaultData).toString() !== JSON.stringify(changedFields))) {
        setStep2(changedFields);
      }
      onBack();
    }
  };

  const onSubmit = (data: IStep2Form) => {
    if (data && onNext && setStep2) {
      setStep2(data);
      onNext();
    }
  };

  return (
    <CardWrapper
      scrollOptions={{
        isErrorsAutoScrollPage: errors
      }}
    >
      <form
        data-cy="step2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          isRequired
          extraProps={{ ...register('jobTitle') }}
          errors={errors}
          label="Job Title"
          maxLength={120}
        />
        <FormField
          isRequired
          errors={errors}
          label="Company Name"
          maxLength={50}
          extraProps={{ ...register('companyName') }}
          fieldComment={'For a blind listing, please describe your company in general terms, i.e. "Energy Drink Manufacturer"'}
        />
        <FormAutocomplete<IOptions>
          optionsSettings={{
            optionText: 'text',
            optionValue: 'value'
          }}
          disableClearable
          isRequired
          isLoading={isLoadingIndustry}
          fieldComment="Please select the category that best matches your company and the type of job your are hiring for."
          extraProps={{ ...register('industrySectorId') }}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          options={listIndustry || []}
          label="Job Category"
        />
        <FormAutocomplete<IOptions>
          optionsSettings={{
            optionText: 'text',
            optionValue: 'value'
          }}
          isLoading={isLoadingEmploymentType}
          isRequired
          disableClearable
          extraProps={{ ...register('employmentTypeId') }}
          errors={errors}
          getValues={getValues}
          options={employmentTypeOptions || []}
          label="Employment Type"
          setValue={setValue}
        />

        <Nav
          nextDisabled={false}
          onBack={goBack}
          nextButton={
            (
              <Button
                endIcon={(<ArrowRightIcon fontSize="small" />)}
                variant="contained"
                disabled={false}
                type="submit"
                data-cy="nextStep"
              >
                Next Step
              </Button>
                )
          }
        />
      </form>
    </CardWrapper>
  );
});
