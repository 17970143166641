import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

// '@media(max-width: 768px)': {},
export const useStyles = makeStyles(() => ({
  card_99: {
    padding: '0 14px',
  },
  step3_address_wrap: {
    ...Mixin.mixin.flex('flex-start', 'flex-start'),
    gridGap: '2rem',
    marginBottom: '2rem',
    '& > div': {
      flex: 1,
      marginBottom: 0,
      '@media(max-width: 768px)': {
        marginBottom: '2rem'
      }
    },
    '@media(max-width: 768px)': {
      display: 'block'
    }
  },
  link: {
    textDecoration: 'none'
  }
}));
