import React from 'react';
import { CardWrapper, Loader } from '@monorepo/common';
import { Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useStyles } from '../styles';

interface IFinalStepProps {
  editJobLink: string;
}
export const FinalStep = ({ editJobLink } : IFinalStepProps) => {
  const classes = useStyles();
  const { guid } = useParams<{ guid: string }>();

  if (!guid) {
    return <Loader isShow />;
  }

  return (
    <CardWrapper dataCy="finalStep">
      <Typography
        variant="h5"
        marginBottom={2}
      >
        Your job listing was reactivated successfully. A receipt will be emailed to you.
      </Typography>

      <Link
        to={`${editJobLink}?path=${guid}`}
        className={classes.link}
      >
        <Typography
          variant="subtitle1"
          color="red"
        >
          Return to editing your listing
        </Typography>
      </Link>
    </CardWrapper>
  );
};
