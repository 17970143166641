import { BasicLayout } from '@monorepo/common';
import { Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useStyles } from '../styles';

interface IProps {
  jobTitle: string;
  companyName: string;
}

const DescriptionJobs = ({ jobTitle, companyName }: IProps) => {
  const classes = useStyles();
  const [titleStep] = useState<string>('Reactivate Job Listing');
  const [subtitleStep, setSubTitleStep] = useState<string>(
    'Reactivate Job Listing'
  );

  useEffect(() => {
    if (jobTitle && companyName) {
      setSubTitleStep(`Reactivate Job Listing ${jobTitle} - ${companyName}`);
    }
  }, [jobTitle, companyName]);

  return (
    <BasicLayout>
      <div className={classes.description_page}>
        <Typography
          sx={{ mb: 3 }}
          variant="h1"
          fontWeight={800}
        >
          {titleStep}
        </Typography>
        <Typography
          sx={{ mb: 3 }}
          variant="subtitle1"
          fontWeight={800}
        >
          {subtitleStep}
        </Typography>
        <p>
          Use this form to reactivate your job listing.
          {' '}
          <br />
          <br />
          {' '}
          Once you make payment, your job listing will be activated and
          move to the TOP of our job listings.
          {' '}
          <br />
          <br />
          You can also make changes after you reactivate.
        </p>
      </div>
    </BasicLayout>
  );
};

export default memo(DescriptionJobs);
