import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userProfile from '../features/sliceUserProfile';
import notification from '../features/sliceNotification';
import loadings from '../features/sliceLoading';
import checkEmail from '../features/sliceUserEmail';
import ui from '../features/ui/sliceMobileMenu';
import auth from '../features/sliceAuth';
import pathnameSettings from '../features/slicePathname';
import stepperOptions from '../features/sliceStepper';
import jobsContent from '../features/sliceJob';
import { middlewareHandler } from './middlewareList';
import { jobsApi } from '../services/servicePrepaidPurchase';
import { postAJobApi } from '../services/servicePostAJob';
import { currentUserInfoApi } from '../services/serviceUserInfo';
import { jobDataApi } from '../services/serviceJobData';
import { postPrepaidListingApi } from '../services/servicePostPrepaidListing';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
  reducer: {
    pathnameSettings,
    stepperOptions,
    jobsContent,
    auth,
    loadingList: loadings,
    userProfile,
    notification,
    checkEmail,
    ui,
    [jobDataApi.reducerPath]: jobDataApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [postAJobApi.reducerPath]: postAJobApi.reducer,
    [currentUserInfoApi.reducerPath]: currentUserInfoApi.reducer,
    [postPrepaidListingApi.reducerPath]: postPrepaidListingApi.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
