import * as yup from 'yup';

export const schema:any = yup.object().shape({
  showUrl: yup.boolean(),
  showEmails: yup.boolean(),

  email: yup.string().required('The Your Email field is required.').email().nullable(),
  contactFirstName: yup.string().required('The Your First Name field is required.').nullable(),
  contactLastName: yup.string().required('The Your  Last Name field is required.').nullable(),

  // applicationUrl: yup.string().url('The URL field must be a valid!').nullable(),
  applicationUrlEnabled: yup.string().nullable(),
  applicationEmail: yup.string(),
  applicationUrl: yup.string()
    .when('showEmails', {
      is: false,
      then: yup.string().url('The URL field must be a valid!').required('A URL field is required.').nullable(),
      otherwise: yup.string().url('The URL field must be a valid!').nullable()
    }),
  tempAdditionalEmails: yup.array().nullable()
    .when('showEmails', {
      is: true,
      then: yup.array().of(
        yup.object().shape({
          email: yup.string().email('The Your Email field is not valid.').required('The Your Email field is required.').nullable()
        }),
      ),
      otherwise: yup.array().of(
        yup.object().shape({
          email: yup.string().email('The Your Email field is not valid.').nullable()
        }),
      )
    })
});
