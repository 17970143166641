import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';

import { BasicLayout, CardWrapper } from '@monorepo/common';

import { useParams } from 'react-router-dom';
import { IEventsStepProps } from '../../interfaces';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { useLazyGetPostPrepaidListingQuery } from '../../../../services/servicePostPrepaidListing';
import { useAppSelector } from '../../../../store/hooks';

interface IStep6Props extends IEventsStepProps {
  finalRequest: () => void;
  isEdited: boolean;
}

export const Step6 = ({ onBack, Nav, onComplete, finalRequest, isEdited }:IStep6Props) => {
  const [value, setValue] = useState<string>('');
  const [getPostPrepaidListing, { data, isLoading }] = useLazyGetPostPrepaidListingQuery();
  const { guid } = useParams<{ guid: string }>();
  const { siteId } = useAppSelector(({ pathnameSettings }) => ({
    siteId: pathnameSettings.siteNumber.toString()
  }));
  const submit = () => {
    finalRequest();
  };
  useEffect(() => {
    if (guid) {
      getPostPrepaidListing({
        siteId,
        guid
      });
    }
  }, [guid]);
  useEffect(() => {
    if (isEdited && onComplete) {
      onComplete();
    }
  }, [isEdited]);

  useEffect(() => {
    if (data) {
      setValue(data.step7.terms);
    }
  }, [data]);

  return (
    <BasicLayout
      dataCy="step7"
      isLoading={isLoading}
    >
      <CardWrapper>
        <div style={{ maxHeight: 500, overflowY: 'auto' }}>
          <Typography
            whiteSpace="break-spaces"
            variant="subtitle1"
            data-cy="termsDescription"
          >
            {value}
          </Typography>
        </div>
      </CardWrapper>

      <Nav
        nextDisabled={false}
        onBack={onBack}
        nextButton={(
          <Button
            onClick={submit}
            data-cy="complete"
            endIcon={(<ArrowRightIcon fontSize="small" />)}
            variant="contained"
          >
            Accept Terms and Continue
          </Button>
                )}
      />
    </BasicLayout>
  );
};
