import React from 'react';
import { Typography, Button } from '@mui/material';
import { Loader } from '@monorepo/common';

interface IModalBodyProps {
  copyJob: () => void;
  isLoading?: boolean;
}
export const ModalBody = ({ copyJob, isLoading }:IModalBodyProps) => (
  <>
    <Loader
      isShow={isLoading}
      position="absolute"
    />
    <Typography marginBottom={2}>
      Use this job listing as a template for a new job post. Please click the continue
      button below and you will be taken to the job posting form where you will be able to make changes, select your listing type, and provide payment information.
    </Typography>

    <Button
      variant="contained"
      color="info"
      onClick={copyJob}
    >
      Continue
    </Button>
  </>
);
