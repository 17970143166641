import { cookieNames } from '@monorepo/common';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import cookies from 'js-cookie';
import endpoints from '../../api/endpoints';
import { baseQueryWithReAuth } from '../../utils/baseQueryWithReAuth';
import {
  IPaymentResponse,
  IRequestStepsData,
  IRespAllSteps,
} from '../servicePostAJob/responseInterfaces';
import {
  IEditJobRequest,
  IEditJobResponse,
  IPaymentIntentRequest,
  IPaymentIntentResponse,
  IReactivateJobListingRequest,
  IUpgradeRequest,
} from './responseInterfaces';

export const jobDataApi = createApi({
  reducerPath: 'serviceJobData',
  tagTypes: ['jobData', 'postprepaidlisting'],
  baseQuery: baseQueryWithReAuth,
  endpoints: (build) => ({
    // ------ Edit job
    getJobData: build.query<IEditJobResponse, { siteId: string; guid: string }>(
      {
        query: ({ siteId, guid }) => ({
          url: endpoints.jobs.jobData({ guid, siteId }),
        }),
        providesTags: () => ['jobData'],
      }
    ),
    upgrade: build.mutation<any, IUpgradeRequest>({
      query: ({ guid, siteId, validateOnly, body }) => ({
        url: endpoints.jobs.upgradeJob(siteId, guid, validateOnly),
        method: 'POST',
        body,
      }),
    }),
    paymentIntentForUpgrade: build.mutation<
    IPaymentIntentResponse,
    IPaymentIntentRequest
    >({
      query: (body) => ({
        url: endpoints.jobs.paymentIntentForUpgrade,
        method: 'POST',
        body,
      }),
    }),
    editJob: build.mutation<any, IEditJobRequest>({
      query: ({ id, body }) => ({
        url: endpoints.jobs.editJobListing(id),
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['jobData'],
    }),
    stopJob: build.mutation<any, string>({
      query: (guid) => ({
        url: endpoints.jobs.stopJobListing(guid),
        method: 'POST',
      }),
      invalidatesTags: () => ['jobData'],
    }),
    reactivateFreeJob: build.mutation<any, string>({
      query: (guid) => ({
        url: endpoints.jobs.reactivateFreeJobListing(guid),
        method: 'POST',
      }),
      invalidatesTags: () => ['jobData'],
    }),
    getReactivateJobTempData: build.query<IRespAllSteps, string>({
      query: (guid: string) => ({
        url: endpoints.jobs.getReactivateJobTempData(guid),
      }),
    }),
    saveReactivateStepsData: build.mutation<
    { guid: string },
    IRequestStepsData
    >({
      query: (body: IRequestStepsData) => {
        if (cookies.get(cookieNames.tempReactivateJob)) {
          return {
            url: endpoints.jobs.saveTempDataReactivate,
            method: 'POST',
            params: { guid: cookies.get(cookieNames.tempReactivateJob) },
            body,
          };
        }
        return {
          url: endpoints.jobs.saveTempDataReactivate,
          method: 'POST',
          body,
        };
      },
    }),
    clearReactivateData: build.query<any, null>({
      query: () => ({
        url: endpoints.jobs.сlearTempData(
          cookies.get(cookieNames.tempReactivateJob) as string
        ),
      }),
    }),
    reactivateJob: build.mutation<
    IPaymentResponse,
    { id: string; validateOnly: boolean; body: IReactivateJobListingRequest }
    >({
      query: ({ id, validateOnly, body }) => ({
        url: endpoints.jobs.reactivateJobListing(id, validateOnly),
        method: 'POST',
        body,
      }),
    }),
  }),
});
export const {
  // ------ Edit job
  useLazyGetJobDataQuery,
  useUpgradeMutation,
  usePaymentIntentForUpgradeMutation,
  useEditJobMutation,
  useStopJobMutation,
  useReactivateFreeJobMutation,
  useGetReactivateJobTempDataQuery,
  useSaveReactivateStepsDataMutation,
  useClearReactivateDataQuery,
  useReactivateJobMutation,
} = jobDataApi;
