export enum HostNames {
  'www.bevnet.com' = 1,
  'localhost:3101' = 1,

  'localhost:3103' = 2,
  'www.brewbound.com' = 2,

  'localhost:3102' = 3,
  'www.nosh.com' = 3,
}
export enum Hosts {
  BEVNET = 1,
  BREWBOUND = 2,
  NOSH = 3,
}
