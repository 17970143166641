import { IStep1Form } from './Step1/formInterface';
import { ILocationItem, IStep2Form } from './Step2/formInterfaces';
import { IStep3Form } from './Step3/formInterface';
import { IStep4Form } from './Step4/formInterface';
import { IStep5Form } from './Step5/formInterfaces';
import { IStep6Form } from './Step6/formInterfaces';

export const itemLocation:ILocationItem = {
  isShowState: true,
  country: 'US',
  state: null,
  province: null,
  address1: null,
  address2: null,
  city: null,
  zip: null
};

export const staticValuesForStep1: IStep1Form = {
  jobTitle: null,
  companyName: null,
  employmentTypeId: 0,
  industrySectorId: 0,
};
export const staticValuesForStep2:IStep2Form = {
  salaryMax: null,
  salaryMin: null,
  salaryUnitId: 5,
  salaryExact: null,
  locations: [itemLocation],
  noneLocation: false,
  salaryUnitRadio: 2,
};

export const staticValuesForStep3:IStep3Form = {
  shortDescription: null,
  jobDescription: ''
};
export const staticValuesForStep4:IStep4Form = {
  uploadedLogoPath: null,
};
export const staticValuesForStep5:IStep5Form = {
  email: null,
  applicationEmail: '',
  contactLastName: null,
  contactFirstName: null,
  applicationUrl: null,
  showEmails: true,
  applicationUrlEnabled: false,
  tempAdditionalEmails: [
    {
      email: null
    }
  ]
};

export const staticValuesForStep6:IStep6Form = {
  terms: ''
};
