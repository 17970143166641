import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Hosts } from '../../constants/hostName';
import { IPathnameInitialStateProps, pathnameInitialState } from './pathnameInitialState';

export const slice = createSlice({
  name: 'siteSettings',
  initialState: pathnameInitialState,
  reducers: {
    setSettings: (state:IPathnameInitialStateProps, action:PayloadAction<{ pathname: string }>): void => {
      if (action.payload.pathname?.includes('/nosh')) {
        state.host = 'nosh.com';
        state.color = '#54BA5D';
        state.siteNumber = Hosts.NOSH;
        state.pathname = '/nosh';
      }
      if (action.payload.pathname?.includes('/brewbound')) {
        state.host = 'brewbound.com';
        state.color = '#14aac0';
        state.siteNumber = Hosts.BREWBOUND;
        state.pathname = '/brewbound';
      }
      if (!action.payload.pathname?.includes('/brewbound') && !action.payload.pathname?.includes('/nosh')) {
        state.host = 'bevnet.com';
        state.color = '#ce0e2d';
        state.siteNumber = Hosts.BEVNET;
        state.pathname = null;
      }
    }

  },
});

export const { setSettings } = slice.actions;
export default slice.reducer;
