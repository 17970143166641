import React, { memo, useState } from 'react';
import { Button, Typography, Link } from '@mui/material';
import { useDispatch } from 'react-redux';

import { CardWrapper, FormFieldUpload } from '@monorepo/common';

import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { usePostLogoMutation } from '../../../../services/servicePostAJob';
import { showNotification } from '../../../../features/sliceNotification';
import { IEventsStepProps } from '../../interfaces';
import { IStep4Form } from './formInterface';
import { cloudfrontImagesBaseURL } from '../../../../components/JobStepper/staticValues';

interface IStep5Props extends IEventsStepProps {
  defaultData:IStep4Form,
  setStep4: (res:IStep4Form) => void
}
export const Step4 = memo(({ onNext, onBack, defaultData, setStep4, Nav }:IStep5Props) => {
  const dispatch = useDispatch();
  const [postLogo, { isLoading }] = usePostLogoMutation();
  const [isUploaded, setIsUploaded] = useState(true);

  const change = async (file: File | File[]) => {
    if (!Array.isArray(file)) {
      const formData = new FormData();
      formData.append('logo', file);
      // if server returned headshot applicationURL, all will send to server for save
      await postLogo(formData)
        .then((res) => {
          if (res && 'data' in res) {
            const { url } = res.data as { url: string };
            setIsUploaded(true);
            setStep4({
              ...defaultData,
              uploadedLogoPath: `${cloudfrontImagesBaseURL}${url}`
            });
            dispatch(showNotification({
              type: 'success',
              show: true,
              text: 'Logo was uploaded!'
            }));
            return;
          }

          setIsUploaded(false);
          dispatch(showNotification({
            type: 'error',
            show: true,
            text: 'Logo not saved!'
          }));
        });
    }
  };
  const onRemove = (status: boolean) => {
    if (status) {
      setStep4({
        ...defaultData,
        uploadedLogoPath: null
      });
    }
  };

  const next = () => {
    if (onNext) {
      if (!isUploaded) {
        setStep4({
          ...defaultData,
          uploadedLogoPath: null
        });
      }
      onNext();
    }
  };

  return (
    <CardWrapper
      dataCy="step5"
      isLoading={isLoading}
    >
      <Typography
        variant="body2"
        sx={{
          mb: 2
        }}
      >
        Upload a company logo that will be used on your job listing. Note that square shaped files work best. Maximum file upload size is 10MB
      </Typography>

      <FormFieldUpload
        onChange={change}
        maxFilesUpload={1}
        onRemove={onRemove}
        removeLabel="Remove logo"
        maxSizeBytes={10485760} // max file size 10mb
        defaultValue={defaultData?.uploadedLogoPath && defaultData.uploadedLogoPath}
        uploadAreaSettings={{
          mainSubtitleTitle: null,
          mainTitle: (
            <>
              Drag & drop your logo or
              {' '}
              <Link underline="always">Browse your files</Link>
            </>
          )
        }}
      />

      <Nav
        nextDisabled={isLoading}
        onBack={onBack}
        nextButton={(
          <Button
            endIcon={(<ArrowRightIcon fontSize="small" />)}
            variant="contained"
            data-cy="nextStep"
            disabled={false}
            type="button"
            onClick={next}
          >
            Next Step
          </Button>
            )}
      />
    </CardWrapper>
  );
});
