import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe, } from '@stripe/react-stripe-js';

import {
  FormField,
  CardWrapper,
  useHookForm,
  useIsProvince,
  GlobalInterfaces,
  StripeWrapperField,
  CountryAutocomplete,
  UsaStatesAutocomplete,
  useCustomGoogleAutocomplete
} from '@monorepo/common';

import { showNotification } from '../../../../features/sliceNotification';
import { ArrowRight as ArrowRightIcon } from '../../../../components/Icons/arrow-right';
import { IEventsStepProps, IStep2, IStep1, IStep3AllDataProps, IStep3FormDataProps } from '../../interfaceSteps';
import { useAppSelector } from '../../../../store/hooks';
import { useGetPaymentIntentPrepaidPurchaseMutation, useCreateJobPackageMutation } from '../../../../services/servicePrepaidPurchase';
import { schema } from './schema';

import { stripeFieldOptions, useStyles } from '../../styles';
import { stripeRequestForStep3 } from './stripeRequestForStep3';
import { IStepsOneInterfaces } from '../../stepsOneInterfaces';
import { Step3PaymentButton } from './components/Step3PaymentButton';

interface IStep3Props extends IEventsStepProps{
  step1?: IStep1,
  step2?: IStep2,
  Nav: React.ElementType,
  step3?: IStep3AllDataProps,
  setStep3: (values: any) => void,
  dataForStep1: IStepsOneInterfaces[],
  finalPaymentRequest: (values: IStep3AllDataProps) => void
}

export const Step3 = memo(({ onBack, step1, step2, finalPaymentRequest, Nav, setStep3, step3, dataForStep1 }:IStep3Props) => {
  const stripe = useStripe();
  const classes = useStyles();
  const elements = useElements();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string | null>('US');
  const [stripeError, setStripeError] = useState<GlobalInterfaces.IStripeErrorProps | null>(null);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [clientSecretData, setClientSecretData] = useState<{ data: { clientSecret: string; }; } | { error: any; } | null>(null);
  const [getPaymentIntent] = useGetPaymentIntentPrepaidPurchaseMutation();
  const [createJobPackage] = useCreateJobPackageMutation();

  const {
    watch,
    register,
    handleSubmit,
    getValues,
    setValue,
    errors
  } = useHookForm<IStep3AllDataProps>({
    schema,
    data: step3
  });

  const { hostNumber } = useAppSelector(({ pathnameSettings }) => ({
    hostNumber: pathnameSettings.siteNumber.toString()
  }));

  const { getData, isProvince } = useIsProvince({
    extraOptions: {
      setValue,
      stateFieldName: 'state',
      provinceFieldName: 'province'
    }
  });

  const checkOverallValidity = async (result: IStep3AllDataProps) : Promise<{ success: boolean, message: string }> => {
    const saveDataResponse = await createJobPackage({
      id: hostNumber,
      validateOnly: true,
      body: {
        step1,
        step2,
        step3: result
      }
    });

    if (saveDataResponse && 'error' in saveDataResponse) {
      const respErorr = saveDataResponse as {
        error: { data: { errors: {} } }
      };
      let errorsString = '';
      Object.values(respErorr.error.data.errors).forEach((value) => {
        const valueErray = value as [];

        valueErray.forEach((element) => {
          errorsString += `${element}\n`;
        });
      });

      return { success: false, message: errorsString };
    }

    return { success: true, message: '' };
  };

  const { changeInput, renderedList, clearSuggestions } = useCustomGoogleAutocomplete({
    country: countryCode || '',
    mode: 'custom',
    setValue,
    autoFillFields: {
      state: 'state',
      province: 'province',
      address1: 'address',
      city: 'city',
      zip: 'zip'
    }
  });

  const onSubmit = useCallback(async (data: IStep3FormDataProps) => {
    setIsLoading(true);
    const saveFormDataResponse = await checkOverallValidity({
      ...step3,
      ...data,
    });
    if (!saveFormDataResponse.success) {
      dispatch(showNotification({
        show: false,
        type: 'error',
        text: saveFormDataResponse.message
      }));
      setIsLoading(false);
      return;
    }
    const resultIntent = clientSecretData ?? await getPaymentIntent({
      standardPackagesQuantity: step1.standardPackagesQuantity,
      featuredPackagesQuantity: step1.featuredPackagesQuantity,
      siteId: hostNumber
    });

    const { data: { clientSecret } } = resultIntent as { data:{ clientSecret: string } };
    setClientSecretData(resultIntent);

    if (!clientSecret) {
      dispatch(showNotification({
        show: false,
        type: 'error',
        text: 'Something went wrong. Please try again later.'
      }));
    }

    const stripePaymentMethodId: string | null = paymentMethodId ?? await stripeRequestForStep3({
      stripe,
      elements,
      dispatch,
      formData: data,
      dataFromStep2: step2,
      setIsLoading,
      setStripeError,
      clientSecret
    });

    setPaymentMethodId(stripePaymentMethodId);

    // if stripe gave payment ID
    if (stripePaymentMethodId) {
      setIsLoading(false);

      await finalPaymentRequest({
        ...step3,
        ...data,
        stripePaymentMethodId
      });
    }
  }, [step2, step3, countryCode, stripe, elements, finalPaymentRequest]);

  // if choose country
  const changeCountries = (val:GlobalInterfaces.IAutocompleteOption | null) => {
    clearSuggestions();

    getData({
      selectOption: val
    });
    setCountryCode(val?.alpha2 || null);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (value) {
        setStep3({
          ...step3,
          ...value
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, step3]);

  useEffect(() => {
    setValue('isShowState', !isProvince);
  }, [isProvince]);

  return (
    <CardWrapper
      isLoading={isLoading}
      scrollOptions={{
        isErrorsAutoScrollPage: errors,
        isScrollToStart: !!stripeError
      }}
    >
      <Step3PaymentButton dataForStep1={dataForStep1} />

      <form
        data-cy="step3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          style={{ display: 'none' }}
          type="checkbox"
          {...register('isShowState')}
        />
        <StripeWrapperField
          label="Card Number"
          name="numbers"
          error={stripeError}
        >
          <CardNumberElement
            className={clsx(classes.card_99)}
            options={stripeFieldOptions}
          />
        </StripeWrapperField>

        <div className={classes.step3_address_wrap}>
          <StripeWrapperField
            label="Card Expiry"
            name="expiry"
            error={stripeError}
          >
            <CardExpiryElement
              className={classes.card_99}
              options={stripeFieldOptions}
            />
          </StripeWrapperField>
          <StripeWrapperField
            label="Card CVC"
            name="cvc"
            error={stripeError}
          >
            <CardCvcElement
              className={classes.card_99}
              options={stripeFieldOptions}
            />
          </StripeWrapperField>
        </div>

        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            extraProps={{ ...register('firstName') }}
            errors={errors}
            label="First Name"
          />
          <FormField
            isRequired
            extraProps={{ ...register('lastName') }}
            errors={errors}
            label="Last Name"
          />
        </div>

        <CountryAutocomplete
          isRequired
          getValues={getValues}
          label="Country"
          extraProps={{ ...register('country') }}
          errors={errors}
          handleChange={changeCountries}
        />

        <FormField
          isRequired
          extraProps={{ ...register('address') }}
          errors={errors}
          label="Address"
          eventInput={(inputValue, event) => {
            changeInput(event as ChangeEvent<HTMLInputElement>);
          }}
        >
          {renderedList}
        </FormField>

        <div className={classes.step3_address_wrap}>
          <FormField
            isRequired
            label="City"
            errors={errors}
            placeholder="City"
            extraProps={{ ...register('city') }}
          />
          <FormField
            visible={isProvince}
            placeholder="Province"
            errors={errors}
            label="Province"
            extraProps={{ ...register('province') }}
          />
          <UsaStatesAutocomplete
            isRequired
            visible={!isProvince}
            getValues={getValues}
            errors={errors}
            label="State"
            setValue={setValue}
            extraProps={{ ...register('state') }}
          />
        </div>

        <FormField
          isRequired
          extraProps={{ ...register('zip') }}
          errors={errors}
          label="ZIP/Postal"
        />

        <Nav
          nextDisabled={false}
          onBack={onBack}
          textPreviousStep="Previous step"
          nextButton={(
            <Button
              endIcon={(<ArrowRightIcon fontSize="small" />)}
              variant="contained"
              data-cy="complete"
              disabled={!stripe}
              type="submit"
            >
              Process Purchase
            </Button>
            )}
        />
      </form>

    </CardWrapper>
  );
});
