import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { book } from '../../../routers/books';
import BevnetLogo from '../../Icons/BevnetLogo';
import BrewboundLogo from '../../Icons/BrewboundLogo';
import NoshLogo from '../../Icons/NoshLogo';
import { useAppSelector } from '../../../store/hooks';
import { Hosts } from '../../../constants/hostName';
import { useStyles } from '../styles';

interface LogoProperties {
  link: string,
  logo: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export const HeaderLeftSide = () => {
  const classes = useStyles();
  const [logoEl, setLogoEl] = useState<LogoProperties>({
    link: '/',
    logo: BevnetLogo,
  });
  const { siteNumber } = useAppSelector(({ pathnameSettings }) => ({
    siteNumber: pathnameSettings.siteNumber,
  }));

  useEffect(() => {
    switch (siteNumber) {
      case Hosts.NOSH:
        setLogoEl({
          link: book.postAJobNosh,
          logo: NoshLogo
        });

        break;
      case Hosts.BREWBOUND:
        setLogoEl({
          link: book.postAJobBrewbound,
          logo: BrewboundLogo
        });

        break;
      default:
        setLogoEl({
          link: book.postAJob,
          logo: BevnetLogo
        });

        break;
    }
  }, [siteNumber]);

  return (
    <>
      <Link to={logoEl.link}>
        {React.createElement(logoEl.logo, { className: classes.logo })}
      </Link>
      <h5 className={classes.title_portal}>Job Board</h5>
    </>
  );
};
