import React, { ReactNode, ReactNodeArray, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSettings } from '../../features/slicePathname';

interface ISiteAddressProps {
  children: ReactNode | ReactNodeArray
}
export const SiteAddress = ({ children }:ISiteAddressProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSettings({ pathname }));
  }, [pathname]);

  if (!pathname) {
    return null;
  }
  return (
    <>
      {children}
    </>
  );
};
